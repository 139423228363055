

import React, { useContext, useState, useEffect } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types'; import TextField from "@mui/material/TextField";

import CloseIcon from '@mui/icons-material/Close';

import { UserManagementContext } from '../../pages/Counter2';

import { PatternFormat } from 'react-number-format';

import { useForm, Controller } from "react-hook-form";




import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';


import { GraphDetailsContext, ProjectDashboardContext } from '../../App';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { NumericFormat } from 'react-number-format';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';

import CircularProgress, {
    CircularProgressProps,
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';


function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', ml: '1VW', mr: "1vw", mt: '-3VH' }}>
            <CircularProgress
                variant="determinate"
                sx={(theme) => ({
                    color: theme.palette.grey[200],
                    ...theme.applyStyles('dark', {
                        color: theme.palette.grey[800],
                    }),
                })}
                size={"5.5rem"}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress size="5.5rem" thickness={4} disableShrink sx={{}} variant="determinate" {...props} sx={{ position: 'absolute', }}
                sx={(theme) => ({
                    color: '#1a90ff',
                    animationDuration: '0ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                    ...theme.applyStyles('dark', {
                        color: '#308fe8',
                    }),
                })}
            />
            <Box
                sx={{
                    top: "-4VH",
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.primary', ml: 'VW', fontWeight: "600", fontSize: "1.25rem", position: "absolute" }}
                >{`${Math.round(props.value)}%`}</Typography>
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.secondary', ml: '-0.05VW', mt: "4VH", textAlign: "center", fontWeight: "300", fontSize: "0.9rem", position: "absolute" }}
                >{`Response`}</Typography>
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.secondary', ml: '-0.05VW', mt: "7VH", textAlign: "center", fontWeight: "300", fontSize: "0.9rem", position: "absolute" }}
                >{`Rate`}</Typography>
            </Box>
        </Box>
    );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    border: "solid 1px #1a90ff",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#EFF6FF",
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,






        backgroundColor: '#1a90ff',
        ...theme.applyStyles('dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const materialUITextFieldProps = {
    sx: [{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }],
    variant: "outlined"
};

function EditTimelineCardStartNotice({ cardTitle, cardSubtitle, desiredCardWidth, desiredCardHeight, desiredHeightPercentage, cardType, cardChipLabel, cardChipStatus, cardExtraSubtitle, cardMilestoneData, cardBatchEndDate, cardExtraSubtitle2, cardFamilyStartId, cardFamilyEndId, cardTotalCount, cardTimelineId, cardGraphic, cardResponseRate, cardCurrentDay, cardTotalDays, cardActiveMilestonePosition }) {

    const {

        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates,
        handleUpdateAvailableClientProjectTimelineData, handleUpdateTimelineMilestonesByBatchEndDate,
        reloadClientProjectTimelineData, setReloadClientProjectTimelineData, handleUpdateReloadClientProjectTimelineData
    } = useContext(ProjectDashboardContext);

    const [milestone0Active, setMilestone0Active] = useState(true);
    const [milestone1Active, setMilestone1Active] = useState(true);
    const [milestone2Active, setMilestone2Active] = useState(false);
    const [milestone3Active, setMilestone3Active] = useState(false);
    const [milestone4Active, setMilestone4Active] = useState(true);
    const [milestone5Active, setMilestone5Active] = useState(true);
    const [milestone6Active, setMilestone6Active] = useState(true);

    function handleUpdateMilestone0Active(update) {
        setMilestone0Active(update)
    }

    function handleUpdateMilestone1Active(update) {
        setMilestone1Active(update)
    }

    function handleUpdateMilestone2Active(update) {
        setMilestone2Active(update)
    }

    function handleUpdateMilestone3Active(update) {
        setMilestone3Active(update)
    }

    function handleUpdateMilestone4Active(update) {
        setMilestone4Active(update)
    }

    function handleUpdateMilestone5Active(update) {
        setMilestone5Active(update)
    }

    function handleUpdateMilestone6Active(update) {
        setMilestone6Active(update)
    }

    const [milestone0Title, setMilestone0Title] = useState("START DATE DEF");
    const [milestone1Title, setMilestone1Title] = useState("Initial NoticeDEF");
    const [milestone2Title, setMilestone2Title] = useState("ReminderDEF");
    const [milestone3Title, setMilestone3Title] = useState("DeadlineDEF");
    const [milestone4Title, setMilestone4Title] = useState("Final Notice + AppealsDEF");
    const [milestone5Title, setMilestone5Title] = useState("Final DeadlineDEF");
    const [milestone6Title, setMilestone6Title] = useState("ReportDEF");

    function handleMilestone0TitleChange(event) {
        setMilestone0Title(event.target.value)
    }
    function handleMilestone1TitleChange(event) {
        setMilestone1Title(event.target.value)
    }
    function handleMilestone2TitleChange(event) {
        setMilestone2Title(event.target.value)
    }
    function handleMilestone3TitleChange(event) {
        setMilestone3Title(event.target.value)
    }
    function handleMilestone4TitleChange(event) {
        setMilestone4Title(event.target.value)
    }
    function handleMilestone5TitleChange(event) {
        setMilestone5Title(event.target.value)
    }
    function handleMilestone6TitleChange(event) {
        setMilestone6Title(event.target.value)
    }

    const [milestone0Goal, setMilestone0Goal] = useState(0);
    const [milestone1Goal, setMilestone1Goal] = useState(10);
    const [milestone2Goal, setMilestone2Goal] = useState(20);
    const [milestone3Goal, setMilestone3Goal] = useState(50);
    const [milestone4Goal, setMilestone4Goal] = useState(70);
    const [milestone5Goal, setMilestone5Goal] = useState(90);
    const [milestone6Goal, setMilestone6Goal] = useState(100);

    function handleMilestone0GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone0Goal(event.target.value)
        }

    }

    function handleMilestone1GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone1Goal(event.target.value)
        }

    }

    function handleMilestone2GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone2Goal(event.target.value)
        }

    }

    function handleMilestone3GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone3Goal(event.target.value)
        }

    }

    function handleMilestone4GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone4Goal(event.target.value)
        }

    }

    function handleMilestone5GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone5Goal(event.target.value)
        }

    }

    function handleMilestone6GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone6Goal(event.target.value)
        }

    }

    const [milestone0EndDateValue, setMilestone0EndDateValue] = useState(null);
    const [milestone1EndDateValue, setMilestone1EndDateValue] = useState(null);
    const [milestone2EndDateValue, setMilestone2EndDateValue] = useState(null);
    const [milestone3EndDateValue, setMilestone3EndDateValue] = useState(null);
    const [milestone4EndDateValue, setMilestone4EndDateValue] = useState(null);
    const [milestone5EndDateValue, setMilestone5EndDateValue] = useState(null);
    const [milestone6EndDateValue, setMilestone6EndDateValue] = useState(null);

    function handleMilestone0EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone0EndDateValue(value)
        }

    }

    function handleMilestone1EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone1EndDateValue(value)
        }

    }

    function handleMilestone2EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone2EndDateValue(value)
        }

    }

    function handleMilestone3EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone3EndDateValue(value)
        }

    }

    function handleMilestone4EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone4EndDateValue(value)
        }

    }

    function handleMilestone5EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone5EndDateValue(value)
        }

    }

    function handleMilestone6EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone6EndDateValue(value)
        }

    }

    const [milestone0TotalDays, setMilestone0TotalDays] = useState(0);
    const [milestone1TotalDays, setMilestone1TotalDays] = useState(0);
    const [milestone2TotalDays, setMilestone2TotalDays] = useState(0);
    const [milestone3TotalDays, setMilestone3TotalDays] = useState(0);
    const [milestone4TotalDays, setMilestone4TotalDays] = useState(0);
    const [milestone5TotalDays, setMilestone5TotalDays] = useState(0);
    const [milestone6TotalDays, setMilestone6TotalDays] = useState(0);

    const [milestone0DaysComplete, setMilestone0DaysComplete] = useState(0);
    const [milestone1DaysComplete, setMilestone1DaysComplete] = useState(0);
    const [milestone2DaysComplete, setMilestone2DaysComplete] = useState(0);
    const [milestone3DaysComplete, setMilestone3DaysComplete] = useState(0);
    const [milestone4DaysComplete, setMilestone4DaysComplete] = useState(0);
    const [milestone5DaysComplete, setMilestone5DaysComplete] = useState(0);
    const [milestone6DaysComplete, setMilestone6DaysComplete] = useState(0);

    const [milestone0CurrentlyActive, setMilestone0CurrentlyActive] = useState(false);
    const [milestone1CurrentlyActive, setMilestone1CurrentlyActive] = useState(false);
    const [milestone2CurrentlyActive, setMilestone2CurrentlyActive] = useState(false);
    const [milestone3CurrentlyActive, setMilestone3CurrentlyActive] = useState(false);
    const [milestone4CurrentlyActive, setMilestone4CurrentlyActive] = useState(false);
    const [milestone5CurrentlyActive, setMilestone5CurrentlyActive] = useState(false);
    const [milestone6CurrentlyActive, setMilestone6CurrentlyActive] = useState(false);


    const [milestone0Complete, setMilestone0Complete] = useState(false);
    const [milestone1Complete, setMilestone1Complete] = useState(false);
    const [milestone2Complete, setMilestone2Complete] = useState(false);
    const [milestone3Complete, setMilestone3Complete] = useState(false);
    const [milestone4Complete, setMilestone4Complete] = useState(false);
    const [milestone5Complete, setMilestone5Complete] = useState(false);
    const [milestone6Complete, setMilestone6Complete] = useState(false);






    function getFormattedEditMilesstoneData(cardMilestoneData) {
        const newMap = cardMilestoneData?.map((item) => {
            if (item?.milestonePosition === 0) {
                setMilestone0Active(item.milestoneToggleActive)
                setMilestone0Title(item.milestoneLabel)
                setMilestone0Goal(item.milestoneGoal)
                setMilestone0EndDateValue(dayjs(item.milestoneEndDate))
                setMilestone0TotalDays(item.milestoneTotalDays)
                setMilestone0CurrentlyActive(item.activeMilestone)
                setMilestone0Complete(item.completedMilestone)
                setMilestone0DaysComplete(item.milestoneDaysComplete)
            } else if (item?.milestonePosition === 1) {
                setMilestone1Active(item.milestoneToggleActive)
                setMilestone1Title(item.milestoneLabel)
                setMilestone1Goal(item.milestoneGoal)
                setMilestone1EndDateValue(dayjs(item.milestoneEndDate))
                setMilestone1TotalDays(item.milestoneTotalDays)
                setMilestone1CurrentlyActive(item.activeMilestone)
                setMilestone1Complete(item.completedMilestone)
                setMilestone1DaysComplete(item.milestoneDaysComplete)
            } else if (item?.milestonePosition === 2) {
                setMilestone2Active(item.milestoneToggleActive)
                setMilestone2Title(item.milestoneLabel)
                setMilestone2Goal(item.milestoneGoal)
                setMilestone2EndDateValue(dayjs(item.milestoneEndDate))
                setMilestone2TotalDays(item.milestoneTotalDays)
                setMilestone2CurrentlyActive(item.activeMilestone)
                setMilestone2Complete(item.completedMilestone)
                setMilestone2DaysComplete(item.milestoneDaysComplete)
            } else if (item?.milestonePosition === 3) {
                setMilestone3Active(item.milestoneToggleActive)
                setMilestone3Title(item.milestoneLabel)
                setMilestone3Goal(item.milestoneGoal)
                setMilestone3EndDateValue(dayjs(item.milestoneEndDate))
                setMilestone3TotalDays(item.milestoneTotalDays)
                setMilestone3CurrentlyActive(item.activeMilestone)
                setMilestone3Complete(item.completedMilestone)
                setMilestone3DaysComplete(item.milestoneDaysComplete)
            } else if (item?.milestonePosition === 4) {
                setMilestone4Active(item.milestoneToggleActive)
                setMilestone4Title(item.milestoneLabel)
                setMilestone4Goal(item.milestoneGoal)
                setMilestone4EndDateValue(dayjs(item.milestoneEndDate))
                setMilestone4TotalDays(item.milestoneTotalDays)
                setMilestone4CurrentlyActive(item.activeMilestone)
                setMilestone4Complete(item.completedMilestone)
                setMilestone4DaysComplete(item.milestoneDaysComplete)
            } else if (item?.milestonePosition === 5) {
                setMilestone5Active(item.milestoneToggleActive)
                setMilestone5Title(item.milestoneLabel)
                setMilestone5Goal(item.milestoneGoal)
                setMilestone5EndDateValue(dayjs(item.milestoneEndDate))
                setMilestone5TotalDays(item.milestoneTotalDays)
                setMilestone5CurrentlyActive(item.activeMilestone)
                setMilestone5Complete(item.completedMilestone)
                setMilestone5DaysComplete(item.milestoneDaysComplete)
            } else if (item?.milestonePosition === 6) {
                setMilestone6Active(item.milestoneToggleActive)
                setMilestone6Title(item.milestoneLabel)
                setMilestone6Goal(item.milestoneGoal)
                setMilestone6EndDateValue(dayjs(item.milestoneEndDate))
                setMilestone6TotalDays(item.milestoneTotalDays)
                setMilestone6CurrentlyActive(item.activeMilestone)
                setMilestone6Complete(item.completedMilestone)
                setMilestone6DaysComplete(item.milestoneDaysComplete)
            }
            return "";


        })
    }


    const { id, auditName, auditFamilyId, employeeId } = useParams();


    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData
    } = useContext(GraphDetailsContext);

    const navigate = useNavigate();

    const [projectTimelineData, setProjectTimelineData] = useState([]);

    function handleUpdateProjectTimelineData(update) {
        if (update !== null) {
            setProjectTimelineData(update)
        }
    }













    function handleGenerateNewTimeline() {
        console.log("VALUES: milestone0: Active:", milestone0Active, "milestone0Title: ", milestone0Title, "milestone0EndDate: ", milestone0EndDateValue, "milestone0Goal: ", milestone0Goal)
        console.log("VALUES: milestone1: Active:", milestone1Active, "milestone1Title: ", milestone1Title, "milestone1EndDate: ", milestone1EndDateValue, "milestone1Goal: ", milestone1Goal)
        console.log("VALUES: milestone2: Active:", milestone2Active, "milestone1Title: ", milestone2Title, "milestone1EndDate: ", milestone2EndDateValue, "milestone1Goal: ", milestone2Goal)

        console.log("VALUES: milestone3: Active:", milestone3Active, "milestone1Title: ", milestone3Title, "milestone1EndDate: ", milestone3EndDateValue, "milestone1Goal: ", milestone3Goal)

        console.log("VALUES: milestone4: Active:", milestone4Active, "milestone1Title: ", milestone4Title, "milestone1EndDate: ", milestone4EndDateValue, "milestone1Goal: ", milestone4Goal)

        console.log("VALUES: milestone5: Active:", milestone5Active, "milestone1Title: ", milestone5Title, "milestone1EndDate: ", milestone5EndDateValue, "milestone1Goal: ", milestone5Goal)

        console.log("VALUES: milestone6: Active:", milestone6Active, "milestone1Title: ", milestone6Title, "milestone1EndDate: ", milestone6EndDateValue, "milestone1Goal: ", milestone6Goal)


        let newMilestoneData = [];

        if (milestone0Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneId": i,
                "activeMilestone": milestone0CurrentlyActive,
                "completedMilestone": milestone0Complete,
                "milestoneLabel": milestone0Title,
                "milestoneEndDate": milestone0EndDateValue,
                "milestoneTotalDays": milestone0TotalDays,
                "milestoneDaysComplete": milestone0DaysComplete,
                "milestoneGoal": milestone0Goal,
                "milestoneToggleActive": milestone0Active,


            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone1Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneId": i,
                "activeMilestone": milestone1CurrentlyActive,
                "completedMilestone": milestone1Complete,
                "milestoneLabel": milestone1Title,
                "milestoneEndDate": milestone1EndDateValue,
                "milestoneTotalDays": milestone1TotalDays,
                "milestoneDaysComplete": milestone1DaysComplete,
                "milestoneGoal": milestone1Goal,
                "milestoneToggleActive": milestone1Active,


            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone2Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneId": i,
                "activeMilestone": milestone2CurrentlyActive,
                "completedMilestone": milestone2Complete,
                "milestoneLabel": milestone2Title,
                "milestoneEndDate": milestone2EndDateValue,
                "milestoneTotalDays": milestone2TotalDays,
                "milestoneDaysComplete": milestone2DaysComplete,
                "milestoneGoal": milestone2Goal,
                "milestoneToggleActive": milestone2Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone3Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneId": i,
                "activeMilestone": milestone3CurrentlyActive,
                "completedMilestone": milestone3Complete,
                "milestoneLabel": milestone3Title,
                "milestoneEndDate": milestone3EndDateValue,
                "milestoneTotalDays": milestone3TotalDays,
                "milestoneDaysComplete": milestone3DaysComplete,
                "milestoneGoal": milestone3Goal,
                "milestoneToggleActive": milestone3Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone4Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneId": i,
                "activeMilestone": milestone4CurrentlyActive,
                "completedMilestone": milestone4Complete,
                "milestoneLabel": milestone4Title,
                "milestoneEndDate": milestone4EndDateValue,
                "milestoneTotalDays": milestone4TotalDays,
                "milestoneDaysComplete": milestone4DaysComplete,
                "milestoneGoal": milestone4Goal,
                "milestoneToggleActive": milestone4Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone5Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneId": i,
                "activeMilestone": milestone5CurrentlyActive,
                "completedMilestone": milestone5Complete,
                "milestoneLabel": milestone5Title,
                "milestoneEndDate": milestone5EndDateValue,
                "milestoneTotalDays": milestone5TotalDays,
                "milestoneDaysComplete": milestone5DaysComplete,
                "milestoneGoal": milestone5Goal,
                "milestoneToggleActive": milestone5Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone6Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneId": i,
                "activeMilestone": milestone6CurrentlyActive,
                "completedMilestone": milestone6Complete,
                "milestoneLabel": milestone6Title,
                "milestoneEndDate": milestone6EndDateValue,
                "milestoneTotalDays": milestone6TotalDays,
                "milestoneDaysComplete": milestone6DaysComplete,
                "milestoneGoal": milestone6Goal,
                "milestoneToggleActive": milestone6Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        console.log("NEW MILESTONE DATA: ", newMilestoneData);

        setProjectTimelineData([
            {
                "auditId": id,
                "batchEndDate": cardBatchEndDate,
                "timelineId": cardTimelineId,
                "familyStartId": cardFamilyStartId,
                "familyEndId": cardFamilyEndId,
                "totalCount": cardTotalCount,
                "active": true,
                "timelineResponseRate": cardResponseRate,
                "newTimeline": false,
                "timelineTotalDays": cardTotalDays,
                "timelineCurrentDay": cardCurrentDay,
                "activeMilestonePosition": cardActiveMilestonePosition,
                "milestones": newMilestoneData,

            }
        ])

        //update state through context
        handleUpdateTimelineMilestonesByBatchEndDate(cardBatchEndDate, newMilestoneData)
        handleUpdateReloadClientProjectTimelineData(true);


        ///TODO - Call thing that actually updates the network resource for project timelines by client ID (currently being built else where)



        //close modal
        setOpenModal(false);



        //setMainSearchFilterModel([])
        //apiRef.current.setFilterModel({
        //    items: []

        //});



        //handleGetAndSetAllFiltersForUpdate()
    }






    useEffect(() => {
        getFormattedEditMilesstoneData(cardMilestoneData)

    }, [])

    useEffect(() => {
        console.log("New Timeline updated data: ", projectTimelineData)
        handleUpdateReloadClientProjectTimelineData(true);
    }, [projectTimelineData])




    /*let stringedStateObj = JSON.stringify(stateObj)*/



    return (
        <>

            <Card style={{ height: desiredHeightPercentage != null ? (desiredHeightPercentage) : ('90%') }} sx={{
                mr: 1.5,
                mb: 1.5,
                minWidth: desiredCardWidth ? (desiredCardWidth) : (75),
                minHeight: desiredCardHeight ? (desiredCardHeight) : (400),
                height: desiredCardHeight ? (desiredCardHeight) : (400),
                width: desiredCardWidth ? (desiredCardWidth) : (400)
            }}>


                {cardType == 'generic' ? (<>
                    {cardGraphic !== null ? (<>
                        <Typography sx={{ mt: 1 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            <div style={{ textAlign: 'center', fontSize: '300%', color: '#233cb0' }}>{cardGraphic}</div>

                        </Typography>

                    </>) : (<></>)}
                    <Typography sx={{ mt: 0 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                        {cardTitle}

                    </Typography>

                    <Typography sx={{ mt: 1 }} textAlign="center" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                        {cardSubtitle}

                    </Typography>

                    {/* <Button onClick={handleOpenModal} sx={{ ml: "45.5%", mt: 1.5, bgcolor: '#233cb0', fontWeight: '700', width: '2VW' }} variant="contained">Start</Button>*/}

                </>) : (<></>)}

                {cardType == 'audit' ? (<>
                    <div className="row-no-margin">
                        <Typography sx={{ mt: 2, ml: 2 }} textAlign="left" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            {cardTitle}

                        </Typography>
                        <Typography sx={{ mt: 2, ml: 2, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                            {cardSubtitle}

                        </Typography>

                        {cardExtraSubtitle !== null ? (<>
                            <Typography sx={{ mt: 2, ml: 2, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                {cardExtraSubtitle}

                            </Typography>
                        </>) : (<></>)}
                        <div className="row-right">
                            {/*                            <Chip variant={cardChipStatus === 'unset' ? "outlined" : ""} color={cardChipStatus === 'unset' ? "warning" : "success"} label={cardChipLabel} sx={{ color: cardChipStatus === 'unset' ? "#EA580C" : "", bgcolor: cardChipStatus === 'unset' ? "" : "#2DD4BF", mr: 1, mt: '2.5VH', fontWeight: '700' }} />*/}
                            <div className="row-no-left-margin">
                                <div className="row-right">
                                    <Button onClick={handleOpenModal} sx={{ mt: cardChipStatus === 'unset' ? 0 : 0, mr: 1.5, bgcolor: '#233cb0', fontWeight: '700', width: '2VW' }} variant="contained">{cardChipStatus === 'unset' ? 'Start' : 'Edit'}</Button>

                                </div>
                            </div>
                        </div>


                    </div>




                    {cardExtraSubtitle2 !== null ? (<>
                        <Typography sx={{ mt: 3 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            {cardExtraSubtitle2}

                        </Typography>
                    </>) : (<></>)}

                    {cardResponseRate !== null ? (<>
                        <div className="row-no-margin">
                            {/* NEED SPACE FOR RESPONSE RATE GRAPH AND TIMELINE CONTAINER  */}
                            <CircularProgressWithLabel value={cardResponseRate} />
                            <Box sx={{ width: "1VW" }}></Box>
                            {cardMilestoneData !== null ? (<>
                                {cardMilestoneData?.map((item) => {
                                    if (item.milestoneToggleActive === true) {
                                        return (<Box sx={{ mt: "-2VH", ml: 3, mr: 0, width: item.milestoneTotalDays !== 1 ? `${(2.75 * (item.milestoneTotalDays / 7)) + 3}VW` : "7VW", }}>
                                            <Box sx={{ position: "relative", mb: "1.25VH" }}>
                                                <Typography sx={{ mt: '-0.75VH', ml: item.milestonePosition === 0 ? "-1VW" : "-3.05VW", mr: "0%", color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                                    {dayjs(item.milestoneEndDate).format("MM/DD")}

                                                </Typography>
                                            </Box>
                                            <Box sx={{ mt: "-2VH", ml: 0, width: item.milestoneTotalDays !== 1 ? `${(2.75 * (item.milestoneTotalDays / 7)) + 3.25}VW` : "8VW", height: "1VH" }}>
                                                <Typography sx={{ zIndex: 10, mt: '2VH', mb: 0, ml: item.milestoneLabel === "Initial Notice" ? `0VW` : "0.00VW" && item.milestoneLabel === "Reminder" ? "0VW" : "0VW" && item.milestoneLabel === "Final Deadline" ? "-0.5VW" : "0VW" && item.milestoneLabel === "Deadline" ? "0VW" : "0VW", mr: "0%", fontWeight: item.activeMilestone === true ? "600" : '', color: item.activeMilestone === true ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                                    {item.milestoneLabel}

                                                </Typography>
                                            </Box>
                                            {item.completedMilestone === true ? (<>
                                                <Box sx={{ position: "relative", ml: "0VW", mt: "1VH" }}>
                                                    <BorderLinearProgress variant="determinate" value={100} sx={{ width: item.milestoneTotalDays !== 1 ? `${3 * (item.milestoneTotalDays / 7)}VW` : "0.5VW" }} />
                                                </Box>
                                            </>) : (<>
                                                {item.completedMilestone === false && item.activeMilestone === true ? (<>
                                                    <Box sx={{ position: "relative", ml: "0VW", mt: "1VH" }}>
                                                        <BorderLinearProgress variant="determinate" value={(item.milestoneDaysComplete / item.milestoneTotalDays) * 100} sx={{ width: item.milestoneTotalDays !== 1 ? `${3 * (item.milestoneTotalDays / 7)}VW` : "0.5VW" }} />
                                                    </Box>
                                                </>) : (<>
                                                    <Box sx={{ position: "relative", ml: "0VW", mt: "1VH" }}>
                                                        <BorderLinearProgress variant="determinate" value={0} sx={{ width: item.milestoneTotalDays !== 1 ? `${3 * (item.milestoneTotalDays / 7)}VW` : "0.5VW" }} />
                                                    </Box>
                                                </>)}
                                            </>)}





                                            {item.milestonePosition !== 5 ? (<>
                                                <Box sx={{ position: "relative", mt: "0.5VH" }}>
                                                    <Typography sx={{ mt: '-.05VH', ml: "0%", mr: "-1VW", color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="right" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                                        {`Goal: ${item.milestoneGoal}%`}

                                                    </Typography>
                                                </Box>
                                                <Divider orientation="vertical" variant="middle" position="absolute" sx={{ ml: "-0.55VW", mr: '0.85VW', mt: "-5.95VH", height: "3.85VH" }} />
                                            </>) : (<>

                                            </>)}

                                        </Box>)
                                    } else {
                                        return (<></>);
                                    }

                                })}
                            </>) : (<></>)}









                        </div>
                    </>) : (<></>)}




                </>) : (<></>)}



                {/*<div className="row-no-left-margin">*/}

                {/*    <div className="row-right">*/}




                {/*    </div>*/}

                {/*</div>*/}
                <Container sx={{ ml: '-4.5VW', mb: '-0.5VH', mt: '-4VH' }}>
                    <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        <Grid item xs={4} >



                        </Grid>

                        <Grid item xs={8} >



                        </Grid>
                    </Grid>
                </Container>













            </Card>


            <BootstrapDialog
                fullWidth={true}
                maxWidth={'lg'}
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle sx={{ fontWeight: 700, fontSize: '20px' }} id="customized-dialog-title" onClose={handleCloseModal}>
                    Edit Project Timeline
                </BootstrapDialogTitle>
                <form

                >
                    <DialogContent>
                        {/*//FORM CONTENT GOES HERE*/}

                        {cardMilestoneData?.length !== 0 ? (<>
                            {cardMilestoneData?.map((itemx) => {


                                return (
                                    <>
                                        {/* 
                                        DEBUG
                                         <Typography sx={{ mt: 0.5, ml: 2, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">
                                            label:{itemx?.milestoneLabel}+EndDate:{dayjs(itemx?.milestoneEndDate).toString()}+Goal:{itemx?.milestoneGoal}+ToggleActive:{itemx?.milestoneToggleActive === true ? "true" : "false"}
                                        </Typography>

                                        */ }




                                    </>
                                )


                            })}
                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone0Title}
                                    disabled={milestone0Active !== true ? true : false}
                                    onChange={handleMilestone0TitleChange}
                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone0EndDateValue}
                                        onChange={handleMilestone0EndDateValueUpdate}
                                        label={milestone0Title !== "" ? `${milestone0Title} Milestone Date` : 'Milestone Date'}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}
                                        disabled={milestone0Active !== true ? true : false}
                                    />

                                </LocalizationProvider>

                                {/*<TextField*/}
                                {/*    label="Date"*/}
                                {/*    variant="outlined"*/}
                                {/*    value={""}*/}


                                {/*    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}*/}


                                {/*/>*/}

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone0Active !== true ? true : false}
                                    label={milestone0Title !== "" ? `${milestone0Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone0Goal}

                                    onValueChange={(values) => {
                                        handleMilestone0GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />



                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone1Active(!milestone1Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone1Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone1Title}
                                    disabled={milestone1Active !== true ? true : false}
                                    onChange={handleMilestone1TitleChange}
                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone1EndDateValue}
                                        onChange={handleMilestone1EndDateValueUpdate}
                                        label={milestone1Title !== "" ? `${milestone1Title} Milestone End Date` : 'Milestone End Date'}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}
                                        disabled={milestone1Active !== true ? true : false}
                                    />

                                </LocalizationProvider>

                                {/*<TextField*/}
                                {/*    label="Date"*/}
                                {/*    variant="outlined"*/}
                                {/*    value={""}*/}


                                {/*    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}*/}


                                {/*/>*/}

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone1Active !== true ? true : false}
                                    label={milestone1Title !== "" ? `${milestone1Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone1Goal}

                                    onValueChange={(values) => {
                                        handleMilestone1GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />



                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone1Active(!milestone1Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone1Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>


                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone2Title}
                                    onChange={handleMilestone2TitleChange}
                                    disabled={milestone2Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone2EndDateValue}
                                        onChange={handleMilestone2EndDateValueUpdate}
                                        label={milestone2Title !== "" ? `${milestone2Title} Milestone End Date` : 'Milestone End Date'}
                                        disabled={milestone2Active !== true ? true : false}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone2Active !== true ? true : false}
                                    label={milestone2Title !== "" ? `${milestone2Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone2Goal}

                                    onValueChange={(values) => {
                                        handleMilestone2GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                <FormControlLabel
                                    onClick={() => handleUpdateMilestone2Active(!milestone2Active)}
                                    control={<IOSSwitch sx={{ m: 1 }} checked={milestone2Active} />}
                                    label="Active"
                                />

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone3Title}
                                    onChange={handleMilestone3TitleChange}
                                    disabled={milestone3Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone3EndDateValue}
                                        onChange={handleMilestone3EndDateValueUpdate}
                                        disabled={milestone3Active !== true ? true : false}
                                        label={milestone3Title !== "" ? `${milestone3Title} Milestone End Date` : 'Milestone End Date'}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone3Active !== true ? true : false}
                                    label={milestone3Title !== "" ? `${milestone3Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone3Goal}

                                    onValueChange={(values) => {
                                        handleMilestone3GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                <FormControlLabel
                                    onClick={() => handleUpdateMilestone3Active(!milestone3Active)}
                                    control={<IOSSwitch sx={{ m: 1 }} checked={milestone3Active} />}
                                    label="Active"
                                />

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone4Title}
                                    onChange={handleMilestone4TitleChange}
                                    disabled={milestone4Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone4EndDateValue}
                                        onChange={handleMilestone4EndDateValueUpdate}
                                        disabled={milestone4Active !== true ? true : false}
                                        label={milestone4Title !== "" ? `${milestone4Title} Milestone End Date` : 'Milestone End Date'}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone4Active !== true ? true : false}
                                    label={milestone4Title !== "" ? `${milestone4Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone4Goal}

                                    onValueChange={(values) => {
                                        handleMilestone4GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone4Active(!milestone4Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone4Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone5Title}
                                    onChange={handleMilestone5TitleChange}
                                    disabled={milestone5Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone5EndDateValue}
                                        onChange={handleMilestone5EndDateValueUpdate}
                                        disabled={milestone5Active !== true ? true : false}
                                        label={milestone5Title !== "" ? `${milestone5Title} Milestone End Date` : 'Milestone End Date'}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone5Active !== true ? true : false}
                                    label={milestone5Title !== "" ? `${milestone5Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone5Goal}

                                    onValueChange={(values) => {
                                        handleMilestone5GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone5Active(!milestone5Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone5Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone6Title}
                                    onChange={handleMilestone6TitleChange}
                                    disabled={milestone6Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone6EndDateValue}
                                        onChange={handleMilestone6EndDateValueUpdate}
                                        disabled={milestone6Active !== true ? true : false}
                                        label={milestone6Title !== "" ? `${milestone6Title} Milestone End Date` : 'Milestone End Date'}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone6Active !== true ? true : false}
                                    label={milestone6Title !== "" ? `${milestone6Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone6Goal}

                                    onValueChange={(values) => {
                                        handleMilestone6GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone6Active(!milestone6Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone6Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>


                        </>) : (<>
                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone1Title}
                                    disabled={milestone1Active !== true ? true : false}
                                    onChange={handleMilestone1TitleChange}
                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone1EndDateValue}
                                        onChange={handleMilestone1EndDateValueUpdate}
                                        label={milestone1Title !== "" ? `${milestone1Title} Milestone End Date` : 'Milestone End Date'}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}
                                        disabled={milestone1Active !== true ? true : false}
                                    />

                                </LocalizationProvider>



                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone1Active !== true ? true : false}
                                    label={milestone1Title !== "" ? `${milestone1Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone1Goal}

                                    onValueChange={(values) => {
                                        handleMilestone1GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />



                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone1Active(!milestone1Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone1Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>


                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone2Title}
                                    onChange={handleMilestone2TitleChange}
                                    disabled={milestone2Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone2EndDateValue}
                                        onChange={handleMilestone2EndDateValueUpdate}
                                        label={milestone2Title !== "" ? `${milestone2Title} Milestone End Date` : 'Milestone End Date'}
                                        disabled={milestone2Active !== true ? true : false}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone2Active !== true ? true : false}
                                    label={milestone2Title !== "" ? `${milestone2Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone2Goal}

                                    onValueChange={(values) => {
                                        handleMilestone2GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                <FormControlLabel
                                    onClick={() => handleUpdateMilestone2Active(!milestone2Active)}
                                    control={<IOSSwitch sx={{ m: 1 }} checked={milestone2Active} />}
                                    label="Active"
                                />

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone3Title}
                                    onChange={handleMilestone3TitleChange}
                                    disabled={milestone3Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone3EndDateValue}
                                        onChange={handleMilestone3EndDateValueUpdate}
                                        disabled={milestone3Active !== true ? true : false}
                                        label={milestone3Title !== "" ? `${milestone3Title} Milestone End Date` : 'Milestone End Date'}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone3Active !== true ? true : false}
                                    label={milestone3Title !== "" ? `${milestone3Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone3Goal}

                                    onValueChange={(values) => {
                                        handleMilestone3GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                <FormControlLabel
                                    onClick={() => handleUpdateMilestone3Active(!milestone3Active)}
                                    control={<IOSSwitch sx={{ m: 1 }} checked={milestone3Active} />}
                                    label="Active"
                                />

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone4Title}
                                    onChange={handleMilestone4TitleChange}
                                    disabled={milestone4Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone4EndDateValue}
                                        onChange={handleMilestone4EndDateValueUpdate}
                                        disabled={milestone4Active !== true ? true : false}
                                        label={milestone4Title !== "" ? `${milestone4Title} Milestone End Date` : 'Milestone End Date'}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone4Active !== true ? true : false}
                                    label={milestone4Title !== "" ? `${milestone4Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone4Goal}

                                    onValueChange={(values) => {
                                        handleMilestone4GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone4Active(!milestone4Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone4Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone5Title}
                                    onChange={handleMilestone5TitleChange}
                                    disabled={milestone5Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone5EndDateValue}
                                        onChange={handleMilestone5EndDateValueUpdate}
                                        disabled={milestone5Active !== true ? true : false}
                                        label={milestone5Title !== "" ? `${milestone5Title} Milestone End Date` : 'Milestone End Date'}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone5Active !== true ? true : false}
                                    label={milestone5Title !== "" ? `${milestone5Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone5Goal}

                                    onValueChange={(values) => {
                                        handleMilestone5GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone5Active(!milestone5Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone5Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>

                            <div className="modal-row">
                                <TextField
                                    label="Milestone Title"
                                    variant="outlined"
                                    value={milestone6Title}
                                    onChange={handleMilestone6TitleChange}
                                    disabled={milestone6Active !== true ? true : false}

                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        value={milestone6EndDateValue}
                                        onChange={handleMilestone6EndDateValueUpdate}
                                        disabled={milestone6Active !== true ? true : false}
                                        label={milestone6Title !== "" ? `${milestone6Title} Milestone End Date` : 'Milestone End Date'}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                </LocalizationProvider>

                                <NumericFormat
                                    customInput={TextField}
                                    {...materialUITextFieldProps}
                                    disabled={milestone6Active !== true ? true : false}
                                    label={milestone6Title !== "" ? `${milestone6Title} Milestone Goal` : 'Milestone Goal'}
                                    value={milestone6Goal}

                                    onValueChange={(values) => {
                                        handleMilestone6GoalUpdate({ target: { value: values.value } })
                                    }

                                    }



                                    valueIsNumericString={true}
                                    suffix="%"
                                />

                                {/*<FormControlLabel*/}
                                {/*    onClick={() => handleUpdateMilestone6Active(!milestone6Active)}*/}
                                {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone6Active} />}*/}
                                {/*    label="Active"*/}
                                {/*/>*/}

                            </div>
                        </>)}



                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" autoFocus onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleGenerateNewTimeline()}
                            variant="contained"

                            autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        </>
    );
}

export default EditTimelineCardStartNotice;

