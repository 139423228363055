
import React, { useContext, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import { useParams, useNavigate, Navigate, Link, redirect } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";

import Divider from '@mui/material/Divider';




import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";
import { CgOptions } from "react-icons/cg";
import { format, formatISO } from 'date-fns'
import { styled, useTheme } from '@mui/material/styles';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';

import Badge from '@mui/material/Badge';


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        height: '32px',
        width: '32px',
        right: 20,
        top: 20,
        borderRadius: '50%',
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 0px',
        bgcolor: '#EB5757',
    },
}));

const StyledBadgeA = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        height: '32px',
        width: '32px',
        right: 20,
        top: 20,
        borderRadius: '50%',
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 0px',
        backgroundColor: '#E97132',
        color: '#ffffff'
    },
}));


const StyledBadgeB = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        height: '32px',
        width: '32px',
        right: 20,
        top: 20,
        borderRadius: '50%',
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 0px',
        backgroundColor: '#F6BE00',
        color: '#ffffff'
    },
}));


function GraphDetailsTable({ tableData, tableColumns, tableTitle, tableFilterModel, tableFilterInputs, desiredWidth, desiredHeight, quickFilterButtons, tableBadgeDate }) {

   

    const { id, auditName } = useParams();

    function getDependentID(row) {
        return row.rowID;
    }

    function hashStringValue(string) {
        return string.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
        }, 0);
    }

    //function getRowID(row) {
    //    if (tableTitle == 'Family Status') {
    //        return row.auditId;
    //    } else if (tableTitle == 'Incomplete Reason') {
    //        return row.dependentBirthDate + row.auditId;
    //    } else if (tableTitle == 'Dependent Status') {
    //        return row.dependentBirthDate + row.auditId;
    //    } else if (tableTitle == 'Ineligible Reason') {
    //        return row.dependentBirthDate + row.auditId;
    //    } else if (tableTitle == 'Alert Report') {
    //        return row.alertRowId;
    //    } else {
    //        return row.auditId;
    //    }
       
    //}

    function getRowID(row) {
         if (tableTitle == 'Alert Report') {
            return row.alertRowId;
        } else {
            return row.altRowId;
        }

    }

    function getDependentRelationship(row) {
        console.log("ROW -----", row);

        if (row.dependent_relationship === "S ") {
            return 'Spouse'
        } else if (row.dependent_relationship === "D ") {
            return 'Dependent'
        } else {
            return `DBValue: ${row.dependent_relationship}`
        }
    }

    function getPreviousSearchFieldName(field) {


        if (field === "last_name") {
            return 'LAST NAME: '
        } else if (field === "first_name") {
            return 'FIRST NAME: '
        } else if (field === "audit_family_id") {
            return 'AUDIT FAMILY ID: '
        } else if (field === "date_of_birth") {
            return 'DATE OF BIRTH: '
        } else if (field === "email") {
            return 'EMAIL: '
        } else {
            return field;
        }
    }

    const apiRef = useGridApiRef();

   
   
 

    const [employeeOnlySearch, setEmployeeOnlySearch] = React.useState(false);

    function handleUpdateEmployeeOnlySearch(newStatus) {
        setEmployeeOnlySearch(newStatus)
    }

    const [previousSearchWasEmployeeOnly, setPreviousSearchWasEmployeeOnly] = useState(false);

    function handleUpdatePreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus) {

        setPreviousSearchWasEmployeeOnly(updatedWasEmployeeOnlyStatus);
    }




    const [mainSearchFilterModel, setMainSearchFilterModel] = React.useState([]);
    const [mainSearchFilterModelLogicalOperator, setMainSearchFilterModelLogicalOperator] = React.useState("");

    const [searchFirstNameExactMatch, setSearchFirstNameExactMatch] = React.useState(false);

    function handleUpdateSearchFirstNameExactMatch(newFirstExactMatchStatus) {
        setSearchFirstNameExactMatch(newFirstExactMatchStatus);
    }

    const [searchLastNameExactMatch, setSearchLastNameExactMatch] = React.useState(false);

    function handleUpdateSearchLastNameExactMatch(newLastExactMatchStatus) {
        setSearchLastNameExactMatch(newLastExactMatchStatus);
    }

    const [generateNewSearchFilterModel, setGenerateNewSearchFilterModel] = React.useState(false);

    const [searchValueAuditID, setSearchValueAuditID] = React.useState("");
    const [savedLastSearchValueAuditID, setSavedLastSearchValueAuditID] = React.useState("");

    function handleSearchValueAuditIDChange(event) {
        event.preventDefault();
        setSearchValueAuditID(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "audit_id",
        //        SearchValue: searchValueAuditID,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);

        //}

    }


    const [searchValueClient, setSearchValueClient] = React.useState("");

    function handleSearchValueClientChange(event) {

        setSearchValueClient(event.target.value);

        //if (generateNewSearchFilterModel == true) {
        //    const newFilterParameterItem = {
        //        FieldType: "client",
        //        SearchValue: searchValueClient,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    const [searchValueClientExactMatch, setSearchValueClientExactMatch] = React.useState(false);

    function handleUpdateSearchValueClientExactMatch(newExactMatchStatus) {
        setSearchValueClientExactMatch(newExactMatchStatus);
    }


    const [searchValueFirstName, setSearchValueFirstName] = React.useState("");

    function handleSearchValueFirstNameChange(event) {
        setSearchValueFirstName(event.target.value);
        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "first_name",
        //        SearchValue: searchValueFirstName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueLastName, setSearchValueLastName] = React.useState("");

    function handleSearchValueLastNameChange(event) {

        setSearchValueLastName(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "last_name",
        //        SearchValue: searchValueLastName,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueDOB, setSearchValueDOB] = React.useState(null);

    function handleSearchValueDOBChange(value) {
        /* console.log("DOB CHANGE ------", event.target?.value)*/
        if (value !== null) {
            setSearchValueDOB(value);
        }


        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: "date_of_birth",
        //        SearchValue: searchValueDOB,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }


    const [searchValueEmailAddress, setSearchValueEmailAddress] = React.useState("");


    function handleSearchValueEmailAddressChange(event) {

        setSearchValueEmailAddress(event.target.value);

        //if (generateNewSearchFilterModel === true) {
        //    const newFilterParameterItem = {
        //        FieldType: 'email',
        //        SearchValue: searchValueEmailAddress,
        //        OperatorValue: 'contains'
        //    };


        //    handleUpdateMainSearchFilterModel(newFilterParameterItem);
        //}
    }

    ///searchValueReason

    const [searchValueReason, setSearchValueReason] = React.useState("");


    function handleSearchValueReasonChange(event) {

        setSearchValueReason(event.target.value);


    }

    ///searchValueFamilyStatus

    const [searchValueFamilyStatus, setSearchValueFamilyStatus] = React.useState("");


    function handleSearchValueFamilyStatusChange(event) {

        setSearchValueFamilyStatus(event.target.value);


    }


    ///searchValueAuditEndDate

    const [searchValueAuditEndDate, setSearchValueAuditEndDate] = React.useState(null);


    function handleSearchValueAuditEndDateChange(value) {
        if (value !== null) {
            setSearchValueAuditEndDate(value);
        }

    }


    ///searchValueLocation

    const [searchValueLocation, setSearchValueLocation] = React.useState("");


    function handleSearchValueLocationChange(event) {

        setSearchValueLocation(event.target.value);


    }

    ///searchValueDependentRelationship

    const [searchValueDependentRelationship, setSearchValueDependentRelationship] = React.useState("");


    function handleSearchValueDependentRelationshipChange(event) {

        setSearchValueDependentRelationship(event.target.value);


    }

    ///searchValueDependentRelationshipType

    const [searchValueDependentRelationshipType, setSearchValueDependentRelationshipType] = React.useState("");


    function handleSearchValueDependentRelationshipTypeChange(event) {

        setSearchValueDependentRelationshipType(event.target.value);


    }

    ///searchValueDependentStatus

    const [searchValueDependentStatus, setSearchValueDependentStatus] = React.useState("");


    function handleSearchValueDependentStatusChange(event) {

        setSearchValueDependentStatus(event.target.value);


    }

    ///searchValueDependentLastName

    const [searchValueDependentLastName, setSearchValueDependentLastName] = React.useState("");


    function handleSearchValueDependentLastNameChange(event) {

        setSearchValueDependentLastName(event.target.value);


    }

    ///searchValueDependentFirstName

    const [searchValueDependentFirstName, setSearchValueDependentFirstName] = React.useState("");


    function handleSearchValueDependentFirstNameChange(event) {

        setSearchValueDependentFirstName(event.target.value);


    }

    ///searchValueStatus

    const [searchValueStatus, setSearchValueStatus] = React.useState("");


    function handleSearchValueStatusChange(event) {

        setSearchValueStatus(event.target.value);


    }

    ///searchValueDependentNoteType

    const [searchValueDependentNoteType, setSearchValueDependentNoteType] = React.useState("");


    function handleSearchValueDependentNoteTypeChange(event) {

        setSearchValueDependentNoteType(event.target.value);


    }

    ///searchValueDependentAlertNote

    const [searchValueDependentAlertNote, setSearchValueDependentAlertNote] = React.useState("");


    function handleSearchValueDependentAlertNoteChange(event) {

        setSearchValueDependentAlertNote(event.target.value);


    }

    ///searchValueFamilyNoteType

    const [searchValueFamilyNoteType, setSearchValueFamilyNoteType] = React.useState("");


    function handleSearchValueFamilyNoteTypeChange(event) {

        setSearchValueFamilyNoteType(event.target.value);


    }

    ///searchValueFamilyAlertNote

    const [searchValueFamilyAlertNote, setSearchValueFamilyAlertNote] = React.useState("");


    function handleSearchValueFamilyAlertNoteChange(event) {

        setSearchValueFamilyAlertNote(event.target.value);


    }

    ///searchValueMemberFirstName

    const [searchValueMemberFirstName, setSearchValueMemberFirstName] = React.useState("");


    function handleSearchValueMemberFirstNameChange(event) {

        setSearchValueMemberFirstName(event.target.value);


    }

    ///searchValueMemberLastName

    const [searchValueMemberLastName, setSearchValueMemberLastName] = React.useState("");


    function handleSearchValueMemberLastNameChange(event) {

        setSearchValueMemberLastName(event.target.value);


    }

    ///searchValueRelationshipType

    const [searchValueRelationshipType, setSearchValueRelationshipType] = React.useState("");


    function handleSearchValueRelationshipTypeChange(event) {
        setSearchValueRelationshipType(event.target.value);
    }


    function handleGenerateQuickFilter(quickFilterToGenerate) {
        let filterType = quickFilterToGenerate;

        setMainSearchFilterModel([])
        apiRef.current.setFilterModel({
            items: []

        });

        let newMainSearchFilterModel = [];
        let newLogicalSearchOperator = null;

        if (filterType === 'Address Change') {
            //setSearchValueFamilyNoteType("Address Change")
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'familyNoteType',
                value: "Address Change",
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        } else if (filterType === 'Mail Returned') {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'familyNoteType',
                value: 'Mail Returned',
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        } else if (filterType === 'Alert') {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItemA = {
                id: newItemID,
                field: 'familyNoteType',
                value: 'Alert',
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemA];

           
                let j = newMainSearchFilterModel?.length;
                let newItemIDb = j + 1;

            const newSearchFilterModelItemB = {
                id: newItemIDb,
                field: 'dependentNoteType',
                value: 'Alert',
                operator: 'contains'
            };
               
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItemB];

            newLogicalSearchOperator = "or";
            

        }

     

    //if (searchValueFamilyNoteType !== "") {
      
    //}

    handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalSearchOperator)



       

        

    }



    function handleGetAndSetAllFiltersForUpdate() {



        apiRef.current.setFilterModel({
            items: []

        });

        let newMainSearchFilterModel = [];
        let newLogicalSearchOperator = null;


        if (searchValueAuditID !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "auditId",
                value: searchValueAuditID,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueClient !== "") {
            if (searchValueClientExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "client",
                    value: searchValueClient,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

              
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "client",
                    value: searchValueClient,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                
            }

        }

        if (searchValueFirstName !== "") {
            if (searchFirstNameExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "employeeFirstName",
                    value: searchValueFirstName,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

               
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "employeeFirstName",
                    value: searchValueFirstName,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

               
            }


        }
        if (searchValueLastName !== "") {
            if (searchLastNameExactMatch === true) {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "employeeLastName",
                    value: searchValueLastName,
                    operator: 'equals'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

               
            } else {
                let i = newMainSearchFilterModel?.length;
                let newItemID = i + 1;

                const newSearchFilterModelItem = {
                    id: newItemID,
                    field: "employeeLastName",
                    value: searchValueLastName,
                    operator: 'contains'
                };
                newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

                
            }

        }
        if (searchValueDOB !== null) {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            /*let convertedForSearchFilterDate = searchValueDOB;*/
            let convertedForSearchFilterDate = convertUserProvidedDate(searchValueDOB)
            console.log("ConvertedSearchDate: ", convertedForSearchFilterDate);

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "date_of_birth",
                value: convertedForSearchFilterDate,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

           
        }
        if (searchValueEmailAddress !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'email',
                value: searchValueEmailAddress,
                operator: 'equals'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueFamilyStatus !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'familyStatus',
                value: searchValueFamilyStatus,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueReason !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'reason',
                value: searchValueReason,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueLocation !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'location',
                value: searchValueLocation,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueDependentRelationship !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'dependentRelationship',
                value: searchValueDependentRelationship,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueDependentRelationshipType !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'dependentRelationshipType',
                value: searchValueDependentRelationshipType,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }

        if (searchValueDependentStatus !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'dependentStatus',
                value: searchValueDependentStatus,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueDependentLastName !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'dependentLastName',
                value: searchValueDependentLastName,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueDependentFirstName !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'dependentFirstName',
                value: searchValueDependentFirstName,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueStatus !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'status',
                value: searchValueStatus,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueRelationshipType !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'relationshipType',
                value: searchValueRelationshipType,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueDependentNoteType !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'dependentNoteType',
                value: searchValueDependentNoteType,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueDependentAlertNote !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'dependentAlertNote',
                value: searchValueDependentAlertNote,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueFamilyNoteType !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'familyNoteType',
                value: searchValueFamilyNoteType,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }
        if (searchValueFamilyAlertNote !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'familyAlertNote',
                value: searchValueFamilyAlertNote,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
        }

        if (searchValueMemberFirstName !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'memberFirstName',
                value: searchValueMemberFirstName,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];



        }

        if (searchValueMemberLastName !== "") {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            const newSearchFilterModelItem = {
                id: newItemID,
                field: 'memberLastName',
                value: searchValueMemberLastName,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];



        }

        if (searchValueAuditEndDate !== null) {
            let i = newMainSearchFilterModel?.length;
            let newItemID = i + 1;

            /*let convertedForSearchFilterDate = searchValueDOB;*/
            let convertedForSearchFilterDate = convertUserProvidedDate(searchValueAuditEndDate)
            console.log("ConvertedSearchDate: ", convertedForSearchFilterDate);

            const newSearchFilterModelItem = {
                id: newItemID,
                field: "auditEndDate",
                value: convertedForSearchFilterDate,
                operator: 'contains'
            };
            newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];

           
        }

        handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalSearchOperator)





    }

    function handleClearSearchAndBackUpPreviousFilterModel() {
        console.log("CLEAR SEARCH AND BACK UP");
        //backup relevant values
        if (mainSearchFilterModel !== []) {
           
            if (mainSearchFilterModelLogicalOperator !== null) {
               

             
              
            }
           



        }
        //then clear out the existing
        handleUpdateMainSearchFilterModel(null);
    }

    function handleUpdateMainSearchFilterModel(newMainSearchFilterModel, newLogicalOperator) {



        if (newMainSearchFilterModel !== null) {
            //let newMainSearchFilterModel = mainSearchFilterModel;


            //let i = newMainSearchFilterModel?.length;
            //let newItemID = i + 1;

            //let newParameterFieldType = newFilterParameter?.FieldType;
            //let newParameterSearchValue = newFilterParameter?.SearchValue;
            //let newParameterOperatorValue = newFilterParameter?.OperatorValue;

            //let newSearchFilterModelItem = {
            //    id: newItemID,
            //    operator: newParameterOperatorValue,
            //    field: newParameterFieldType,
            //    value: newParameterSearchValue,
            //}
            if (newLogicalOperator !== null) {
                setMainSearchFilterModelLogicalOperator(newLogicalOperator)
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF - WITH LOGICAL OPERATOR: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel,
                    logicOperator: newLogicalOperator

                });
            } else {
                setMainSearchFilterModel(newMainSearchFilterModel);
                console.log("SETTING NEW APIREF: ", newMainSearchFilterModel)
                apiRef.current.setFilterModel({
                    items: newMainSearchFilterModel

                });
            }
            /////
            //if (newMainSearchFilterModel !== []) {
            //    handleUpdatePreviousSearchParameters(newMainSearchFilterModel)
            //    if (newLogicalOperator !== null) {
            //        handleUpdatePreviousSearchLogicalOperatorParameter(newLogicalOperator)
            //    }

            //}

            //newMainSearchFilterModel = [...newMainSearchFilterModel, newSearchFilterModelItem];
            //if (generateNewSearchFilterModel == true) {


        } else if (newMainSearchFilterModel == null) {
            setMainSearchFilterModel([]);
            apiRef.current.setFilterModel({
                items: []

            });
            console.log("SETTING NEW APIREF NULL: ", null)
            setSearchValueAuditID("")
            setSearchValueClient("")
            setSearchValueFirstName("")
            setSearchValueLastName("")
            setSearchValueDOB(null)
            setSearchValueEmailAddress("")
            setSearchValueReason("")
            setSearchValueFamilyStatus("")
            setSearchValueAuditEndDate(null)
            setSearchValueLocation("")
            setSearchValueDependentRelationship("")
            setSearchValueDependentRelationshipType("")
            setSearchValueDependentStatus("")
            setSearchValueDependentLastName("")
            setSearchValueDependentFirstName("")
            setSearchValueStatus("")
            setSearchValueRelationshipType("")
            setSearchValueDependentNoteType("")
            setSearchValueDependentAlertNote("")
            setSearchValueFamilyNoteType("")
            setSearchValueFamilyAlertNote("")
            setSearchValueMemberFirstName("")
            setSearchValueMemberLastName("")



            setGenerateNewSearchFilterModel(false)
        }
        //console.log("PREV SEARCH MODEL:", previousSearchParameters);
        //console.log("PREV SEARCH LOGICAL OPERATOR:", previousSearchLogicalOperatorParameter);
    }




    function handleGenerateNewMainSearchFilterModel() {
        /* setGenerateNewSearchFilterModel(true)*/
        setMainSearchFilterModel([])
        apiRef.current.setFilterModel({
            items: []

        });



        handleGetAndSetAllFiltersForUpdate()
    }

    function handleGeneratePreviousMainSearchFilterModel() {



        apiRef.current.setFilterModel({
            items: []

        });
        



    }


    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>

                <Box sx={{ mt: 1, fontWeight: 'bold' }}>Employee Search Report</Box>
                <Box sx={{ mt: 1 }}>Use the form above to generate a report</Box>
            </StyledGridOverlay>
        );
    }

    function CustomNoResultsOverlay() {
        return (
            <StyledGridOverlay>

                <Box sx={{ mt: 1, fontWeight: 'bold' }}>Sorry, that search did not yield any results.</Box>
                <Box sx={{ mt: 1 }}>Please try again with different Search Parameters</Box>
            </StyledGridOverlay>
        );
    }

    //const {
    //    openMobile,
    //    handleDrawerCloseMobile,
    //    handleDrawerOpenMobile,
    //    handleUpdateEmployeeOnlySearch,
    //    employeeOnlySearch,
    //    handleUpdateSearchValueClientExactMatch,
    //    searchValueClientExactMatch,
    //    handleUpdateSearchFirstNameExactMatch,
    //    searchFirstNameExactMatch,
    //    handleUpdateSearchLastNameExactMatch,
    //    searchLastNameExactMatch,
    //    handleSearchValueAuditIDChange,
    //    handleSearchValueClientChange,
    //    handleSearchValueFirstNameChange,
    //    handleSearchValueLastNameChange,
    //    handleSearchValueEmailAddressChange,
    //    handleSearchValueDOBChange,
    //    handleGeneratePreviousMainSearchFilterModel,
    //    previousSearchParameters,
    //    handleClearSearchAndBackUpPreviousFilterModel,
    //    handleGenerateNewMainSearchFilterModel
    //} = useContext(EmployeeSearchContext);

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }

  

    function convertUserProvidedDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'M/dd/yyyy');


        /* formatISO(new Date(date), { representation: 'date' })*/
    }

    const dataGridColumns = tableColumns;

    //const dataGridColumnsMediumOrSmall = [
    //    {
    //        "field": "audit_family_id",
    //        "headerName": "Audit Family",
    //        "width": 90
    //    },
    //    {
    //        "field": "first_name",
    //        "headerName": "First Name",
    //        "width": 120,
    //        "editable": false
    //    },
    //    {
    //        "field": "last_name",
    //        "headerName": "Last Name",
    //        "width": 150,
    //        "editable": false
    //    },
    //    {
    //        "field": "relationship",
    //        "headerName": "Relationship",
    //        "width": 100,
    //        "editable": false
    //    },
    //    {
    //        "field": "date_of_birth",
    //        "headerName": "DOB",
    //        "width": 160,
    //        "editable": false,
    //        renderCell: (params) =>
    //            renderDate(params.row?.date_of_birth)
    //    },
    //    {
    //        "field": "address",
    //        "headerName": "Address",
    //        "width": 450,
    //        "editable": false
    //    },
    //    {
    //        "field": "email",
    //        "headerName": "Email",
    //        "width": 230,
    //        "editable": false
    //    },
    //    {
    //        "field": "audit_end_date",
    //        "headerName": "End Date",
    //        "width": 150,
    //        "editable": false,
    //        renderCell: (params) =>
    //            renderDate(params.row?.audit_end_date)

    //    },

    //];

    let detailTableInputs = null;
    let quickFilterButtonsLocal = null;

    if (tableTitle == 'Family Status') {
        detailTableInputs = <>
            < TextField
                label="Audit ID"
                variant="outlined"
                value={searchValueAuditID}
                onChange={handleSearchValueAuditIDChange}

                sx={{ ml: 3, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />

            < LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateField
                    label="Audit End Date"
                    format="MM/DD/YYYY"
                    value={searchValueAuditEndDate}
                    onChange={(value) => handleSearchValueAuditEndDateChange(value)}
                    sx={{ ml: 0, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}

                />
            </LocalizationProvider >

            < TextField
                label="Member/Employee Last Name"
                variant="outlined"
                value={searchValueLastName}
                onChange={handleSearchValueLastNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Member/Employee First Name"
                variant="outlined"
                value={searchValueFirstName}
                onChange={handleSearchValueFirstNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />



            < TextField
                label="Family Status"
                variant="outlined"
                value={searchValueFamilyStatus}
                onChange={handleSearchValueFamilyStatusChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />





            < TextField
                label="Location"
                variant="outlined"
                value={searchValueLocation}
                onChange={handleSearchValueLocationChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

        </>

    } else if (tableTitle == 'Incomplete Reason') {
        detailTableInputs = <>
            < TextField
                label="Audit ID"
                variant="outlined"
                value={searchValueAuditID}
                onChange={handleSearchValueAuditIDChange}

                sx={{ ml: 3, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />









            < LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateField
                    label="Audit End Date"
                    format="MM/DD/YYYY"
                    value={searchValueAuditEndDate}
                    onChange={(value) => handleSearchValueAuditEndDateChange(value)}
                    sx={{ ml: 0, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}

                />
            </LocalizationProvider >

            < TextField
                label="Member/Employee Last Name"
                variant="outlined"
                value={searchValueLastName}
                onChange={handleSearchValueLastNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Member/Employee First Name"
                variant="outlined"
                value={searchValueFirstName}
                onChange={handleSearchValueFirstNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />



            < TextField
                label="Dependent Relationship Type"
                variant="outlined"
                value={searchValueDependentRelationshipType}
                onChange={handleSearchValueDependentRelationshipTypeChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />



            < TextField
                label="Reason"
                variant="outlined"
                value={searchValueReason}
                onChange={handleSearchValueReasonChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Location"
                variant="outlined"
                value={searchValueLocation}
                onChange={handleSearchValueLocationChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

        </>
    } else if (tableTitle == 'Dependent Status') {
        detailTableInputs = <>
            < TextField
                label="Audit ID"
                variant="outlined"
                value={searchValueAuditID}
                onChange={handleSearchValueAuditIDChange}

                sx={{ ml: 3, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }
                }


            />


            < LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateField
                    label="Audit End Date"
                    format="MM/DD/YYYY"
                    value={searchValueAuditEndDate}
                    onChange={(value) => handleSearchValueAuditEndDateChange(value)}
                    sx={{ ml: 0, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}

                />
            </LocalizationProvider >

            < TextField
                label="Member/Employee Last Name"
                variant="outlined"
                value={searchValueLastName}
                onChange={handleSearchValueLastNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Member/Employee First Name"
                variant="outlined"
                value={searchValueFirstName}
                onChange={handleSearchValueFirstNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />





            < TextField
                label="Dep Relationship Type"
                variant="outlined"
                value={searchValueRelationshipType}
                onChange={handleSearchValueRelationshipTypeChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />


            < TextField
                label="Dependent Status"
                variant="outlined"
                value={searchValueStatus}
                onChange={handleSearchValueStatusChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />



            < TextField
                label="Location"
                variant="outlined"
                value={searchValueLocation}
                onChange={handleSearchValueLocationChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />










        </>
    } else if (tableTitle == 'Ineligible Reason') {
        detailTableInputs = <>

            < TextField
                label="Audit ID"
                variant="outlined"
                value={searchValueAuditID}
                onChange={handleSearchValueAuditIDChange}

                sx={{ ml: 3, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }
                }


            />

            < LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateField
                    label="Audit End Date"
                    format="MM/DD/YYYY"
                    value={searchValueAuditEndDate}
                    onChange={(value) => handleSearchValueAuditEndDateChange(value)}
                    sx={{ ml: 0, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}

                />
            </LocalizationProvider >


            < TextField
                label="Member/Employee Last Name"
                variant="outlined"
                value={searchValueLastName}
                onChange={handleSearchValueLastNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Member/Employee First Name"
                variant="outlined"
                value={searchValueFirstName}
                onChange={handleSearchValueFirstNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />











            <TextField
                label="Dep Relationship Type"
                variant="outlined"
                value={searchValueDependentRelationshipType}
                onChange={handleSearchValueDependentRelationshipTypeChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />






            <TextField
                label="Reason"
                variant="outlined"
                value={searchValueReason}
                onChange={handleSearchValueReasonChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}
            />

            < TextField
                label="Location"
                variant="outlined"
                value={searchValueLocation}
                onChange={handleSearchValueLocationChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />




        </>
    } else if (tableTitle == 'Alert Report') {
        quickFilterButtonsLocal = <>
            { /* <StyledBadge badgeContent={tableBadgeDate} color="error">*/}
            <Button
                sx={{ color: '#ffffff', bgcolor: '#23549F', ml: '14VW', mt: 2, mr: 2, height: '45px', width: '200px', borderRadius: '6px 6px 6px 6px', }}
                variant="filled"
                aria-label="cart"
                onClick={() => handleGenerateQuickFilter("Alert")}
            >

                Alerts

            </Button>
            {/* </StyledBadge > */}
            {/*  <StyledBadge badgeContent={tableBadgeDate} color="error">
            <Button
                   sx={{ color: '#ffffff', bgcolor: '#23549F', mt: 2, mr: 2, height: '45px', width: '200px', borderRadius: '6px 6px 6px 6px', }}
                   variant="filled"
                   aria-label="cart"
                   disabled={true}
                  onClick={() => handleGenerateQuickFilter("Review")}
                >

                    Dependent Reviews

                </Button>}
            </StyledBadge>*/}
            {/*<StyledBadgeA badgeContent={tableBadgeDate}>*/}
                <Button
                    sx={{ color: '#ffffff', bgcolor: '#23549F', mt: 2, mr: 2, height: '45px', width: '200px', borderRadius: '6px 6px 6px 6px', }}
                    variant="filled"
                    aria-label="cart"
                    onClick={() => handleGenerateQuickFilter("Mail Returned")}
                >

                    Mail Returns

                </Button>
          {/*  </StyledBadgeA>*/}
            {/* <StyledBadgeB badgeContent={tableBadgeDate} >*/}
                <Button
                    sx={{ color: '#ffffff', bgcolor: '#23549F', mt: 2, mr: 2, height: '45px', width: '200px', borderRadius: '6px 6px 6px 6px', }}
                    variant="filled"
                    aria-label="cart"
                    onClick={() => handleGenerateQuickFilter("Address Change")}
                >

                    Address Changes

                </Button>
           {/* </StyledBadgeB>*/}
        </>;
        detailTableInputs = <>

            < TextField
                label="Audit ID"
                variant="outlined"
                value={searchValueAuditID}
                onChange={handleSearchValueAuditIDChange}

                sx={{ ml: 3, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }
                }


            />

            < LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateField
                    label="Audit End Date"
                    format="MM/DD/YYYY"
                    value={searchValueAuditEndDate}
                    onChange={(value) => handleSearchValueAuditEndDateChange(value)}
                    sx={{ ml: 0, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}

                />
            </LocalizationProvider >

          
            < TextField
                label="Member/Employee Last Name"
                variant="outlined"
                value={searchValueLastName}
                onChange={handleSearchValueLastNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Member/Employee First Name"
                variant="outlined"
                value={searchValueFirstName}
                onChange={handleSearchValueFirstNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />
            

           

            < TextField
                label="Dependent Note Type"
                variant="outlined"
                value={searchValueDependentNoteType}
                onChange={handleSearchValueDependentNoteTypeChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Family Note Type"
                variant="outlined"
                value={searchValueFamilyNoteType}
                onChange={handleSearchValueFamilyNoteTypeChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />


            < TextField
                label="Location"
                variant="outlined"
                value={searchValueLocation}
                onChange={handleSearchValueLocationChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />


        </>;
    } else if (tableTitle == "Employee / Member Status") {
        detailTableInputs = <>
            < TextField
                label="Audit ID"
                variant="outlined"
                value={searchValueAuditID}
                onChange={handleSearchValueAuditIDChange}

                sx={{ ml: 3, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }
                }


            />


            < LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateField
                    label="Audit End Date"
                    format="MM/DD/YYYY"
                    value={searchValueAuditEndDate}
                    onChange={(value) => handleSearchValueAuditEndDateChange(value)}
                    sx={{ ml: 0, mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}

                />
            </LocalizationProvider>

            < TextField
                label="Member/Employee Last Name"
                variant="outlined"
                value={searchValueMemberLastName}
                onChange={handleSearchValueMemberLastNameChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Member/Employee First Name"
                variant="outlined"
                value={searchValueMemberFirstName}
                onChange={handleSearchValueMemberFirstNameChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Location"
                variant="outlined"
                value={searchValueLocation}
                onChange={handleSearchValueLocationChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />

           

            < TextField
                label="Rel Type"
                variant="outlined"
                value={searchValueRelationshipType}
                onChange={handleSearchValueRelationshipTypeChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />

            < TextField
                label="Status"
                variant="outlined"
                value={searchValueStatus}
                onChange={handleSearchValueStatusChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />


            < TextField
                label="Reason"
                variant="outlined"
                value={searchValueReason}
                onChange={handleSearchValueReasonChange}

                sx={{ mr: '1VW', width: '8.75VW', bgcolor: '#FFFFFF' }}


            />











        </>
    } else if (tableTitle == 'TEST') {
        detailTableInputs = <>
            < TextField
                label="Audit ID"
                variant="outlined"
                value={searchValueAuditID}
                onChange={handleSearchValueAuditIDChange}

                sx={{ ml: 3, mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />
            < TextField
                label="First Name"
                variant="outlined"
                value={searchValueFirstName}
                onChange={handleSearchValueFirstNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }
                }


            />

            < TextField
                label="Last Name"
                variant="outlined"
                value={searchValueLastName}
                onChange={handleSearchValueLastNameChange}

                sx={{ mr: '1VW', width: '10VW', bgcolor: '#FFFFFF' }}


            />
        </>
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openSearchSettings = Boolean(anchorEl);

    const handleClickSearchSettings = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSearchSettings = () => {
        setAnchorEl(null);
    };




    const rows = tableData;
    const navigate = useNavigate();


    const handleNavigation = (params) => {

        /* console.log(params);*/

        navigate(`/report/${String(params.row.auditId)}/${params.row.auditName}`);

    };

    let apiFamilyData = useRef(null);



    /////
    const [rowSelectedMemberData, setRowSelectedMemberData] = useState()

    function handleUpdateRowSelectedMemberData(updatedData) {
        let tempData = [];
        console.log("updatedRowSelectedMemberData: ", updatedData)
        tempData = [...tempData, updatedData]
        console.log("setting this tempData: ", tempData)
        setRowSelectedMemberData(tempData);
    }

    function handleNavigateToFamilyDetails(navFamId, navEmpId) {

        console.log("leaving, REAL RowSelectedMemberData: ", rowSelectedMemberData)

        navigate(`/report/${String(id)}/${auditName}/search/${String(navFamId)}/${String(navEmpId)}`);

    };

    const handleUpdateMemberRowDataAndNavigate = (params) => {

       
        if (mainSearchFilterModelLogicalOperator !== null) {
            

            console.log("BACKING UP PERSIST PREV SEARCH LOGICAL OPERATOR:", mainSearchFilterModelLogicalOperator);
        }
        let navigateAudtFamilyId = params.row.audit_family_id;
        let navigateEmployeeId = params.row.employeeID;

        let newRowSelectedMemberData = {
            "rowEmployeeID": params.row.employeeID,
            "rowAuditFamilyID": params.row.audit_family_id,
            "rowAuditEndDate": params.row.audit_end_date,
            "rowAddress": params.row.address,
            "rowClient": params.row.client,
            "rowEmail": params.row.email,
            "rowEmployeeFirstName": params.row.first_name,
            "rowEmployeeLastName": params.row.last_name,
            "rowEmployeeDateOfBirth": params.row.date_of_birth,
        }
        setRowSelectedMemberData([newRowSelectedMemberData]);
        handleUpdateRowSelectedMemberData(newRowSelectedMemberData);



        /* console.log(params);*/
        console.log("CLICK-ROW", params.row);
        console.log("leaving, newRowSelectedMemberData: ", newRowSelectedMemberData)


        handleNavigateToFamilyDetails(navigateAudtFamilyId, navigateEmployeeId)
    }

    return (
        <>
            {/* DESKTOP TABLE - FILTER*/}
            <Box sx={{ width: '98VW', bgcolor: '#FAFAFA', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                {/* SKELETON LOADER - DESKTOP TABLE FILTER */}
               
                <Box sx={{ width: '98VW', bgcolor: '#FAFAFA', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                    {quickFilterButtons !== null ? (<>

                        {quickFilterButtonsLocal}

                    </>) : (<>
                    </>)}
                    <Box sx={{ width: '98VW', height: '2VH', bgcolor: '#FAFAFA', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                    </Box>

                   
                    {detailTableInputs !== null ? (<>

                        {detailTableInputs}

                    </>) : (<>
                    </>)}

                    <Button
                        variant="contained"
                        label="GENERATE SEARCH"
                        sx={{ mr: '1VW', height: '6VH', bgcolor: '#3B82F6' }}
                        onClick={() => handleGenerateNewMainSearchFilterModel()}
                    >
                        GENERATE SEARCH
                    </Button>
                    <>
                        <Button
                            sx={{
                                maxWidth: '1VW', height: '6VH', bgcolor: '#FFFFFF', color: '#991B1B', border: '1px solid #991B1B',
                                '&:hover': {
                                    cursor: 'pointer',
                                    bgcolor: '#FECACA'
                                }
                            }}
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() => handleClearSearchAndBackUpPreviousFilterModel()}
                        >
                        X

                           {/* <CgOptions />*/}
                        </Button>
                      
                    </>
                </Box>
                <Box sx={{ width: '98VW', height: '2VH', bgcolor: '#FAFAFA', mt: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>

                </Box>

                <Box sx={{ width: '98VW', bgcolor: '#FAFAFA', mt: 0, mb: 0, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>





                </Box>
              
                  

                        <Box sx={{ height: desiredHeight, width: desiredWidth , ml: 3 }}>
                            <DataGridPro
                               
                                getRowId={getRowID}
                                columnVisibilityModel={{
                                    // Hide columns, the other columns will remain visible
                                    other: false,
                                    alertRowId: false,
                                    altRowId: false,
                                }}
                                onFilterModelChange={(model) => console.log("NEW Filter MODEL - GEN: ", model)}
                                columns={dataGridColumns}
                                rows={tableData}
                                sx={{
                                    '& .MuiDataGrid-columnContainer': {
                                        bgcolor: '#F1F5F9',
                                        fontWeight: 800,
                                    },
                                }}

                                apiRef={apiRef}
                                slots={{
                                    noResultsOverlay: CustomNoResultsOverlay,
                                    detailPanelExpandIcon: IoMdArrowDropright,
                                    detailPanelCollapseIcon: IoMdArrowDropdown,
                                }}
                               
                               

                            />
                        </Box>
                   







            </Box>

        </>
    );
}

export default GraphDetailsTable;

