
import React, { useState, useEffect, useContext } from 'react';
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { RoutesContext, PermissionsContext, GraphDetailsContext, ProjectDashboardContext, RenderedProjectDashboardContext } from "../App";



function useFetchMilestoneTypes() {
    const { instance, accounts } = useMsal();

    //const [id, setId] = useState();

    

    const [throwFetchMilestoneTypesError, setThrowFetchMilestoneTypesError] = useState(false);
    const [fetchMilestoneTypesStatus, setFetchMilestoneTypesStatus] = useState(null);
    const [promiseIsLoadingMilestoneTypes, setPromiseIsLoadingMilestoneTypes] = useState(true);


    /* context for storing promise data */

    const {
        projectId, setProjectId,

        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates, callFilter, setCallFilter,

        availableClientProjectTimelineData, setAvailableClientProjectTimelineData, handleUpdateAvailableClientProjectTimelineData, handleUpdateTimelineMilestonesByBatchEndDate,
        reloadClientProjectTimelineData, setReloadClientProjectTimelineData, handleUpdateReloadClientProjectTimelineData,
        handleGetLatestInactiveAndActiveSetupData,
        unfilteredDashboardTimelineData, setUnfilteredDashboardTimelineData,
        setTimelineDashboardReload,
        milestoneTypes, setMilestoneTypes
    } = useContext(ProjectDashboardContext);

    const {


        renderedDashboardTimelineData, setRenderedDashboardTimelineData

    } = useContext(RenderedProjectDashboardContext);

    //function handleGetTimelinesForAudit(id) {

    //    setReloadClientProjectTimelineData(true)

    //    instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
    //        .then(result => {
    //            const fetchAuditTimelines = async () => {
    //                let retry = 5;
    //                let response = null;
    //                let counter = 1;
    //                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
    //                while (retry > 0) {
    //                    if (retry < 5) {
    //                        let d = 2000 * counter;
    //                        await timeout(d);
    //                    }
    //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Timeline/GetTimelines', {
    //                        method: 'POST',
    //                        mode: 'cors',
    //                        headers: {
    //                            'Content-Type': 'application/json',
    //                            'Authorization': 'Bearer ' + result.id,
    //                        },
    //                        body: JSON.stringify({ "auditId": parseInt(id) })
    //                    }).then(res => {
    //                        if (res.ok) {
    //                            retry = 0
    //                            response = res.json();
    //                            //handleUpdateAvailableClientProjectTimelineData(response)
    //                            //setAvailableClientProjectTimelineData(response);
    //                            //handleGetLatestInactiveAndActiveSetupData(response);
    //                            return;
    //                        }
    //                        if (retry > 1) {
    //                            retry--;
    //                            counter++;
    //                            return;
    //                        }
    //                        if (retry <= 1) {
    //                            retry = 0;
    //                            //failedFetchError(res.status); //throw error 
    //                            setThrowFetchError(!throwFetchError);
    //                            setFetchStatus(res.status);
    //                            return;
    //                        }


    //                    })
    //                        .catch((error) => { console.log(error) })
    //                }
    //            }
    //            fetchAuditTimelines(id);
    //        }).catch((error) => {
    //            console.log(error) //token expired/is not valid => user is redirected to login page
    //            if (error instanceof InteractionRequiredAuthError) {
    //                instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
    //            }

    //        });

    //    //setUnfilteredDashboardTimelineData(result[0]?.value)

    //    setReloadClientProjectTimelineData(false)

    //}

    function handleFetchAllTimelineTypes() {

        setPromiseIsLoadingMilestoneTypes(true);

        setReloadClientProjectTimelineData(true)














      

        const fetchAuditTimelineTypes = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter;
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Timeline/GetMilestoneTypes', {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },

                }).then(res => {
                    if (res.ok) {
                        retry = 0
                        response = res.json();
                        return;
                    }
                    if (retry > 1) {
                        retry--;
                        counter++;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchMilestoneTypesError(!throwFetchMilestoneTypesError);
                        setFetchMilestoneTypesStatus(res.status);
                        return;
                    }


                })
                    .catch((error) => { console.log(error) })
            }


            return response;
        };



        const fetchTimelinesInPromise = async () => {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {

                            await Promise.allSettled([



                              
                                fetchAuditTimelineTypes(result.idToken)


                            ])
                                .then((result) => {
                                    console.log("PROJECT TIMELINE FETCH RESULT inside hook - ", result);

                            

                                    /* SET MILESTONE TYPES  */

                                    setMilestoneTypes(result[0]?.value)

                                    //setUnfilteredMemberReport(result[5]?.value?.memberStatusReports);

                                    setPromiseIsLoadingMilestoneTypes(false); //controls audit date menu and export from displaying
                                    setReloadClientProjectTimelineData(false)
                                    setTimelineDashboardReload(false)
                                })
                                .catch((error) => {
                                    console.log('promiseTimelines error -', error); //promise failed
                                    //abort fetch request here
                                });


                        }

                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }
        }


        fetchTimelinesInPromise();



    }

    return { handleFetchAllTimelineTypes, throwFetchMilestoneTypesError, fetchMilestoneTypesStatus, promiseIsLoadingMilestoneTypes };
}
export default useFetchMilestoneTypes;