
import React, { useContext, useState, useEffect } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types'; import TextField from "@mui/material/TextField";

import CloseIcon from '@mui/icons-material/Close';

import { UserManagementContext } from '../../pages/Counter2';

import { PatternFormat } from 'react-number-format';

import { useForm, Controller } from "react-hook-form";




import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';


import { GraphDetailsContext, ProjectDashboardContext } from '../../App';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { NumericFormat } from 'react-number-format';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';

import CircularProgress, {
    CircularProgressProps,
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';


function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', ml: '2VW', mt: '-0.5VH', mr: '3VW' }}>
            <CircularProgress
                variant="determinate"
                sx={(theme) => ({
                    color: theme.palette.grey[200],
                    ...theme.applyStyles('dark', {
                        color: theme.palette.grey[800],
                    }),
                })}
                size={"4.55rem"}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress size="4.55rem" thickness={4} disableShrink sx={{}} variant="determinate" {...props} sx={{ position: 'absolute', }}
                sx={(theme) => ({
                    color: '#1a90ff',
                    animationDuration: '0ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                    ...theme.applyStyles('dark', {
                        color: '#308fe8',
                    }),
                })}
            />
            <Box
                sx={{
                    top: "-3VH",
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.primary', ml: 'VW', fontWeight: "600", fontSize: "0.95rem", position: "absolute" }}
                >{`${Math.round(props.value)}%`}</Typography>
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.secondary', ml: '-0.05VW', mt: "3VH", textAlign: "center", fontWeight: "600", fontSize: "0.7rem", position: "absolute" }}
                >{`Response`}</Typography>
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.secondary', ml: '-0.05VW', mt: "5VH", textAlign: "center", fontWeight: "600", fontSize: "0.7rem", position: "absolute" }}
                >{`Rate`}</Typography>
            </Box>
        </Box>
    );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    border: "solid 1px #1a90ff",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#EFF6FF",
        ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,






        backgroundColor: '#1a90ff',
        ...theme.applyStyles('dark', {
            backgroundColor: '#308fe8',
        }),
    },
}));


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const materialUITextFieldProps = {
    sx: [{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }],
    variant: "outlined"
};

function MobileTimelineCard({ cardTitle, cardSubtitle, desiredCardWidth, desiredCardHeight, desiredHeightPercentage, cardType, cardChipLabel, cardChipStatus, cardExtraSubtitle, cardMilestoneData, cardBatchEndDate, cardFamilyStartId, cardFamilyEndId, cardTotalCount, cardTimelineId, cardGraphic, cardResponseRate, cardExtraSubtitle2, cardCurrentDay, cardTotalDays }) {

    const {

        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates,
        handleUpdateAvailableClientProjectTimelineData, handleUpdateTimelineMilestonesByBatchEndDate,
        reloadClientProjectTimelineData, setReloadClientProjectTimelineData, handleUpdateReloadClientProjectTimelineData
    } = useContext(ProjectDashboardContext);


    const { id, auditName, auditFamilyId, employeeId } = useParams();


    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData
    } = useContext(GraphDetailsContext);

    const navigate = useNavigate();

    const [projectTimelineData, setProjectTimelineData] = useState([]);

    function handleUpdateProjectTimelineData(update) {
        if (update !== null) {
            setProjectTimelineData(update)
        }
    }

    const [milestone1Active, setMilestone1Active] = useState(true);
    const [milestone2Active, setMilestone2Active] = useState(true);
    const [milestone3Active, setMilestone3Active] = useState(true);
    const [milestone4Active, setMilestone4Active] = useState(true);
    const [milestone5Active, setMilestone5Active] = useState(true);
    const [milestone6Active, setMilestone6Active] = useState(true);

    function handleUpdateMilestone1Active(update) {
        setMilestone1Active(update)
    }

    function handleUpdateMilestone2Active(update) {
        setMilestone2Active(update)
    }

    function handleUpdateMilestone3Active(update) {
        setMilestone3Active(update)
    }

    function handleUpdateMilestone4Active(update) {
        setMilestone4Active(update)
    }

    function handleUpdateMilestone5Active(update) {
        setMilestone5Active(update)
    }

    function handleUpdateMilestone6Active(update) {
        setMilestone6Active(update)
    }

    const [milestone1Title, setMilestone1Title] = useState("Initial Notice");
    const [milestone2Title, setMilestone2Title] = useState("Reminder");
    const [milestone3Title, setMilestone3Title] = useState("Deadline");
    const [milestone4Title, setMilestone4Title] = useState("Final Notice + Appeals");
    const [milestone5Title, setMilestone5Title] = useState("Final Deadline");
    const [milestone6Title, setMilestone6Title] = useState("Report");

    function handleMilestone1TitleChange(event) {
        setMilestone1Title(event.target.value)
    }
    function handleMilestone2TitleChange(event) {
        setMilestone2Title(event.target.value)
    }
    function handleMilestone3TitleChange(event) {
        setMilestone3Title(event.target.value)
    }
    function handleMilestone4TitleChange(event) {
        setMilestone4Title(event.target.value)
    }
    function handleMilestone5TitleChange(event) {
        setMilestone5Title(event.target.value)
    }
    function handleMilestone6TitleChange(event) {
        setMilestone6Title(event.target.value)
    }

    const [milestone1Goal, setMilestone1Goal] = useState(10);
    const [milestone2Goal, setMilestone2Goal] = useState(20);
    const [milestone3Goal, setMilestone3Goal] = useState(50);
    const [milestone4Goal, setMilestone4Goal] = useState(70);
    const [milestone5Goal, setMilestone5Goal] = useState(90);
    const [milestone6Goal, setMilestone6Goal] = useState(100);

    function handleMilestone1GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone1Goal(event.target.value)
        }

    }

    function handleMilestone2GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone2Goal(event.target.value)
        }

    }

    function handleMilestone3GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone3Goal(event.target.value)
        }

    }

    function handleMilestone4GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone4Goal(event.target.value)
        }

    }

    function handleMilestone5GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone5Goal(event.target.value)
        }

    }

    function handleMilestone6GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone6Goal(event.target.value)
        }

    }

    const [milestone1EndDateValue, setMilestone1EndDateValue] = useState(null);
    const [milestone2EndDateValue, setMilestone2EndDateValue] = useState(null);
    const [milestone3EndDateValue, setMilestone3EndDateValue] = useState(null);
    const [milestone4EndDateValue, setMilestone4EndDateValue] = useState(null);
    const [milestone5EndDateValue, setMilestone5EndDateValue] = useState(null);
    const [milestone6EndDateValue, setMilestone6EndDateValue] = useState(null);


    function handleMilestone1EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone1EndDateValue(value)
        }

    }

    function handleMilestone2EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone2EndDateValue(value)
        }

    }

    function handleMilestone3EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone3EndDateValue(value)
        }

    }

    function handleMilestone4EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone4EndDateValue(value)
        }

    }

    function handleMilestone5EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone5EndDateValue(value)
        }

    }

    function handleMilestone6EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone6EndDateValue(value)
        }

    }


    function getEditMilestoneFunctionFromPosition(milestonePosition) {
        if (milestonePosition === 0) {
            return handleMilestone1TitleChange;
        }
    }



    function handleGenerateNewTimeline() {
        console.log("VALUES: milestone1: Active:", milestone1Active, "milestone1Title: ", milestone1Title, "milestone1EndDate: ", milestone1EndDateValue, "milestone1Goal: ", milestone1Goal)
        console.log("VALUES: milestone2: Active:", milestone2Active, "milestone1Title: ", milestone2Title, "milestone1EndDate: ", milestone2EndDateValue, "milestone1Goal: ", milestone2Goal)

        console.log("VALUES: milestone3: Active:", milestone3Active, "milestone1Title: ", milestone3Title, "milestone1EndDate: ", milestone3EndDateValue, "milestone1Goal: ", milestone3Goal)

        console.log("VALUES: milestone4: Active:", milestone4Active, "milestone1Title: ", milestone4Title, "milestone1EndDate: ", milestone4EndDateValue, "milestone1Goal: ", milestone4Goal)

        console.log("VALUES: milestone5: Active:", milestone5Active, "milestone1Title: ", milestone5Title, "milestone1EndDate: ", milestone5EndDateValue, "milestone1Goal: ", milestone5Goal)

        console.log("VALUES: milestone6: Active:", milestone6Active, "milestone1Title: ", milestone6Title, "milestone1EndDate: ", milestone6EndDateValue, "milestone1Goal: ", milestone6Goal)

        //NEED a cool - format only the "Active" ones for properly structuring "Milestones" in Chris response, but for now, lets just get it working with all of them;
        let newMilestoneData = [];

        if (milestone1Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "activeMilestone": false,
                "completed": false,
                "milestoneLabel": milestone1Title,
                "milestoneEndDate": milestone1EndDateValue,
                "milestoneTotalDays": 0,
                "milestoneGoal": milestone1Goal,
                "milestoneToggleActive": milestone1Active,


            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone2Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "activeMilestone": false,
                "completed": false,
                "milestoneLabel": milestone2Title,
                "milestoneEndDate": milestone2EndDateValue,
                "milestoneTotalDays": 0,
                "milestoneGoal": milestone2Goal,
                "milestoneToggleActive": milestone2Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone3Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "activeMilestone": false,
                "completed": false,
                "milestoneLabel": milestone3Title,
                "milestoneEndDate": milestone3EndDateValue,
                "milestoneTotalDays": 0,
                "milestoneGoal": milestone3Goal,
                "milestoneToggleActive": milestone3Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone4Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "activeMilestone": false,
                "completed": false,
                "milestoneLabel": milestone4Title,
                "milestoneEndDate": milestone4EndDateValue,
                "milestoneTotalDays": 0,
                "milestoneGoal": milestone4Goal,
                "milestoneToggleActive": milestone4Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone5Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "activeMilestone": false,
                "completed": false,
                "milestoneLabel": milestone5Title,
                "milestoneEndDate": milestone5EndDateValue,
                "milestoneTotalDays": 0,
                "milestoneGoal": milestone5Goal,
                "milestoneToggleActive": milestone5Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone6Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "activeMilestone": false,
                "completed": false,
                "milestoneLabel": milestone6Title,
                "milestoneEndDate": milestone6EndDateValue,
                "milestoneTotalDays": 0,
                "milestoneGoal": milestone6Goal,
                "milestoneToggleActive": milestone6Active,

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        console.log("NEW MILESTONE DATA: ", newMilestoneData);

        setProjectTimelineData([
            {
                "auditId": id,
                "batchEndDate": cardBatchEndDate !== null ? cardBatchEndDate : "(Data Not Yet Available)",
                "auditTimelineId": cardTimelineId !== null ? cardTimelineId : "(Data Not Yet Available)",
                "familyStartId": cardFamilyStartId !== null ? cardFamilyStartId : "(Data Not Yet Available)",
                "familyEndId": cardFamilyEndId !== null ? cardFamilyEndId : "(Data Not Yet Available)",
                "totalCount": cardTotalCount !== null ? cardTotalCount : "(Data Not Yet Available)",
                "active": true,
                "totalResponseRate": 0,
                "newTimeline": true,
                "timelineTotalDays": 0,
                "timelineCurrentDay": 0,
                "milestones": newMilestoneData,

            }
        ])


        //update state through context
        handleUpdateTimelineMilestonesByBatchEndDate(cardBatchEndDate, newMilestoneData)



        //close modal
        setOpenModal(false);



        //setMainSearchFilterModel([])
        //apiRef.current.setFilterModel({
        //    items: []

        //});



        //handleGetAndSetAllFiltersForUpdate()
    }






    useEffect(() => {
        setMilestone1Active(true)
        setMilestone2Active(true)
        setMilestone3Active(true)
        setMilestone4Active(true)
        setMilestone5Active(true)
        setMilestone6Active(true)

    }, [])

    useEffect(() => {
        console.log("New Timeline updated data: ", projectTimelineData)
        handleUpdateReloadClientProjectTimelineData(true);
    }, [projectTimelineData])




    /*let stringedStateObj = JSON.stringify(stateObj)*/

    let cumulativeMilestoneDayCount = 0;

    function getCumulativeMilestoneDayCount(milestonePosition) {
        if (milestonePosition === 0) {

        }
    }



    return (
        <>

            <Card style={{ height: desiredHeightPercentage != null ? (desiredHeightPercentage) : ('90%') }} sx={{
                mr: 1.5,
                mb: 1.5,
                minWidth: desiredCardWidth ? (desiredCardWidth) : (75),
                minHeight: desiredCardHeight ? (desiredCardHeight) : (400),
                height: desiredCardHeight ? (desiredCardHeight) : (400),
                width: desiredCardWidth ? (desiredCardWidth) : (400)
            }}>


                {cardType == 'generic' ? (<>
                    {cardGraphic !== null ? (<>
                        <Typography sx={{ mt: 1 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            <div style={{ textAlign: 'center', fontSize: '300%', color: '#233cb0' }}>{cardGraphic}</div>

                        </Typography>

                    </>) : (<></>)}
                    <Typography sx={{ mt: 0 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                        {cardTitle}

                    </Typography>

                    <Typography sx={{ mt: 1 }} textAlign="center" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                        {cardSubtitle}

                    </Typography>

                    {/* <Button onClick={handleOpenModal} sx={{ ml: "45.5%", mt: 1.5, bgcolor: '#233cb0', fontWeight: '700', width: '2VW' }} variant="contained">Start</Button>*/}

                </>) : (<></>)}

                {cardType == 'audit' ? (<>
                    <div className="row-no-margin">
                        <Typography sx={{ mt: 2, ml: 1 }} textAlign="left" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            {cardTitle}

                        </Typography>

                       
                        {/*<Typography sx={{ mt: 2, ml: 2, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">*/}

                        {/*    {cardSubtitle}*/}

                        {/*</Typography>*/}

                       
                        <div className="row-right">
                            {/*                            <Chip variant={cardChipStatus === 'unset' ? "outlined" : ""} color={cardChipStatus === 'unset' ? "warning" : "success"} label={cardChipLabel} sx={{ color: cardChipStatus === 'unset' ? "#EA580C" : "", bgcolor: cardChipStatus === 'unset' ? "" : "#2DD4BF", mr: 1, mt: '2.5VH', fontWeight: '700' }} />*/}
                            <div className="row-no-left-margin">
                                <div className="row-right">
                                    {/*<Button onClick={handleOpenModal} sx={{ mt: cardChipStatus === 'unset' ? 0 : 0, mr: 1.5, bgcolor: '#233cb0', fontWeight: '700', width: '2VW' }} variant="contained">{cardChipStatus === 'unset' ? 'Start' : 'Edit'}</Button>*/}
                                    <CircularProgressWithLabel value={cardResponseRate} />
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="row-no-margin">

                        <Typography sx={{ mt: -4.5, ml: 1, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                            {cardSubtitle}

                        </Typography>

                       

                       
                    </div>

                    <div className="row-no-margin">

                      

                        {cardExtraSubtitle !== null ? (<>
                            <Typography sx={{ mt: -2.5, ml: 1, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                {cardExtraSubtitle}

                            </Typography>
                        </>) : (<></>)}


                    </div>


                    {cardExtraSubtitle2 !== null ? (<>
                        <Typography sx={{ mt: 3 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            {cardExtraSubtitle2}

                        </Typography>
                    </>) : (<></>)}

                    {cardResponseRate !== null ? (<>
                        <div className="row-no-margin">
                            {/* NEED SPACE FOR RESPONSE RATE GRAPH AND TIMELINE CONTAINER  */}
                          
                            <Box sx={{ width: "2.95VW" }}></Box>
                            {cardMilestoneData !== null ? (<>
                                {cardMilestoneData?.map((item) => {
                                    if (item.milestoneToggleActive === true) {
                                        cumulativeMilestoneDayCount += item.milestoneTotalDays;
                                        console.log("cumuDayCount:", cumulativeMilestoneDayCount);
                                        return (<>
                                           
                                            <Box sx={{ mt: "-0.5VH", ml: 0.00, mr: 0, width: item.milestoneTotalDays !== 1 ? `${(2.75 * (7 / 7)) + 12.25}VW` : "7VW" && item.completedMilestone === true ? "7VW" : "7VW", }}>
                                                {item.completedMilestone === true ? (<>
                                                    <Box sx={{ position: "relative", mb: "1.25VH" }}>
                                                        <Typography sx={{ mt: '-0.75VH', ml: item.milestonePosition === 0 ? "-1VW" : "-1.05VW", mr: "0%", color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                                            {dayjs(item.milestoneEndDate).format("MM/DD")}

                                                        </Typography>
                                                    </Box>
                                                </>) : (<>
                                                    <Box sx={{ position: "relative", mb: "1.25VH" }}>
                                                        <Typography sx={{ mt: '-0.75VH', ml: item.milestonePosition === 0 ? "-1VW" : "-1.05VW", mr: "0%", color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                                            {dayjs(item.milestoneEndDate).format("MM/DD")}

                                                        </Typography>
                                                    </Box>
                                                </>)}

                                                {item.completedMilestone === true ? (<>
                                                    <Box sx={{ mt: "-2VH", ml: 0, width: item.milestoneTotalDays !== 1 ? `${(2.75 * (7 / 7)) + 13.05}VW` : "8VW", height: "1VH" }}>
                                                        <Typography sx={{
                                                            zIndex: 10, mt:"2VH",
                                                            mb: item.milestoneLabel === "Initial Notice" ? `-4VW` : '0VH' &&
                                                                item.milestoneLabel === "Reminder" ? "24VW" : '0VH' &&
                                                                    item.milestoneLabel === "Final Deadline" ? "-0.0VW" : '0VH' &&
                                                                        item.milestoneLabel === "Deadline" ? "-4VW" : '0VH' &&
                                                                            item.milestoneLabel === "Review" ? "-4VW" : '0VH',  ml: item.milestoneLabel === "Initial Notice" ? `0VW` : "0.00VW" && item.milestoneLabel === "Reminder" ? "0VW" : "0VW" && item.milestoneLabel === "Final Deadline" ? "-0.0VW" : "0VW" && item.milestoneLabel === "Deadline" ? "0VW" : "0VW", mr: "0%", fontWeight: item.activeMilestone === true ? "600" : '', color: item.activeMilestone === true ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.7em" component="div">

                                                            {item.milestoneLabel}

                                                        </Typography>
                                                    </Box>
                                                </>) : (<>
                                                    <Box sx={{ mt: "-2VH", ml: 0, width: item.milestoneTotalDays !== 1 ? `${(2.75 * (item.milestoneTotalDays / 7)) + 13.05}VW` : "8VW", height: "1VH" }}>
                                                        <Typography sx={{ zIndex: 10, mt: '2VH', mb: 0, ml: item.milestoneLabel === "Initial Notice" ? `0VW` : "0.00VW" && item.milestoneLabel === "Reminder" ? "0VW" : "0VW" && item.milestoneLabel === "Final Deadline" ? "-0.0VW" : "0VW" && item.milestoneLabel === "Deadline" ? "0VW" : "0VW", mr: "0%", fontWeight: item.activeMilestone === true ? "600" : '', color: item.activeMilestone === true ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.7em" component="div">

                                                            {item.milestoneLabel}

                                                        </Typography>
                                                    </Box>
                                                </>)}


                                                {item.completedMilestone === true ? (<>
                                                    <Box sx={{ position: "relative", ml: "0VW", mt: "3VH" }}>
                                                        <BorderLinearProgress variant="determinate" value={100} sx={{ width: item.milestoneTotalDays !== 1 ? `${7 * (8 / 7)}VW` : "0.5VW" }} />
                                                    </Box>
                                                </>) : (<>
                                                    {item.completedMilestone === false && item.activeMilestone === true ? (<>
                                                        <Box sx={{ position: "relative", ml: "0VW", mt: "3VH" }}>
                                                            <BorderLinearProgress variant="determinate" value={(item.milestoneDaysComplete / item.milestoneTotalDays) * 100} sx={{ width: item.milestoneTotalDays !== 1 ? `${7 * (item.milestoneTotalDays / 7)}VW` : "0.5VW" }} />
                                                        </Box>
                                                    </>) : (<>
                                                        <Box sx={{ position: "relative", ml: "0VW", mt: "3VH" }}>
                                                            <BorderLinearProgress variant="determinate" value={0} sx={{ width: item.milestoneTotalDays !== 1 ? `${7 * (item.milestoneTotalDays / 7)}VW` : "0.5VW" }} />
                                                        </Box>
                                                    </>)}
                                                </>)}





                                                {item.milestonePosition !== 5 ? (<>
                                                    <Box sx={{ position: "relative", mt: "0.5VH" }}>
                                                        <Typography sx={{ mt: '-.05VH', ml: "0%", mr: "-6.7VW", color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="right" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                                                            {`${item.milestoneGoal}%`}

                                                        </Typography>
                                                    </Box>
                                                    <Divider orientation="vertical" variant="middle" position="absolute" sx={{ ml: "-0.45VW", mr: '0.25VW', mt: "-7.95VH", height: (item.completedMilestone === false && item.activeMilestone === true) ? "5.85VH" : "5.85VH" }} />
                                                </>) : (<>

                                                </>)}

                                            </Box>
                                                </>)
                                                                                
                                    } else {
                                        return (<></>);
                                    }

                                })}
                            </>) : (<></>)}









                        </div>
                    </>) : (<></>)}




                </>) : (<></>)}



                {/*</div>*/}
                <Container sx={{ ml: '-4.5VW', mb: '-0.5VH', mt: '-4VH' }}>
                    <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        <Grid item xs={4} >



                        </Grid>

                        <Grid item xs={8} >



                        </Grid>
                    </Grid>
                </Container>













            </Card>



        </>
    );
}

export default MobileTimelineCard;

