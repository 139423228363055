
import React, { useState, useEffect, useContext } from 'react';
import { ProjectDashboardContext } from "../App";

function useFilterSetupTimelines() { //filter timeline data by milestone length into setup categories
    const [inactiveTimelineData, setInactiveTimelineData] = useState([]); //use inactiveTimelineData as temp arr
    const [activeTimelineData, setActiveTimelineData] = useState([]); //use activeTimelineData as temp arr
    //const [counter, setCounter] = useState(0);

    const {
        inactiveClientTimelineSetupData, setInactiveClientTimelineSetupData,
        activeClientTimelineSetupData, setActiveClientTimelineSetupData,
      
    } = useContext(ProjectDashboardContext);

    function handleFilteringSetupTimelines(timelineData) {
      
        console.log("filter hook setup timelines - timelineData --- ", timelineData);
        let copyA = timelineData;
        let copyB = timelineData;
    

        setInactiveTimelineData(copyA?.filter((record) => { 
             if (record?.milestones.length === 0) {
                 return record;
             } //select all records with no milestones as inactive

        }));

        setActiveTimelineData(copyB?.filter((record) => {
            if (record?.milestones.length !== 0) {
                return record;
            } //select all records with any milestones as active

        }));

            //setFilteredTimelineData(timelineData?.filter((record) => //filter each record by selected date(s)
           


         
            //{
            //    if (Date.parse(record?.batchEndDate?.valueOf()) === Date.parse(selectedAuditDates[0]?.auditEndDate.valueOf())) {
            //        return record;
            //    } //select all records with given date

            //}));

       
    }
    setInactiveClientTimelineSetupData(inactiveTimelineData)
    setActiveClientTimelineSetupData(activeTimelineData)
    console.log("filter hook setup timelines - inactiveTimelineData --- ", inactiveTimelineData);
    console.log("filter hook setup timelines - activeTimelineData --- ", activeTimelineData);

    return { inactiveTimelineData, activeTimelineData,  handleFilteringSetupTimelines };
}
export default useFilterSetupTimelines;