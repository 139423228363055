
import React, { useState, useEffect, useContext } from 'react';
function useFilterTimeline() { //filter timeline data by selected Audit End Dates
    const [filteredTimelineData, setFilteredTimelineData] = useState([]); //use filteredTimelineData as temp arr
    //const [counter, setCounter] = useState(0);

    function handleFilteringTimeline(timelineData, selectedAuditDates) {
        if (selectedAuditDates) {
            console.log("filter hook date - timelineData --- ", timelineData);
            console.log("filter hook date - selectedAuditDates --- ", selectedAuditDates);

            setFilteredTimelineData(timelineData?.filter((record) => //filter each record by selected date(s)
            //selectedAuditDates?.map((date) => {
            //    record?.auditEndDate?.includes(date)
            //})
            //setCounter(...counter + 1);
            //counter = counter + 1;


            //record?.auditEndDate?.toLowerCase().includes(selectedAuditDates[0]?.auditEndDate) //select all records with given date
            {
                if (Date.parse(record?.batchEndDate?.valueOf()) === Date.parse(selectedAuditDates[0]?.auditEndDate.valueOf())) {
                    return record;
                } //select all records with given date

            }));

        }
    }


    return { filteredTimelineData, handleFilteringTimeline };
}
export default useFilterTimeline;