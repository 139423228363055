


import React, { useState, useEffect, useContext, createContext, useRef } from 'react'
import { useTable } from 'react-table'
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";
import { useForm } from 'react-hook-form'
/*import { Artboard, Card, Collapse, Stats, Table, Progress, RadialProgress, Button, Input, Select, Modal, Dropdown } from 'react-daisyui';*/
import { BsCheckLg, BsXLg, BsExclamationCircleFill, BsThreeDots } from 'react-icons/bs';
import { SidebarContext } from "../App";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import UserManagementTable from "../components/tables/UserManagementTable";
import UserManagementTableMobile from "../components/tables/UserManagementTableMobile";
import USER_MGMT_MOCK_DATA from "../data/USER_MGMT_MOCK_DATA.json"
import UserManagementModalCreateUser from "../components/modals/UserManagementModalCreateUser";
import UserManagementModalUpdateUser from "../components/modals/UserManagementModalUpdateUser";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext, RolesContext, ProjectDashboardContext } from "../App";
import useFetch from "../hooks/useFetch";
import Tooltip from "@mui/material/Tooltip";
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Button from '@mui/material/Button';
import { IoMdArrowRoundBack } from "react-icons/io";

import { TbWorldSearch } from "react-icons/tb";
import { FaSearch } from "react-icons/fa";

import { EmployeeSearchContext } from '../pages/EmployeeSearch';
import FamilyDetailsEmployeeCard from '../components/cards/FamilyDetailsEmployeeCard';
import Divider from '@mui/material/Divider';


import FamilyDetailsMembersTable from '../components/tables/FamilyDetailsMembersTable';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';

import FamilyDetailsFilesUploadedTable from '../components/tables/FamilyDetailsFilesUploadedTable';

import { HiWrenchScrewdriver } from "react-icons/hi2";
import CreateTimelineCard from '../components/cards/CreateTimelineCard';

import EditTimelineCard from '../components/cards/EditTimelineCard';
import dayjs from 'dayjs';
import { TbTimelineEventX } from "react-icons/tb";

import { PiCheckCircleDuotone } from "react-icons/pi";

import TimelineCard from '../components/cards/TimelineCard';
import CreateTimelineCardStartNotice from '../components/cards/CreateTimelineCardStartNotice';
import EditTimelineCardStartNotice from '../components/cards/EditTimelineCardStartNotice';

import useFilterSetupTimelines from "../hooks/useFilterSetupTimelines";

import useFetchTimelines from '../hooks/useFetchTimelines';

import useFetchMilestoneTypes from '../hooks/useFetchMilestoneTypes';






function ProjectSetup({ onChange, children }) {

    /* const { rowSelectedMemberData } = useContext(EmployeeSearchContext)*/

    /* console.log("PASSED IN ROW SELECTED MEMBER DATA: ",  rowSelectedMemberData  )*/

    const {

        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates,
        availableClientProjectTimelineData, setAvailableClientProjectTimelineData, handleUpdateAvailableClientProjectTimelineData,
        reloadClientProjectTimelineData, setReloadClientProjectTimelineData, handleUpdateReloadClientProjectTimelineData,
        haveAPITimelineData, setHaveAPITimelineData,
        handleGetLatestInactiveAndActiveSetupData,
        inactiveClientTimelineSetupData, setInactiveClientTimelineSetupData,
        activeClientTimelineSetupData, setActiveClientTimelineSetupData,
        unfilteredDashboardTimelineData, setUnfilteredDashboardTimelineData,
        timelineSetupReload, setTimelineSetupReload,
        milestoneTypes, setMilestoneTypes
    } = useContext(ProjectDashboardContext);

    const {

        setSidebarTitle,
        sidebarActiveClientID,
        setSidebarActiveClientID,
        setSidebarActions
    } = useContext(SidebarContext);

    const { id, auditName, auditFamilyId, employeeId } = useParams();
    const { instance, accounts, inProgress } = useMsal();

    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);

    //client
    const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');
    const [givenName, setGivenName] = useState('');
    const [surname, setSurname] = useState('');
    const [accountEnabled, setAccountEnabled] = useState(false);
    const [currentEmail, setCurrentEmail] = useState('')
    const [displayName, setDisplayName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [users, setUsers] = useState([]);
    //const users = useRef({});
    const [haveUserData, setHaveUserData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [status, setStatus] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);
    const { newRoutes, setRoutes } = useContext(RoutesContext);
    //const [fetchNewData, setFetchNewData] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);


    const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);


    useEffect(() => {

        if (id !== sidebarActiveClientID) {
            setIsLoading(false);
        }

    }, [id, auditName]);

    useEffect(() => {

        setSidebarTitle(`${auditName}`)
        setSidebarActiveClientID(`${id}`)
    }, [auditName, id])



    //toggle visible modal for adding user when on 'add user' button click
    const toggleVisible = () => {
        setVisible(!visible)
    }

    const toggleVisible1 = () => {
        setVisible1(!visible1)
    }

    const toggleVisible2 = () => {
        setVisible2(!visible2)
    }

    const toggleLoading = () => {
        setLoading(!loading)
    }

    useEffect(() => {

        if (isLoading === false) {
            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)

            setIsLoading(true);

        }

    }, [isLoading]);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: true
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: false,
            style: '',
            isNested: true
        },
        {
            id: 3,
            label: "Processing",
            path: "processing",
            icon: <AssignmentTurnedInIcon />,
            isActive: false,
            style: '',
            isNested: false
        }
    ]);

    const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);
    const [validRoute, setValidRoute] = useState(null);
    const [reports, setReports] = useState([]);

    useEffect(() => {

        //console.log("valid Route --- ", validRoute)
        //if ((roles === "CLIENT") && (validRoute === false)) {

        //    navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
        //    setIsLoading(false);

        //}
        if ((roles === 'CLIENT') && (validRoute === null) && (count === permissions?.length)) {
            setValidRoute(false);
        }
        if ((roles === "SERVICES")) {
            navigate('/processing');
        }

        //if ((roles === "CLIENT")) {
        //    /* <Navigate to={`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`} replace />*/
        //    navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
        //    //setIsLoading(false);
        //}

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length <= 1)) {
            setRoutes([
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: true,
                    style: '',
                    isNested: false
                },
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length > 1)) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: true,
                    style: '',
                    isNested: true
                },
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles == "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 4,
                    label: "Project Setup",
                    path: `report/${String(id)}/${auditName}/setup`,
                    icon: <HiWrenchScrewdriver className="largerIcon" />,
                    isActive: true,
                    style: '',
                    isNested: true
                },
                {
                    id: 5,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 6,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }


    }, [id, auditName, roles]);

    useEffect(() => {

        permissions?.map((audit) => {
            if (audit?.auditId === parseInt(id)) {
                setValidRoute(true)

            }
            setCount(count + 1);
        });
        console.log("count -- ", count)
        console.log("permissions -- ", permissions)
        if ((validRoute === null || validRoute === false) && (count === permissions?.length)) {
            setValidRoute(false)
        }
        console.log("current id --- ", id);
        fetchTimelines.handleFetchAllTimelines(id);
        fetchMilestoneTypes.handleFetchAllTimelineTypes();

    }, []);


    useEffect(() => {

        if ((roles === "SERVICES")) {
            navigate('/processing');
        }
        console.log("permissions - ", permissions);
        console.log("valid Route 2 --- ", validRoute)
        if ((roles === "CLIENT") && (validRoute === false)) {

            navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            //setIsLoading(false);

        }


    }, [validRoute, setValidRoute, roles]);








    const handleNavigateBackToProject = (params) => {

        /* console.log(params);*/


        navigate(`/report/${String(id)}/${auditName}`);


    };



    const handleNavigateBackToSearch = (params) => {

        /* console.log(params);*/


        navigate(`/report/${String(id)}/${auditName}/search`);
        /* navigate(`report/:id/:auditName/search/`);*/

    };

    const fetchTimelines = useFetchTimelines();

    const fetchMilestoneTypes = useFetchMilestoneTypes();

    const filterSetupTimelines = useFilterSetupTimelines();

    //potentially remove - refetch issue


    useEffect(() => {
        handleUpdateReloadClientProjectTimelineData(false);


    }, [])


    const [throwFetchError, setThrowFetchError] = useState(false);
    const [fetchStatus, setFetchStatus] = useState(null);
    const [promiseIsLoading, setPromiseIsLoading] = useState(true);

    const [filterIsLoading, setFilterIsLoading] = useState(true);

    useEffect(() => {
        console.log("fetch graph details promise is loading called outside if ", fetchTimelines?.promiseIsLoadingTimelines);
        if (fetchTimelines.promiseIsLoadingTimelines !== null && fetchTimelines.promiseIsLoadingTimelines !== undefined && fetchTimelines.promiseIsLoadingTimelines !== true) {
            console.log("fetch graph details promise is loading called inside if ", fetchTimelines?.promiseIsLoadingTimelines);
            setPromiseIsLoading(false);

        }


    }, [fetchTimelines.promiseIsLoadingTimelines])




    const [availableClientTimelineData, setAvailableClientTimelineData] = useState(availableClientProjectTimelineData !== null ? availableClientProjectTimelineData : [])

    const [inactiveClientTimelineData, setInactiveClientTimelineData] = useState([])

    const [activeClientTimelineData, setActiveClientTimelineData] = useState([])

    const [localReload, setLocalReload] = useState(false)

    useEffect(() => {
        setTimelineSetupReload(true)
        setFilterIsLoading(true);
        if (unfilteredDashboardTimelineData?.length > 0) {

            console.log("availableClientProjectTimelineData -- ", availableClientProjectTimelineData);
            console.log("unfilteredDashboardTimelineData -- ", unfilteredDashboardTimelineData);
            filterSetupTimelines.handleFilteringSetupTimelines(unfilteredDashboardTimelineData);
            //filterFamGraphHook.handleFamilyGraphFiltering(fetchGraphDetails.familyData);
            if (filterSetupTimelines?.inactiveTimelineData?.length > 0) {
                setInactiveClientTimelineData(filterSetupTimelines.inactiveTimelineData)
                setInactiveClientTimelineSetupData(filterSetupTimelines.inactiveTimelineData)
            }

            if (filterSetupTimelines?.activeTimelineData?.length !== 0) {
                setActiveClientTimelineData(filterSetupTimelines.activeTimelineData)
                setActiveClientTimelineSetupData(filterSetupTimelines.activeTimelineData)
            }
        }
        setFilterIsLoading(false);
        setTimelineSetupReload(false)


    }, [fetchTimelines.promiseIsLoadingTimelines, unfilteredDashboardTimelineData, setTimelineSetupReload, setInactiveClientTimelineSetupData, setActiveClientTimelineSetupData]);



    //useEffect(() => {

    //    handleGetLatestStatusTimelineData();
    //    setLocalReload(true);

    //}, [haveAPITimelineData, handleGetLatestStatusTimelineData])


    //let apiData = useRef(null);




    //useEffect(() => {
    //    fetchTimelines.handleFetchAllTimelines(id);
    //}, [])















    return (
        <>

            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', display: { xs: 'none', md: 'block', xl: 'block' } }} >


                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>

                    <div className="row-no-left-margin">
                        <Button variant="text"
                            sx={{ mt: 0, ml: '-0.5VW' }}
                            onClick={() => handleNavigateBackToProject()}
                        > <IoMdArrowRoundBack />Back to Project</Button>
                        <div className="row-left">




                        </div>


                    </div>

                    {/*{haveAPISearchData && haveAPIFamilyData && haveAPIFamilyActivityData*/}
                    {/*    ? (<>*/}
                    {/*        <div className="row-no-left-margin" style={{ width: "82.5VW" }}>*/}
                    {/*            <div className="row-left">*/}


                    {/*                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>Project Setup</Typography>*/}



                    {/*            </div>*/}
                    {/*         </div>*/}
                    {/*    </>) : (<>*/}
                    {/*        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                    {/*            <Skeleton height={400} width={600} />*/}
                    {/*        </SkeletonTheme>*/}
                    {/*    </>)}*/}

                    <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                        <div className="row-left">


                            <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>{`Project Setup`}</Typography>



                        </div>

                    </div>
                </Container >
                {promiseIsLoading === true || filterIsLoading === true || timelineSetupReload === true ? (<>
                    <Box sx={{ ml: 3, mb: 3 }}>
                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                            <Skeleton height={110} width={'75VW'} />
                        </SkeletonTheme>
                    </Box>                 <Box sx={{ ml: 3, mb: 3 }}>
                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                            <Skeleton height={510} width={'75VW'} />
                        </SkeletonTheme>
                    </Box>
                </>) : (<>
                    <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, ml: 3, color: '#737373' }}>Setup Project Timelines</Typography>

                    <Container sx={{
                        mt: 3, mb: 3, ml: 0,
                        display: { xs: 'none', md: 'block' },


                    }}>
                        <div className="row-no-left-margin"  >
                            {/*<Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>No Unset Audit Batch Data Available at this time</Typography>*/}

                            {inactiveClientTimelineSetupData.length !== 0 ? (
                                inactiveClientTimelineSetupData.map((value) => {

                                    return (
                                        <CreateTimelineCard
                                            cardTitle={`Batch Ending: ${dayjs(value.batchEndDate).format('MM/DD')}`}
                                            cardSubtitle={`ID Range: ${value.familyStartId}-${value.familyEndId}`}
                                            cardExtraSubtitle={`Total Count: ${value.totalCount}`}
                                            desiredCardWidth={400}
                                            desiredCardHeight={150}
                                            desiredHeightPercentage={'90%'}
                                            cardType={'audit'}
                                            cardChipLabel={'Unset'}
                                            cardChipStatus={'unset'}
                                            cardBatchEndDate={value.batchEndDate}
                                            cardMilestoneData={value.milestones}
                                            cardFamilyStartId={value.familyStartId}
                                            cardFamilyEndId={value.familyEndId}
                                            cardTotalCount={value.totalCount}
                                            cardTimelineId={value.timelineId}
                                            cardMilestoneTypeIds={milestoneTypes}
                                        />
                                    )

                                })
                            ) : (<>


                                <Container sx={{ mt: 0, mb: 0.5, ml: '-1.25VW', display: { xs: 'none', md: 'block', xl: 'block' } }}>
                                    {/*                                   <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Setup Project Timelines</Typography>*/}
                                    {/*<Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>No Unset Audit Batch Data Available at this time</Typography>*/}


                                </Container>
                                <CreateTimelineCard
                                    cardTitle={"No Unset Batches Available"}
                                    cardSubtitle={'You are up to date!'}
                                    cardGraphic={<PiCheckCircleDuotone />}
                                    desiredCardWidth={800}
                                    desiredCardHeight={150}
                                    desiredHeightPercentage={'90%'}
                                    cardType={'generic'}
                                />
                            </>)}




                        </div>













                    </Container >

                    <Divider sx={{ mt: 2, mb: 2 }} />

                    <Container
                        maxWidth={false}
                        sx={{
                            mt: 3, ml: 0,

                            display: { xs: 'none', md: 'block', xl: 'block' },
                            "&.MuiContainer-maxWidthMd": {
                                maxWidth: "1000px",


                            },
                            '& .MuiContainer-root': {

                                maxWidth: '1000px',


                            },

                        }}>

                        {activeClientTimelineSetupData.length !== 0 ? (<>
                            <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Active Project Timelines</Typography>
                            <div className="row-no-left-margin" >
                                {activeClientTimelineSetupData.map((value) => {

                                    return (
                                        <EditTimelineCard
                                            cardTitle={`Batch Ending: ${dayjs(value.batchEndDate).format('MM/DD')}`}
                                            cardSubtitle={`ID Range: ${value.familyStartId}-${value.familyEndId}`}
                                            cardExtraSubtitle={`Total Count: ${value.totalCount}`}
                                            desiredCardWidth={1160}
                                            desiredCardHeight={150}
                                            desiredHeightPercentage={'90%'}
                                            cardType={'audit'}
                                            cardChipLabel={'Active'}
                                            cardChipStatus={'active'}
                                            cardMilestoneData={value.milestones}
                                            cardBatchEndDate={value.batchEndDate}
                                            cardFamilyStartId={value.familyStartId}
                                            cardFamilyEndId={value.familyEndId}
                                            cardResponseRate={(value.timelineResponseRate / value.totalCount) * 100}
                                            cardTotalCount={value.totalCount}
                                            cardTimelineId={value.timelineId}
                                            cardCurrentDay={value.timelineCurrentDay}
                                            cardTotalDays={value.timelineTotalDays}
                                            cardActiveMilestonePosition={value.activeMilestonePosition}
                                            cardMilestoneTypeIds={milestoneTypes}
                                            cardClick={false}
                                            dynamicCardWidth={true}
                                        />
                                    )

                                })}
                            </div>
                        </>) : (<>
                            <Container sx={{ mt: 0, mb: 0.5, ml: '-1.25VW', display: { xs: 'none', md: 'block', xl: 'block' } }}>

                                <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>Active Project Timelines</Typography>
                                {/*<Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 2, mb: 2, color: '#737373' }}>No Timeline Data Available at this time</Typography>*/}

                                <EditTimelineCard
                                    cardTitle={"No Active Timeline Available"}
                                    cardSubtitle={'Use the form above to generate a Timeline'}
                                    cardGraphic={<TbTimelineEventX />}
                                    desiredCardWidth={800}
                                    desiredCardHeight={150}
                                    desiredHeightPercentage={'90%'}
                                    cardType={'generic'}
                                />

                            </Container>
                        </>)}

                    </Container >
                </>)}









                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>






            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/*  MOBILE PAGE COMPONENTS */}


                {/*  MOBILE PAGE TITLES/HEADINGS*/}

                <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                        <div className="row-left">
                            <Button variant="text"
                                sx={{ mt: 0, ml: '-1VW' }}
                                onClick={() => handleNavigateBackToProject()}
                            > <IoMdArrowRoundBack />Back to Project</Button>



                        </div>


                    </div>

                    <div className="row-no-left-margin">

                        <EditTimelineCard
                            cardTitle={"Setup Not Available"}
                            cardSubtitle={'The Setup Feature is Currently Desktop Only'}
                            cardGraphic={<TbTimelineEventX />}
                            desiredCardWidth={'84VW'}
                            desiredCardHeight={150}
                            desiredHeightPercentage={'90%'}
                            cardType={'generic'}
                        />

                    </div>

                    {/*<Divider sx={{ mt: 2, mb: 2 }} />*/}



                    {/*<Divider sx={{ mt: 2, mb: 2 }} />*/}







                </Container >






            </Box>

        </>

    )
}

export default ProjectSetup;

