
import React, { useContext, useState, useEffect } from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types'; import TextField from "@mui/material/TextField";

import CloseIcon from '@mui/icons-material/Close';

import { UserManagementContext } from '../../pages/Counter2';

import { PatternFormat } from 'react-number-format';

import { useForm, Controller } from "react-hook-form";




import { useParams, useNavigate, Navigate, Link } from "react-router-dom";

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';


import { GraphDetailsContext, ProjectDashboardContext } from '../../App';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { NumericFormat } from 'react-number-format';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';

import { loginRequest, b2cPolicies } from "../../authConfig";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import useFetchTimelines from "../../hooks/useFetchTimelines";



const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const materialUITextFieldProps = {
    sx: [{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }],
    variant: "outlined" 
};

function CreateTimelineCard({ cardTitle, cardSubtitle, desiredCardWidth, desiredCardHeight, desiredHeightPercentage, cardType, cardChipLabel, cardChipStatus, cardExtraSubtitle, cardMilestoneData, cardBatchEndDate, cardFamilyStartId, cardFamilyEndId, cardTotalCount, cardTimelineId, cardGraphic, cardMilestoneTypeIds }) {

    const fetchTimelines = useFetchTimelines();

    const {

        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates,
        handleUpdateAvailableClientProjectTimelineData, handleUpdateTimelineMilestonesByBatchEndDate,
        reloadClientProjectTimelineData, setReloadClientProjectTimelineData, handleUpdateReloadClientProjectTimelineData, handleUpdateTimelineDataByBatchEndDate,
        handleUpdateTimelineActiveByBatchEndDate, handleUpdateTimelineNewByBatchEndDate, setAvailableClientProjectTimelineData, 
        haveAPITimelineData, setHaveAPITimelineData,
        handleGetLatestInactiveAndActiveSetupData,
        availableClientProjectTimelineData,
        timelineSetupReload, setTimelineSetupReload
    } = useContext(ProjectDashboardContext);


    const { accounts, instance } = useMsal();
    const { id, auditName, auditFamilyId, employeeId } = useParams();


    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData
    } = useContext(GraphDetailsContext);

    const navigate = useNavigate();

    const [projectTimelineData, setProjectTimelineData] = useState([]);

    function handleUpdateProjectTimelineData(update) {
        if (update !== null) {
            setProjectTimelineData(update)
        }
    }

    const [milestone1Active, setMilestone1Active] = useState(true);
    const [milestone2Active, setMilestone2Active] = useState(true);
    const [milestone3Active, setMilestone3Active] = useState(true);
    const [milestone4Active, setMilestone4Active] = useState(true);
    const [milestone5Active, setMilestone5Active] = useState(true);
    const [milestone6Active, setMilestone6Active] = useState(true);

    function handleUpdateMilestone1Active(update) {
        setMilestone1Active(update)
    }

    function handleUpdateMilestone2Active(update) {
        setMilestone2Active(update)
    }

    function handleUpdateMilestone3Active(update) {
        setMilestone3Active(update)
    }

    function handleUpdateMilestone4Active(update) {
        setMilestone4Active(update)
    }

    function handleUpdateMilestone5Active(update) {
        setMilestone5Active(update)
    }

    function handleUpdateMilestone6Active(update) {
        setMilestone6Active(update)
    }

    const [milestone1Title, setMilestone1Title] = useState("Initial Notice");
    const [milestone2Title, setMilestone2Title] = useState("Reminder");
    const [milestone3Title, setMilestone3Title] = useState("Grace Period");
    const [milestone4Title, setMilestone4Title] = useState("Final Notice + Appeals");
    const [milestone5Title, setMilestone5Title] = useState("Wrap-up Period");
    const [milestone6Title, setMilestone6Title] = useState("Final Report");

    function handleMilestone1TitleChange(event) {
        setMilestone1Title(event.target.value)
    }
    function handleMilestone2TitleChange(event) {
        setMilestone2Title(event.target.value)
    }
    function handleMilestone3TitleChange(event) {
        setMilestone3Title(event.target.value)
    }
    function handleMilestone4TitleChange(event) {
        setMilestone4Title(event.target.value)
    }
    function handleMilestone5TitleChange(event) {
        setMilestone5Title(event.target.value)
    }
    function handleMilestone6TitleChange(event) {
        setMilestone6Title(event.target.value)
    }

    const [milestone1Goal, setMilestone1Goal] = useState(50);
    const [milestone2Goal, setMilestone2Goal] = useState(80);
    const [milestone3Goal, setMilestone3Goal] = useState(80);
    const [milestone4Goal, setMilestone4Goal] = useState(90);
    const [milestone5Goal, setMilestone5Goal] = useState(92);
    const [milestone6Goal, setMilestone6Goal] = useState(92);

    function handleMilestone1GoalUpdate(event) {
       
        if (event.target.value !== null) {
            setMilestone1Goal(event.target.value)
        }

    }

    function handleMilestone2GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone2Goal(event.target.value)
        }

    }

    function handleMilestone3GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone3Goal(event.target.value)
        }

    }

    function handleMilestone4GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone4Goal(event.target.value)
        }

    }

    function handleMilestone5GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone5Goal(event.target.value)
        }

    }

    function handleMilestone6GoalUpdate(event) {

        if (event.target.value !== null) {
            setMilestone6Goal(event.target.value)
        }

    }
   
    const [milestone1EndDateValue, setMilestone1EndDateValue] = useState(null);
    const [milestone2EndDateValue, setMilestone2EndDateValue] = useState(null);
    const [milestone3EndDateValue, setMilestone3EndDateValue] = useState(null);
    const [milestone4EndDateValue, setMilestone4EndDateValue] = useState(null);
    const [milestone5EndDateValue, setMilestone5EndDateValue] = useState(null);
    const [milestone6EndDateValue, setMilestone6EndDateValue] = useState(null);

    
    function handleMilestone1EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone1EndDateValue(value)
        }

    }

    function handleMilestone2EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone2EndDateValue(value)
        }

    }

    function handleMilestone3EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone3EndDateValue(value)
        }

    }

    function handleMilestone4EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone4EndDateValue(value)
        }

    }

    function handleMilestone5EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone5EndDateValue(value)
        }

    }

    function handleMilestone6EndDateValueUpdate(value) {

        if (value !== null) {
            setMilestone6EndDateValue(value)
        }

    }


    function getEditMilestoneFunctionFromPosition(milestonePosition) {
        if (milestonePosition === 0) {
            return handleMilestone1TitleChange;
        }
    }

  
    const [milestoneTypes, setMilestoneTypes] = useState(cardMilestoneTypeIds)
    const [milestone1TypeId, setMilestone1TypeId] = useState("");
    const [milestone2TypeId, setMilestone2TypeId] = useState("");
    const [milestone3TypeId, setMilestone3TypeId] = useState("");
    const [milestone4TypeId, setMilestone4TypeId] = useState("");
    const [milestone5TypeId, setMilestone5TypeId] = useState("");
    const [milestone6TypeId, setMilestone6TypeId] = useState("");

    function getProperMilestoneTypeIdByPosition(milestonePosition) {
        let tempMilestoneTypes = milestoneTypes;
        console.log("tempMilestoneTypes:", tempMilestoneTypes);

        let toReturn = "";
      

        const newMap = tempMilestoneTypes.map((item) => {
            if (item.milestoneType === "Initial Notice" && milestonePosition === 0) {
                setMilestone1TypeId(item.milestoneTypeId)
                console.log("milestone1 TypeID: ", item.milestoneTypeId)
                toReturn = item.milestoneTypeId;
               
              
            } else if (item.milestoneType === "Reminder" && milestonePosition === 1 ) {
                setMilestone2TypeId(item.milestoneTypeId)
                console.log("milestone2 TypeID: ", item.milestoneTypeId)
                toReturn = item.milestoneTypeId;
               
               
            } else if (item.milestoneType === "Deadline" && milestonePosition === 2 ) {
                setMilestone3TypeId(item.milestoneTypeId)
                console.log("milestone3 TypeID: ", item.milestoneTypeId)
                toReturn = item.milestoneTypeId;
               
               
            } else if (item.milestoneType === "Final Notice" && milestonePosition === 3 ) {
                setMilestone4TypeId(item.milestoneTypeId)
                console.log("milestone4 TypeID: ", item.milestoneTypeId)
                toReturn = item.milestoneTypeId;
               
               
            } else if (item.milestoneType === "Final Deadline" && milestonePosition === 4) {
                setMilestone5TypeId(item.milestoneTypeId)
                console.log("milestone5 TypeID: ", item.milestoneTypeId)
                toReturn = item.milestoneTypeId;
              
               
            } else if (item.milestoneType === "Final Report" && milestonePosition === 5) {
                setMilestone6TypeId(item.milestoneTypeId)
                console.log("milestone6 TypeID: ", item.milestoneTypeId)
                toReturn = item.milestoneTypeId;
               
               
            } else {
                return toReturn;
            }

          
            
        })

        return toReturn;
    }



   
      
    

    function getDifferenceBetweenNearestDateMilestones(milestoneRequesting) {
        let date1 = null;
        let date2 = null;
        let result = 0;

        //use milestone position for milestoneRequesting
        if (milestoneRequesting === 0) {
            date1 = dayjs(milestone1EndDateValue);
            date2 = dayjs(milestone2EndDateValue);
            result = date2.diff(date1, 'day')
            console.log("0-computed result: ", result)
            //if (milestone2Active === true && milestone3Active === true) {
            //    date1 = dayjs(milestone1EndDateValue);
            //    date2 = dayjs(milestone2EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m2T-computed result: ", result)
            //} else if (milestone2Active === false && milestone3Active === true) {
            //    date1 = dayjs(milestone1EndDateValue);
            //    date2 = dayjs(milestone3EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m3T-computed result: ", result)
            //} else if (milestone2Active === false && milestone3Active === false) {
            //    date1 = dayjs(milestone1EndDateValue);
            //    date2 = dayjs(milestone4EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m4T-computed result: ", result)
            //}
          

        } else if (milestoneRequesting === 1) {
            date1 = dayjs(milestone2EndDateValue);
            date2 = dayjs(milestone3EndDateValue);
            result = date2.diff(date1, 'day')
            console.log("1-computed result: ", result)
            //if (milestone2Active === true && milestone3Active === true) {
            //    date1 = dayjs(milestone2EndDateValue);
            //    date2 = dayjs(milestone3EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m23T-computed result: ", result)
            //} else if (milestone2Active === false && milestone3Active === true) {
            //    date1 = dayjs(milestone3EndDateValue);
            //    date2 = dayjs(milestone4EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m34T-computed result: ", result)
            //} else if (milestone2Active === false && milestone3Active === false) {
            //    date1 = dayjs(milestone1EndDateValue);
            //    date2 = dayjs(milestone5EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m15T-computed result: ", result)
            //}
           

        } else if (milestoneRequesting === 2) {
            //if (milestone2Active === true && milestone3Active === true) {
            //    date1 = dayjs(milestone3EndDateValue);
            //    date2 = dayjs(milestone4EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m34T-computed result: ", result)
            //} else if (milestone2Active === false && milestone3Active === true) {
            //    date1 = dayjs(milestone3EndDateValue);
            //    date2 = dayjs(milestone4EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m34T-computed result: ", result)
            //} else if (milestone2Active === false && milestone3Active === false) {
            //    date1 = dayjs(milestone4EndDateValue);
            //    date2 = dayjs(milestone5EndDateValue);
            //    result = date2.diff(date1, 'day')
            //    console.log("0m45T-computed result: ", result)
            //}
            date1 = dayjs(milestone3EndDateValue);
            date2 = dayjs(milestone4EndDateValue);
            result = date2.diff(date1, 'day')
            console.log("2-computed result: ", result)

        } else if (milestoneRequesting === 3) {
            date1 = dayjs(milestone4EndDateValue);
            date2 = dayjs(milestone5EndDateValue);
            result = date2.diff(date1, 'day')
            console.log("3-computed result: ", result)

        } else if (milestoneRequesting === 4) {
            date1 = dayjs(milestone5EndDateValue);
            date2 = dayjs(milestone6EndDateValue);
            result = date2.diff(date1, 'day')
            console.log("4-computed result: ", result)

        } else if (milestoneRequesting === 5) {
            date1 = dayjs(milestone5EndDateValue);
            date2 = dayjs(milestone6EndDateValue);
            result = date2.diff(date1, 'day')
            console.log("5-computed result: ", result)

        } 

        console.log("returned result: ", result)

        return result;
    }

    

    function handleGenerateNewTimeline() {
        console.log("VALUES: milestone1: Active:", milestone1Active, "milestone1Title: ", milestone1Title, "milestone1EndDate: ", milestone1EndDateValue, "milestone1Goal: ", milestone1Goal)
        console.log("VALUES: milestone2: Active:", milestone2Active, "milestone1Title: ", milestone2Title, "milestone1EndDate: ", milestone2EndDateValue, "milestone1Goal: ", milestone2Goal)

        console.log("VALUES: milestone3: Active:", milestone3Active, "milestone1Title: ", milestone3Title, "milestone1EndDate: ", milestone3EndDateValue, "milestone1Goal: ", milestone3Goal)

        console.log("VALUES: milestone4: Active:", milestone4Active, "milestone1Title: ", milestone4Title, "milestone1EndDate: ", milestone4EndDateValue, "milestone1Goal: ", milestone4Goal)

        console.log("VALUES: milestone5: Active:", milestone5Active, "milestone1Title: ", milestone5Title, "milestone1EndDate: ", milestone5EndDateValue, "milestone1Goal: ", milestone5Goal)

        console.log("VALUES: milestone6: Active:", milestone6Active, "milestone1Title: ", milestone6Title, "milestone1EndDate: ", milestone6EndDateValue, "milestone1Goal: ", milestone6Goal)

        //NEED a cool - format only the "Active" ones for properly structuring "Milestones" in Chris response, but for now, lets just get it working with all of them;
        let newMilestoneData = [];
      

        if (milestone1Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;
          

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneToggleActive": milestone1Active,
                "milestoneLabel": milestone1Title,
                "milestoneTypeId": getProperMilestoneTypeIdByPosition(i),
                "milestoneEndDate": milestone1EndDateValue,
                "milestoneGoal": parseFloat(milestone1Goal),
              
               


            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone2Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;
           

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneToggleActive": milestone2Active,
                "milestoneLabel": milestone2Title,
                "milestoneTypeId": getProperMilestoneTypeIdByPosition(i),
                "milestoneEndDate": milestone2EndDateValue !== null ? milestone2EndDateValue : milestone1EndDateValue,
                "milestoneGoal": parseFloat(milestone2Goal),

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone3Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;
           

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneToggleActive": milestone3Active,
                "milestoneLabel": milestone3Title,
                "milestoneTypeId": getProperMilestoneTypeIdByPosition(i),
                "milestoneEndDate": milestone3EndDateValue !== null ? milestone3EndDateValue : milestone5EndDateValue,
                "milestoneGoal": parseFloat(milestone3Goal),

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone4Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;
           

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneToggleActive": milestone4Active,
                "milestoneLabel": milestone4Title,
                "milestoneTypeId": getProperMilestoneTypeIdByPosition(i),
                "milestoneEndDate": milestone4EndDateValue !== null ? milestone4EndDateValue : milestone5EndDateValue,
                "milestoneGoal": parseFloat(milestone4Goal),

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone5Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;
           

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneToggleActive": milestone5Active,
                "milestoneLabel": milestone5Title,
                "milestoneTypeId": getProperMilestoneTypeIdByPosition(i),
                "milestoneEndDate": milestone5EndDateValue,
                "milestoneGoal": parseFloat(milestone5Goal),
               

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        if (milestone6Active !== null) {
            let i = newMilestoneData?.length;
            let newItemID = i + 1;
        

            const newMilestoneDataItem = {
                "milestonePosition": i,
                "milestoneToggleActive": milestone6Active,
                "milestoneLabel": milestone6Title,
                "milestoneTypeId": getProperMilestoneTypeIdByPosition(i),
                "milestoneEndDate": milestone6EndDateValue,
                "milestoneGoal": parseFloat(milestone6Goal),
              

            };
            newMilestoneData = [...newMilestoneData, newMilestoneDataItem];
        }

        console.log("NEW MILESTONE DATA: ", newMilestoneData);

        console.log("nu timeline response object:",);

     setHaveAPITimelineData(false)

        let tempProjectTimelineDataObj = {
            "auditId": id,
            "batchEndDate": cardBatchEndDate !== null ? cardBatchEndDate : "(Data Not Yet Available)",
            "MilestonesStartDate": milestone1EndDateValue,
            "MilestonesEndDate": milestone6EndDateValue,
            "milestones": newMilestoneData,

        }

        ///TODO - Call thing that actually updates the network resource for project timelines by client ID, add new - (currently being built else where)

       
            function submitUserData(){
                instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        console.log("WOAH YEAH")
                        const fetchData = () => {
                            fetch(process.env.REACT_APP_BACK_URL + '/api/Timeline/CreateTimeline', {
                                method: 'POST',
                                mode: 'cors',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + result.idToken,
                                },
                                body: JSON.stringify({
                                    "auditId": id,
                                    "batchEndDate": cardBatchEndDate !== null ? cardBatchEndDate : "(Data Not Yet Available)",
                                    "MilestonesStartDate": milestone1EndDateValue,
                                    "MilestonesEndDate": milestone6EndDateValue,
                                    "milestones": newMilestoneData, })
                            })
                                .then(res => res.json())
                                .then((response) => {
                                    console.log("CREATE RESPONSE: ", response)

                                   //handleUpdateReloadClientProjectTimelineData(true);
                                   //handleGetTimelinesForAudit(id);
                                  
                                   //setOpenModal(false);
                                    //setHaveAPITimelineData(true)
                                 
                                    //call get timelines fetch request

                                }).catch((error) => {
                                    console.log(error)
                                    //throw error if submission fails
                                })


                        }

                        fetchData();

                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }

                    });

            }
            submitUserData();

        //function handleGetTimelinesForAudit(id) {
        //    instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
        //        .then(result => {
        //            const fetchAuditTimelines = async () => {
        //                let retry = 5;
        //                let response = null;
        //                let counter = 1;
        //                let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
        //                while (retry > 0) {
        //                    if (retry < 5) {
        //                        let d = 2000 * counter;
        //                        await timeout(d);
        //                    }
        //                    await fetch(process.env.REACT_APP_BACK_URL + '/api/Timeline/GetTimelines', {
        //                        method: 'POST',
        //                        mode: 'cors',
        //                        headers: {
        //                            'Content-Type': 'application/json',
        //                            'Authorization': 'Bearer ' + result.id,
        //                        },
        //                        body: JSON.stringify({ "auditId": parseInt(id) })
        //                    }).then(res => {
        //                        if (res.ok) {
        //                            retry = 0
        //                            response = res.json();
        //                            setAvailableClientProjectTimelineData(response);
        //                          /*  handleUpdateAvailableClientProjectTimelineData(response);*/
        //                            return;
        //                        }
        //                        if (retry > 1) {
        //                            retry--;
        //                            counter++;
        //                            return;
        //                        }
        //                        if (retry <= 1) {
        //                            retry = 0;
        //                            //failedFetchError(res.status); //throw error
        //                            setThrowFetchError(!throwFetchError);
        //                            setFetchStatus(res.status);
        //                            return;
        //                        }


        //                    })
        //                        .catch((error) => { console.log(error) })
        //                }
        //            }
        //            fetchAuditTimelines(id);
        //        }).catch((error) => {
        //            console.log(error) //token expired/is not valid => user is redirected to login page
        //            if (error instanceof InteractionRequiredAuthError) {
        //                instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
        //            }

        //        });


        //}
        //setProjectTimelineData([
        //    {
        //        "auditId": id,
        //        "batchEndDate": cardBatchEndDate !== null ? cardBatchEndDate : "(Data Not Yet Available)",
        //        "auditTimelineId": cardTimelineId !== null ? cardTimelineId : "(Data Not Yet Available)",
        //        "familyStartId": cardFamilyStartId !== null ? cardFamilyStartId : "(Data Not Yet Available)",
        //        "familyEndId": cardFamilyEndId !== null ? cardFamilyEndId : "(Data Not Yet Available)",
        //        "totalCount": cardTotalCount !== null ? cardTotalCount : "(Data Not Yet Available)",
        //        "active": true,
        //        "totalResponseRate": 0,
        //        "newTimeline": false,
        //        "timelineTotalDays": 0,
        //        "timelineCurrentDay": 0,
        //        "milestones": newMilestoneData,

        //    }
        //])


        //update state through context
      /*  handleUpdateTimelineMilestonesByBatchEndDate(cardBatchEndDate, newMilestoneData)*/
        //handleUpdateTimelineActiveByBatchEndDate(cardBatchEndDate, true)
        //handleUpdateTimelineNewByBatchEndDate(cardBatchEndDate, false)

        ///TODO - Call thing that actually updates the network resource for project timelines by client ID, add new - (currently being built else where)

        /* handleUpdateTimelineDataByBatchEndDate(cardBatchEndDate, tempProjectTimelineDataObj)*/

        //handleUpdateReloadClientProjectTimelineData(true);
        fetchTimelines.handleFetchAllTimelines(id);
        setTimelineSetupReload(true);
        //close modal
        setOpenModal(false);

       

        //setMainSearchFilterModel([])
        //apiRef.current.setFilterModel({
        //    items: []

        //});



        //handleGetAndSetAllFiltersForUpdate()
    }

    
   
 
   

    useEffect(() => {

      

        setMilestone1Active(true)
        setMilestone2Active(true)
        setMilestone3Active(true)
        setMilestone4Active(true)
        setMilestone5Active(true)
        setMilestone6Active(true)

    }, [])

    //potentially remove 

    useEffect(() => {
      console.log("New Timeline updated data: ", projectTimelineData)
        handleUpdateReloadClientProjectTimelineData(true);
    }, [projectTimelineData])


    

    /*let stringedStateObj = JSON.stringify(stateObj)*/

   

    return (
        <>

            <Card style={{ height: desiredHeightPercentage != null ? (desiredHeightPercentage) : ('90%') }} sx={{
                mr: 1.5,
                mb: 1.5,
                minWidth: desiredCardWidth ? (desiredCardWidth) : (75),
                minHeight: desiredCardHeight ? (desiredCardHeight) : (400),
                height: desiredCardHeight ? (desiredCardHeight) : (400),
                width: desiredCardWidth ? (desiredCardWidth) : (400)
}}>


                {cardType == 'generic' ? (<>
                    {cardGraphic !== null ? (<>
                        <Typography sx={{ mt: 1 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            <div style={{ textAlign: 'center', fontSize: '300%', color: '#233cb0' }}>{cardGraphic}</div>

                        </Typography>

                    </>) : (<></>)}
                    <Typography sx={{ mt: 0 }} textAlign="center" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                        {cardTitle}

                    </Typography>

                    <Typography sx={{ mt: 1 }} textAlign="center" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                        {cardSubtitle}

                    </Typography>

                    {/* <Button onClick={handleOpenModal} sx={{ ml: "45.5%", mt: 1.5, bgcolor: '#233cb0', fontWeight: '700', width: '2VW' }} variant="contained">Start</Button>*/}

                </>) : (<></>)}

                {cardType == 'audit' ? (<>
                    <div className="row-no-margin">
                        <Typography sx={{ mt: 3, ml: 2 }} textAlign="left" variant="h6" fontWeight="700" fontSize="0.9em" component="div">

                            {cardTitle}

                        </Typography>
                       
                        <div className="row-right">
                            <Chip variant={cardChipStatus === 'unset' ? "outlined" : ""} color={cardChipStatus === 'unset' ? "warning" : "success"} label={cardChipLabel} sx={{ color: cardChipStatus === 'unset' ? "#EA580C" : "",bgcolor: cardChipStatus === 'unset' ? "" : "#2DD4BF", mr: 1, mt: '2.5VH', fontWeight: '700' }} />

                        </div>
                          
                       
                    </div>
                    

                    {/* <Typography sx={{ mt: '-1.25VH', ml: 2, color: cardChipStatus === 'unset' ? "": '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                        {cardSubtitle}

                    </Typography> */}

                    {cardExtraSubtitle !== null ? (<>
                        {/* <Typography sx={{ mt: 0, ml: 2, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">

                            {cardExtraSubtitle}

                        </Typography> */}
                    </>) : (<></>)}


                    <div className="row-no-left-margin">
                        <div className="row-right">
                            <Button  onClick={handleOpenModal} sx={{ mt: cardChipStatus === 'unset' ? 0 : 0, mr: 1, bgcolor: '#233cb0', fontWeight: '700', width: '2VW' }} variant="contained">{cardChipStatus === 'unset' ? 'Start' : 'Edit'}</Button>

                        </div>
                    </div>


                </>) : (<></>)}



                {/*<div className="row-no-left-margin">*/}

                {/*    <div className="row-right">*/}




                {/*    </div>*/}

                {/*</div>*/}
                <Container sx={{ ml: '-4.5VW', mb: '-0.5VH', mt: '-4VH' }}>
                    <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        <Grid item xs={4} >

                           

                        </Grid>

                        <Grid item xs={8} >

                           

                        </Grid>
                    </Grid>
                </Container>













            </Card>
           

            <BootstrapDialog
                fullWidth={true}
                maxWidth={'lg'}
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <BootstrapDialogTitle sx={{ fontWeight: 700, fontSize: '20px' }} id="customized-dialog-title" onClose={handleCloseModal}>
                    Create Project Timeline - {cardTitle}
                </BootstrapDialogTitle>
                <form
                  
                >
                    <DialogContent>
                        {/*//FORM CONTENT GOES HERE*/}

                        {cardMilestoneData?.length !== 0 ? (<>
                            {cardMilestoneData?.map((itemx) => {
                                
                                   
                                return (
                                    <>
                                        <Typography sx={{ mt: 0.5, ml: 2, color: cardChipStatus === 'unset' ? "" : '#A3A3A3' }} textAlign="left" variant="h6" fontWeight="500" fontSize="0.8em" component="div">
                                            label:{itemx?.milestoneLabel}+EndDate:{dayjs(itemx?.milestoneEndDate).toString()}+Goal:{itemx?.milestoneGoal}+ToggleActive:{itemx?.milestoneToggleActive === true ? "true" : "false"}
                                        </Typography>

                                        <div className="modal-row">
                                            <TextField
                                                label="Milestone Title"
                                                variant="outlined"
                                                value={itemx?.milestoneLabel}
                                                disabled={itemx?.milestoneToggleActive !== true ? true : false}
                                                onChange={handleMilestone1TitleChange}
                                               
                                                sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                            />

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                <DatePicker
                                                    value={dayjs(itemx?.milestoneEndDate)}
                                                    disabled={itemx?.milestoneToggleActive !== true ? true : false}
                                                    label={itemx?.milestoneLabel !== "" ? `${itemx?.milestoneLabel} Milestone Date` : 'Milestone Date'}
                                                    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}
                                                   
                                                />

                                            </LocalizationProvider>

                                          

                                            <NumericFormat
                                                customInput={TextField}
                                                {...materialUITextFieldProps}
                                                disabled={itemx?.milestoneToggleActive !== true ? true : false}
                                                label={itemx?.milestoneLabel !== "" ? `${itemx?.milestoneLabel} Milestone Goal` : 'Milestone Goal'}
                                                value={itemx?.milestoneGoal}

                                                

                                               



                                                valueIsNumericString={true}
                                                suffix="%"
                                            />

                                          

                                            {/*<FormControlLabel*/}
                                                
                                            {/*    onClick={() => handleUpdateMilestone1Active(!itemx?.milestoneToggleActive)}*/}
                                            {/*    control={<IOSSwitch sx={{ m: 1 }} checked={itemx?.milestoneToggleActive} />}*/}
                                            {/*    label="Active"*/}
                                            {/*/>*/}

                                        </div>

                                    </>
                                  )
                                   
                                
                            })}
                        </>) : (<>
                                <div className="modal-row">
                                    <TextField
                                        label="Milestone Title"
                                        variant="outlined"
                                        value={milestone1Title}
                                        disabled={milestone1Active !== true ? true : false}
                                        onChange={handleMilestone1TitleChange}
                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                    />

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DatePicker
                                            value={milestone1EndDateValue}
                                            onChange={handleMilestone1EndDateValueUpdate}
                                            label={milestone1Title !== "" ? `${milestone1Title} Milestone Start Date` : 'Milestone Start Date'}
                                            sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}
                                            disabled={milestone1Active !== true ? true : false}
                                        />

                                    </LocalizationProvider>

                                    {/*<TextField*/}
                                    {/*    label="Date"*/}
                                    {/*    variant="outlined"*/}
                                    {/*    value={""}*/}


                                    {/*    sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}*/}


                                    {/*/>*/}

                                    <NumericFormat
                                        customInput={TextField}
                                        {...materialUITextFieldProps}
                                        disabled={milestone1Active !== true ? true : false}
                                        label={milestone1Title !== "" ? `${milestone1Title} Milestone Goal` : 'Milestone Goal'}
                                        value={milestone1Goal}

                                        onValueChange={(values) => {
                                            handleMilestone1GoalUpdate({ target: { value: values.value } })
                                        }

                                        }



                                        valueIsNumericString={true}
                                        suffix="%"
                                    />

                                   

                                    {/*<FormControlLabel*/}
                                    {/*    onClick={() => handleUpdateMilestone1Active(!milestone1Active)}*/}
                                    {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone1Active} />}*/}
                                    {/*    label="Active"*/}
                                    {/*/>*/}

                                </div>


                                <div className="modal-row">
                                    <TextField
                                        label="Milestone Title"
                                        variant="outlined"
                                        value={milestone2Title}
                                        onChange={handleMilestone2TitleChange}
                                        disabled={milestone2Active !== true ? true : false}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                    />

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DatePicker
                                            value={milestone2EndDateValue}
                                            onChange={handleMilestone2EndDateValueUpdate}
                                            label={milestone2Title !== "" ? `${milestone2Title} Milestone Start Date` : 'Milestone Start Date'}
                                            disabled={milestone2Active !== true ? true : false}
                                            sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                    </LocalizationProvider>

                                    <NumericFormat
                                        customInput={TextField}
                                        {...materialUITextFieldProps}
                                        disabled={milestone2Active !== true ? true : false}
                                        label={milestone2Title !== "" ? `${milestone2Title} Milestone Goal` : 'Milestone Goal'}
                                        value={milestone2Goal}

                                        onValueChange={(values) => {
                                            handleMilestone2GoalUpdate({ target: { value: values.value } })
                                        }

                                        }



                                        valueIsNumericString={true}
                                        suffix="%"
                                    />

                                    <FormControlLabel
                                        onClick={() => handleUpdateMilestone2Active(!milestone2Active)}
                                        control={<IOSSwitch sx={{ m: 1 }} checked={milestone2Active} />}
                                        label="Active"
                                    />

                                </div>

                                <div className="modal-row">
                                    <TextField
                                        label="Milestone Title"
                                        variant="outlined"
                                        value={milestone3Title}
                                        onChange={handleMilestone3TitleChange}
                                        disabled={milestone3Active !== true ? true : false}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                    />

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DatePicker
                                            value={milestone3EndDateValue}
                                            onChange={handleMilestone3EndDateValueUpdate}
                                            disabled={milestone3Active !== true ? true : false}
                                            label={milestone3Title !== "" ? `${milestone3Title} Milestone Start Date` : 'Milestone Start Date'}

                                            sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                    </LocalizationProvider>

                                    <NumericFormat
                                        customInput={TextField}
                                        {...materialUITextFieldProps}
                                        disabled={milestone3Active !== true ? true : false}
                                        label={milestone3Title !== "" ? `${milestone3Title} Milestone Goal` : 'Milestone Goal'}
                                        value={milestone3Goal}

                                        onValueChange={(values) => {
                                            handleMilestone3GoalUpdate({ target: { value: values.value } })
                                        }

                                        }



                                        valueIsNumericString={true}
                                        suffix="%"
                                    />

                                    <FormControlLabel
                                        onClick={() => handleUpdateMilestone3Active(!milestone3Active)}
                                        control={<IOSSwitch sx={{ m: 1 }} checked={milestone3Active} />}
                                        label="Active"
                                    />

                                </div>

                                <div className="modal-row">
                                    <TextField
                                        label="Milestone Title"
                                        variant="outlined"
                                        value={milestone4Title}
                                        onChange={handleMilestone4TitleChange}
                                        disabled={milestone4Active !== true ? true : false}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                    />

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DatePicker
                                            value={milestone4EndDateValue}
                                            onChange={handleMilestone4EndDateValueUpdate}
                                            disabled={milestone4Active !== true ? true : false}
                                            label={milestone4Title !== "" ? `${milestone4Title} Milestone Start Date` : 'Milestone Start Date'}

                                            sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                    </LocalizationProvider>

                                    <NumericFormat
                                        customInput={TextField}
                                        {...materialUITextFieldProps}
                                        disabled={milestone4Active !== true ? true : false}
                                        label={milestone4Title !== "" ? `${milestone4Title} Milestone Goal` : 'Milestone Goal'}
                                        value={milestone4Goal}

                                        onValueChange={(values) => {
                                            handleMilestone4GoalUpdate({ target: { value: values.value } })
                                        }

                                        }



                                        valueIsNumericString={true}
                                        suffix="%"
                                    />

                                    <FormControlLabel
                                        onClick={() => handleUpdateMilestone4Active(!milestone4Active)}
                                        control={<IOSSwitch sx={{ m: 1 }} checked={milestone4Active} />}
                                        label="Active"
                                    />

                                </div>

                                <div className="modal-row">
                                    <TextField
                                        label="Milestone Title"
                                        variant="outlined"
                                        value={milestone5Title}
                                        onChange={handleMilestone5TitleChange}
                                        disabled={milestone5Active !== true ? true : false}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                    />

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DatePicker
                                            value={milestone5EndDateValue}
                                            onChange={handleMilestone5EndDateValueUpdate}
                                            disabled={milestone5Active !== true ? true : false}
                                            label={milestone5Title !== "" ? `${milestone5Title} Milestone Start Date` : 'Milestone Start Date'}
                                            sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                    </LocalizationProvider>

                                    <NumericFormat
                                        customInput={TextField}
                                        {...materialUITextFieldProps}
                                        disabled={milestone5Active !== true ? true : false}
                                        label={milestone5Title !== "" ? `${milestone5Title} Milestone Goal` : 'Milestone Goal'}
                                        value={milestone5Goal}

                                        onValueChange={(values) => {
                                            handleMilestone5GoalUpdate({ target: { value: values.value } })
                                        }

                                        }



                                        valueIsNumericString={true}
                                        suffix="%"
                                    />

                                    {/*<FormControlLabel*/}
                                    {/*    onClick={() => handleUpdateMilestone5Active(!milestone5Active)}*/}
                                    {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone5Active} />}*/}
                                    {/*    label="Active"*/}
                                    {/*/>*/}

                                </div>

                                <div className="modal-row">
                                    <TextField
                                        label="Milestone Title"
                                        variant="outlined"
                                        value={milestone6Title}
                                        onChange={handleMilestone6TitleChange}
                                        disabled={milestone6Active !== true ? true : false}

                                        sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }}


                                    />

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                        <DatePicker
                                            value={milestone6EndDateValue}
                                            onChange={handleMilestone6EndDateValueUpdate}
                                            disabled={milestone6Active !== true ? true : false}
                                            label={milestone6Title !== "" ? `${milestone6Title} Milestone End Date` : 'Milestone End Date'}

                                            sx={{ mr: '1VW', width: '16VW', bgcolor: '#FFFFFF' }} />

                                    </LocalizationProvider>

                                    <NumericFormat
                                        customInput={TextField}
                                        {...materialUITextFieldProps}
                                        disabled={milestone6Active !== true ? true : false}
                                        label={milestone6Title !== "" ? `${milestone6Title} Milestone Goal` : 'Milestone Goal'}
                                        value={milestone6Goal}

                                        onValueChange={(values) => {
                                            handleMilestone6GoalUpdate({ target: { value: values.value } })
                                        }

                                        }



                                        valueIsNumericString={true}
                                        suffix="%"
                                    />

                                    {/*<FormControlLabel*/}
                                    {/*    onClick={() => handleUpdateMilestone6Active(!milestone6Active)}*/}
                                    {/*    control={<IOSSwitch sx={{ m: 1 }} checked={milestone6Active} />}*/}
                                    {/*    label="Active"*/}
                                    {/*/>*/}

                                </div>
                        </>)}

                     
                   
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" autoFocus onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleGenerateNewTimeline()}
                            variant="contained"
                           
                            autoFocus>
                            Create
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        </>
    );
}
export default CreateTimelineCard;

