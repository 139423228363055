

import React, { useState, useEffect, useContext, createContext, useRef } from 'react'
import { useTable } from 'react-table'
import { useParams, useNavigate, Navigate, Link, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form'
/*import { Artboard, Card, Collapse, Stats, Table, Progress, RadialProgress, Button, Input, Select, Modal, Dropdown } from 'react-daisyui';*/
import { BsCheckLg, BsXLg, BsExclamationCircleFill, BsThreeDots } from 'react-icons/bs';
import { GraphDetailsContext, SidebarContext, ProjectDashboardContext, RenderedProjectDashboardContext } from "../App";
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext, RolesContext } from "../App";
import useFetch from "../hooks/useFetch";
import Tooltip from "@mui/material/Tooltip";
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Button from '@mui/material/Button';
import { IoMdArrowRoundBack } from "react-icons/io";

import { TbWorldSearch } from "react-icons/tb";
import { FaSearch } from "react-icons/fa";


import FamilyDetailsEmployeeCard from '../components/cards/FamilyDetailsEmployeeCard';
import Divider from '@mui/material/Divider';


import FamilyDetailsMembersTable from '../components/tables/FamilyDetailsMembersTable';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';

import FamilyDetailsFilesUploadedTable from '../components/tables/FamilyDetailsFilesUploadedTable';

import GraphCard from "../components/cards/GraphCard";
import PieGraph from "../components/graphs/PieGraph";

import GraphLegendCard from '../components/cards/GraphLegendCard';
import PercentageBarChart from "../components/graphs/PercentageBarChart";
import { format, formatISO } from 'date-fns'



import GraphDetailsTable from '../components/tables/GraphDetailsTable';
import GraphDetailsTableMobile from "../components/tables/GraphDetailsTableMobile";

import GraphCardWide from '../components/cards/GraphCardWide';
import GraphCardWideBarChart from '../components/cards/GraphCardWideBarChart';
import GraphCardWideBarChartMobile from '../components/cards/GraphCardWideBarChartMobile';
import GraphCardWideMobile from '../components/cards/GraphCardWideMobile';
import GraphCardMobile from '../components/cards/GraphCardMobile';
import GraphLegendCardMobile from '../components/cards/GraphLegendCardMobile';
import IneligibleReasonBarChart from '../components/graphs/IneligibleReasonBarChart';
import GraphCardWideIneligibleBarChart from '../components/cards/GraphCardWideIneligibleBarChart';
import useFetchGraphDetails from '../hooks/useFetchGraphDetails';
import useFilterGraph from "../hooks/useFilterGraph";

import { MdBuild } from "react-icons/md";
import { HiWrenchScrewdriver } from "react-icons/hi2";






function GraphDetails({ onChange, children }) {

    /* const { rowSelectedMemberData } = useContext(EmployeeSearchContext)*/

    /* console.log("PASSED IN ROW SELECTED MEMBER DATA: ",  rowSelectedMemberData  )*/

    const {

        setSidebarTitle,
        sidebarActiveClientID,
        setSidebarActiveClientID,
        setSidebarActions
    } = useContext(SidebarContext);

    const {
        graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData,
        openMobileGraphDetails,
        graphLegendStyle,
        setGraphLegendStyle,
        handleUpdateGraphLegendStyle
    } = useContext(GraphDetailsContext);

    const {
        projectId, setProjectId,
        familyReport, setFamilyReport,
        dependentReport, setDependentReport,
        alertReport, setAlertReport,
        ineligibleReport, setIneligibleReport,
        responseRateData, setResponseRateData,
        employeeStatusData, setEmployeeStatusData,
        dependentStatusData, setDependentStatusData,
        ineligibleStatusData, setIneligibleStatusData,
        incompleteStatusData, setIncompleteStatusData,
        incompleteReport, setIncompleteReport,
        finalIneligibleList, setFinalIneligibleList,
        alertNoteCount, setAlertNoteCount,
        firstIneligibleList, setFirstIneligibleList,
        secIneligibleList, setSecIneligibleList,
        extendIneligibleGraphContainer, setExtendIneligibleGraphContainer,
        hideIneligibleChart, setHideIneligibleChart,
        showIneligibleChart, setShowIneligibleChart,
        memberReport, setMemberReport,
        memberStatusData, setMemberStatusData,
        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates, callFilter, setCallFilter,
        unfilteredFamilyReport, setUnfilteredFamilyReport,
        unfilteredDependentReport, setUnfilteredDependentReport,
        unfilteredAlertReport, setUnfilteredAlertReport,
        unfilteredIneligibleReport, setUnfilteredIneligibleReport,
        unfilteredMemberReport, setUnfilteredMemberReport,
        unfilteredResponseRateData, setUnfilteredResponseRateData,
        unfilteredEmployeeStatusData, setUnfilteredEmployeeStatusData,
        unfilteredDependentStatusData, setUnfilteredDependentStatusData,
        unfilteredIneligibleStatusData, setUnfilteredIneligibleStatusData,
        unfilteredIncompleteStatusData, setUnfilteredIncompleteStatusData,
        unfilteredMemberStatusData, setUnfilteredMemberStatusData,
        unfilteredIncompleteReport, setUnfilteredIncompleteReport,
        unfilteredAlertNoteCount, setUnfilteredAlertNoteCount
    } = useContext(ProjectDashboardContext);

    const {

        renderedFamilyReport, setRenderedFamilyReport,
        renderedDependentReport, setRenderedDependentReport,
        renderedAlertReport, setRenderedAlertReport,
        renderedIneligibleReport, setRenderedIneligibleReport,
        renderedMemberReport, setRenderedMemberReport,
        renderedResponseRateData, setRenderedResponseRateData,
        renderedEmployeeStatusData, setRenderedEmployeeStatusData,
        renderedDependentStatusData, setRenderedDependentStatusData,
        renderedIneligibleStatusData, setRenderedIneligibleStatusData,
        renderedIncompleteStatusData, setRenderedIncompleteStatusData,
        renderedMemberStatusData, setRenderedMemberStatusData,
        renderedIncompleteReport, setRenderedIncompleteReport,
        renderedFinalIneligibleList, setRenderedFinalIneligibleList,
        showRenderedIneligibleChart, setShowRenderedIneligibleChart,
        extendRenderedIneligibleGraphContainer, setExtendRenderedIneligibleGraphContainer,
        renderedAlertNoteCount, setRenderedAlertNoteCount,
        renderedFirstIneligibleList, setRenderedFirstIneligibleList,
        renderedSecIneligibleList, setRenderedSecIneligibleList,
        hideRenderedIneligibleChart, setRenderedHideIneligibleChart,
        filteredAuditDates, setFilteredAuditDates,
        familyCardData, setFamilyCardData,
        depCardData, setDepCardData,
        memberCardData, setMemberCardData,
        responseCardData, setResponseCardData,
        ineligibleCardData, setIneligibleCardData,
        incompleteCardData, setIncompleteCardData,


    } = useContext(RenderedProjectDashboardContext);



    const [hasGraphContextData, setHasGraphContextData] = useState(false)

    const [graphContextData, setGraphContextData] = useState(graphComponentData?.length !== 0 ? graphComponentData : [])

    const [hasGraphContextTableData, setHasGraphContextTableData] = useState(false)

    const [graphContextTableData, setGraphContextTableData] = useState(graphTableComponentData?.length !== 0 ? graphTableComponentData : [])

    const [graphLegendDetailsStyle, setGraphLegendDetailsStyle] = useState(graphLegendStyle !== null ? graphLegendStyle : null)

    //const { projectId, setProjectId } = useContext(ProjectDashboardContext);

    console.log("graphComponentData - ", graphComponentData);

    function handleUpdateGraphContextData(update) {
        setGraphContextData(update)
    }

    const { id, auditName, auditFamilyId, employeeId } = useParams();
    const { instance, accounts, inProgress } = useMsal();

    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);

    //hook to fetch report details, graph details if context is empty
    const fetchGraphDetails = useFetchGraphDetails();

    //client
   
    const [users, setUsers] = useState([]);
    //const users = useRef({});
    const [haveUserData, setHaveUserData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [status, setStatus] = useState(false);
   
    const { newRoutes, setRoutes } = useContext(RoutesContext);
    //const [fetchNewData, setFetchNewData] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const filterFamGraphHook = useFilterGraph();
    const filterDepGraphHook = useFilterGraph();
    const filterIneligibleGraphHook = useFilterGraph();
    const filterIncompleteGraphHook = useFilterGraph();
    const filterMemberGraphHook = useFilterGraph();

    //GRAPH NAME FOR SWITCH CASE

    const { state } = useLocation();

    let graphName = state?.graph_title;

    useEffect(() => {

        if (id !== sidebarActiveClientID) {
            setIsLoading(false);
        }

    }, [id, auditName]);

    useEffect(() => {

        setSidebarTitle(`${auditName}`)
        setSidebarActiveClientID(`${id}`)
    }, [auditName, id])

    useEffect(() => {
        if (isLoading === false && id !== null && id !== undefined && projectId !== id) {
            //setProjectId(id);
            //setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            fetchGraphDetails.handleFetchAll(id);
           
            //console.log("state after refetch ", state?.graph_title, " - ", state?.graph_component, " - ")
            setIsLoading(true);
          

        }

    }, [id]);

    useEffect(() => {
        console.log("graphName - ", graphName);

    }, []);

    useEffect(() => {

        if (fetchGraphDetails.promiseIsLoading !== null && fetchGraphDetails.promiseIsLoading !== undefined && graphName !== null && graphName !== undefined && isLoading === true) {
            console.log("hit graphName switch - ", graphName);
            switch (graphName) {
          
                case "Alert Report":
                    //filterFamGraphHook?.handle
                    //set alert table data
                    setGraphContextTableData(unfilteredAlertReport);
                    break;
                case "Family Status":
                    if (unfilteredFamilyReport !== null && unfilteredFamilyReport !== undefined) {
                        filterFamGraphHook.handleFamilyGraphFiltering(unfilteredFamilyReport);
                    }       
                    break;
                case "Dependent Status":
                    if (unfilteredDependentReport !== null && unfilteredDependentReport !== undefined) {
                        filterDepGraphHook.handleDependentGraphFiltering(unfilteredDependentReport);
                    }
                    break;
                case "Incomplete Reason":
                    if (unfilteredIncompleteReport !== null && unfilteredIncompleteReport !== undefined) {
                        filterIncompleteGraphHook.handleIncompleteGraphFiltering(unfilteredIncompleteReport);

                    }
               
                    break;
                case "Ineligible Reason":
                    if (unfilteredIneligibleReport !== null && unfilteredIneligibleReport !== undefined) {
                        filterIneligibleGraphHook.handleIneligibleGraphFiltering(unfilteredIneligibleReport);
                    }
                    break;
                //case "Employee / Member Status":
                //    if (unfilteredMemberReport !== null && unfilteredMemberReport !== undefined) {
                //        filterMemberGraphHook.handleMemberGraphFiltering(unfilteredMemberReport);
                //    }
                    
                //    break;
                default:
                    break;
            }
        }

    }, [fetchGraphDetails.promiseIsLoading]);

    /* set recalculated graphs to render */
    useEffect(() => {
       //console.log("filtered fam graph data - ", filterFamGraphHook.filteredGraphData, filterFamGraphHook.filteredGraphCardData);
        //console.log("filtered fam graphd data legend variable - ", filterFamGraphHook.filteredGraphCardData[2]?.ESCompleteTotal);
        /*if (filterFamGraphHook.filteredGraphCardData !== null && filterFamGraphHook.filteredGraphCardData !== undefined && filterFamGraphHook.filteredGraphData !== null && filterFamGraphHook.filterGraphData !== undefined) {*/
        //console.log("filtered fam graphd data legend variable set called - ", filterFamGraphHook.filteredGraphCardData[2]?.ESCompleteTotal);
        if (filterFamGraphHook.filteredGraphData !== null && filterFamGraphHook.filteredGraphData !== undefined && filterFamGraphHook.filteredGraphData[0]?.employeeStatusGrandTotal > 0) {
            console.log("filteredGraphData - ", filterFamGraphHook.filteredGraphData, filterFamGraphHook.filteredGraphCardData);
            console.log("filteredGraphData data - ", filterFamGraphHook.filteredGraphCardData[0]);
            setGraphContextData(filterFamGraphHook.filteredGraphCardData[0]);
            handleUpdateGraphComponentData(filterFamGraphHook.filteredGraphCardData[0]);
            setGraphContextTableData(unfilteredFamilyReport);
            //setRenderedEmployeeStatusData(filterFamGraphHook.filteredGraphData[0]);
            //setRenderedResponseRateData(filterFamGraphHook.filteredGraphData[1]);
            //setFamilyCardData(filterFamGraphHook.filteredGraphCardData);
            //setResponseCardData(filterFamGraphHook.filteredGraphCardData);
        }
      

        //}

    }, [filterFamGraphHook.filteredGraphData]);

    useEffect(() => {
        if (filterDepGraphHook?.filteredGraphData !== null && filterDepGraphHook?.filteredGraphData !== undefined && filterDepGraphHook.filteredGraphData[0]?.dependentStatusGrandTotal > 0) {
            //console.log("filtered dep graph data - ", filterDepGraphHook.filteredGraphData);
            //setRenderedDependentStatusData(filterDepGraphHook.filteredGraphData[0]);
            //setDepCardData(filterDepGraphHook.filteredGraphCardData[0])
            setGraphContextData(filterDepGraphHook.filteredGraphCardData[0]);
            handleUpdateGraphComponentData(filterDepGraphHook.filteredGraphCardData[0]);
            setGraphContextTableData(unfilteredDependentReport);
        }

    }, [filterDepGraphHook.filteredGraphData]);

    useEffect(() => {

        //console.log("filtered ineligible card graph data temp - ", filterIneligibleGraphHook.tempArr);

        if (filterIneligibleGraphHook.filteredGraphData !== null && filterIneligibleGraphHook.filteredGraphData !== undefined && filterIneligibleGraphHook.filteredGraphData[0]?.total > 0) {
            console.log("filtered ineligible graph data - ", filterIneligibleGraphHook.filteredGraphData);
            console.log("filtered ineligible card graph data - ", filterIneligibleGraphHook.filteredGraphCardData);
            setGraphContextData(filterIneligibleGraphHook.filteredGraphCardData);
            setGraphLegendDetailsStyle("Ineligible Reason X");
            handleUpdateGraphComponentData(filterIneligibleGraphHook.filteredGraphCardData);
            setGraphContextTableData(unfilteredIneligibleReport);
        }

        //if (filterIneligibleGraphHook?.filteredGraphData[0]?.total > 0) {
        //    //console.log("filtered ineligible card data --- ", filterIneligibleGraphHook.filteredGraphCardData[0])
        //    setRenderedFinalIneligibleList(filterIneligibleGraphHook.filteredGraphCardData);
        //    setIneligibleCardData(filterIneligibleGraphHook.filteredGraphCardData);

        //    setRenderedSecIneligibleList([])
        //    setRenderedFirstIneligibleList([])
        //    if (filterIneligibleGraphHook.filteredGraphCardData.length > 8) {
        //        setExtendIneligibleGraphContainer(true);
        //        for (let i = 0; i < filterIneligibleGraphHook.filteredGraphCardData.length; i++) {
        //            if (i <= 7) {
        //                setRenderedFirstIneligibleList(renderedFirstIneligibleList => [...renderedFirstIneligibleList, filterIneligibleGraphHook.filteredGraphCardData[i]])
        //            }

        //            if (i > 7) {
        //                setRenderedSecIneligibleList(renderedSecIneligibleList => [...renderedSecIneligibleList, filterIneligibleGraphHook.filteredGraphCardData[i]])
        //            }

        //        }
        //    }

        //    if (filterIneligibleGraphHook.filteredGraphCardData.length === 0) {
        //        setHideIneligibleChart(true);
        //    }


        //} else {
        //    setRenderedFinalIneligibleList([]);
        //    setIneligibleCardData([]);
        //    //filterIneligibleGraphHook.setTempArr([]); 
        //}
        ////filterIneligibleGraphHook.setTempArr([]); //reset temp arr to avoid duplicates when calculating ineligible graph hook


        ////setShowIneligibleChart(true);


        //setRenderedIneligibleStatusData(filterIneligibleGraphHook.filteredGraphData[0]);
        //setRenderedFinalIneligibleList(filterIneligibleGraphHook.filteredGraphCardData);

    }, [filterIneligibleGraphHook.filteredGraphData]);


    useEffect(() => {
        //console.log("filtered incomplete graph data - ", filterIncompleteGraphHook.filteredGraphData);
        if (filterIncompleteGraphHook.filteredGraphData !== null && filterIncompleteGraphHook.filteredGraphData !== undefined && filterIncompleteGraphHook.filteredGraphData[0]?.total > 0) {
            setGraphContextData(filterIncompleteGraphHook.filteredGraphCardData[0]);
            handleUpdateGraphComponentData(filterIncompleteGraphHook.filteredGraphCardData[0]);
            setGraphContextTableData(unfilteredIncompleteReport);
        }

    }, [filterIncompleteGraphHook.filteredGraphData]);

    //useEffect(() => {
    //    //console.log("filtered member graph data - ", filterMemberGraphHook.filteredGraphData);
    //    if (filterMemberGraphHook.filteredGraphData !== null && filterMemberGraphHook.filteredGraphData !== undefined && filterMemberGraphHook.filteredGraphData[0]?.memberStatusGrandTotal > 0) {
    //        //setRenderedMemberStatusData(filterMemberGraphHook.filteredGraphData[0]);
    //        //setMemberCardData(filterMemberGraphHook.filteredGraphData[0]);

    //        setGraphContextData(filterMemberGraphHook.filteredGraphCardData[0]);
    //        handleUpdateGraphComponentData(filterMemberGraphHook.filteredGraphCardData[0]);
    //        setGraphContextTableData(unfilteredMemberReport);
        
    //    }
      
    //}, [filterMemberGraphHook.filteredGraphData]);


    //toggle visible modal for adding user when on 'add user' button click
    const toggleVisible = () => {
        setVisible(!visible)
    }

    const toggleVisible1 = () => {
        setVisible1(!visible1)
    }

    const toggleVisible2 = () => {
        setVisible2(!visible2)
    }

    const toggleLoading = () => {
        setLoading(!loading)
    }

    useEffect(() => {

        if (isLoading === false) {
            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)

            setIsLoading(true);

        }

    }, [isLoading]);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: true
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: false,
            style: '',
            isNested: true
        },
        //{
        //    id: 3,
        //    label: "Processing",
        //    path: "processing",
        //    icon: <AssignmentTurnedInIcon />,
        //    isActive: false,
        //    style: '',
        //    isNested: false
        //}
    ]);

    //const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);
    const [validRoute, setValidRoute] = useState(null);
    const [reports, setReports] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {

        //permissions?.map((audit) => {
        //    if (audit?.auditId === parseInt(id)) {
        //        setValidRoute(true)
        //    }
        //    setCount(count + 1);
        //}

        //});

        if ((roles === 'CLIENT') && (validRoute === null) && (count === permissions?.length)) {
            setValidRoute(false);
        }
        if ((roles === "SERVICES")) {
            navigate('/processing');
        }

        //console.log("valid Route 1 --- ", validRoute)
        //if ((roles === "CLIENT") && (validRoute === false || validRoute == null)) {

        //    navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
        //    setIsLoading(false);

        //}

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length <= 1)) {
            setRoutes([
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                //{
                //    id: 3,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: false,
                //    style: '',
                //    isNested: false
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length > 1)) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                //{
                //    id: 2,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: false,
                //    style: '',
                //    isNested: true
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles == "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 4,
                    label: "Project Setup",
                    path: `report/${String(id)}/${auditName}/setup`,
                    icon: <HiWrenchScrewdriver className="largerIcon" />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 5,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 6,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }


    }, [id, auditName, roles]);
   
    useEffect(() => {
        let checkPerms = false;
        permissions?.map((audit) => {
            if (audit?.auditId === parseInt(id)) {
                setValidRoute(true)
                checkPerms = true;
               
            }
            setCount(count + 1);
        });
        console.log("count -- ", count)
        console.log("permissions -- ", permissions)
        //if ((validRoute === null || validRoute === false) && (count === permissions?.length)) {
        //    setValidRoute(false)
        //}
        if (checkPerms === false) {
            setValidRoute(false);
        }
        console.log("current id --- ", id);


    }, []);


    useEffect(() => {

        if ((roles === "SERVICES")) {
            navigate('/processing');
        }
        console.log("permissions - ", permissions);
        console.log("valid Route 2 --- ", validRoute)
        if ((roles === "CLIENT") && (validRoute === false)) {

            navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            //setIsLoading(false);

        }


    }, [validRoute, setValidRoute, roles]);



    //useEffect(() => {
    //    if (accounts?.length > 0) { //if account exists authorize user
    //        const fetchUserData = async () => {
    //            await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
    //                .then(result => {
    //                    const fetchData = async () => {
    //                        await fetch(process.env.REACT_APP_BACK_URL + '/api/userMgmt/GetUsers', {
    //                            method: 'POST',
    //                            mode: 'cors',
    //                            headers: {
    //                                'Content-Type': 'application/json',
    //                                'Authorization': 'Bearer ' + result.idToken,
    //                            },
    //                            body: JSON.stringify({ "auditId": id })
    //                        })
    //                            .then(res => res.json())
    //                            .then((response) => {
    //                                console.log(response)
    //                                setHaveUserData(true);
    //                                setUsers(response?.b2CUsers);

    //                                //users.current = [...response];
    //                            }).catch((error) => {
    //                                console.log(error)
    //                            })


    //                    }

    //                    fetchData();

    //                }).catch((error) => {
    //                    console.log(error) //token expired/is not valid => user is redirected to login page
    //                    if (error instanceof InteractionRequiredAuthError) {
    //                        instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
    //                    }

    //                });

    //        }
    //        fetchUserData();
    //    }

    //}, [haveUserData, setHaveUserData])

   

    function renderDate(date) {
        if (!date) {
            return '';
        }
        return format(new Date(date), 'MM/dd/yyyy');
    }


       



    const handleNavigateBackToProject = (params) => {

        /* console.log(params);*/


        navigate(`/report/${String(id)}/${auditName}`);
        

    };

    let detailGraphComponent = null;
    let detailGraphTableComponent = null;
    let detailGraphComponentMobile = null;
    let detailGraphComponentMobile2 = null;
    let detailGraphTableComponentMobile = null;
   /* let detailGraphTableInputComponents = null;*/

 

    if (graphName == 'Family Status' && graphContextData !== null && graphContextTableData !== null) {

        let altTableData = [];
        let altTableId = 0;

        const mapData = graphContextTableData.map((item) => {
            let newEntry = { ...item, altRowId: altTableId++, }
            altTableData = [...altTableData, newEntry];
            return altTableData;
        })


        /* detailGraphTableInputComponents = {};*/
        detailGraphComponent = <GraphCardWide
            graphLink={null}
            graphName={null}
            desiredCardWidth={1000}
            desiredCardHeight={60}
            graphData={graphContextData}
            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueA={"Complete"}
                    graphLabelValueB={"No Response"}
                    graphLabelValueC={"Incomplete"}
                    metricSubTitle={'Families'}
                    graphTitle={`Employee Status Graph - ${graphContextData[0]?.metricValue} Complete employees out of ${graphContextData[0]?.metricValue + graphContextData[1]?.metricValue + graphContextData[2]?.metricValue} Total Employees`}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                            {
                                metricTitle: "Complete",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "No Response",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Incomplete",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;
        detailGraphTableComponent = <GraphDetailsTable
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Family Status'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
              
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 250,
                    "editable": false
                },
              
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },
               

            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Family Status'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
              
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 250,
                    "editable": false
                },
              
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 350,
                    "editable": false
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },
               

            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;
        detailGraphComponentMobile = <GraphCardWideMobile
            graphLink={null}
            graphName={null}
            desiredCardWidth={360}
            desiredCardHeight={170}
            graphData={graphContextData}
            graphLegendStyle={'Family Status'}
            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueA={"Complete"}
                    graphLabelValueB={"No Response"}
                    graphLabelValueC={"Incomplete"}
                    metricSubTitle={'Families'}
                    graphTitle={`Employee Status Graph - ${graphContextData[0]?.metricValue} Complete employees out of ${graphContextData[0]?.metricValue + graphContextData[1]?.metricValue + graphContextData[2]?.metricValue} Total Employees`}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                            {
                                metricTitle: "Complete",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "No Response",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Incomplete",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;



    } else if (graphName == 'Incomplete Reason' && graphContextData !== null && graphContextTableData !== null) {

        let altTableData = [];
        let altTableId = 0;

        const mapData = graphContextTableData.map((item) => {
            let newEntry = { ...item, altRowId: altTableId++, }
            altTableData = [...altTableData, newEntry];
            return altTableData;
        })

        detailGraphComponent = <GraphCardWide
            graphLink={null}
            graphName={null}
            desiredCardWidth={1000}
            desiredCardHeight={60}
            graphData={graphContextData}
            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueB={"Missing Document(s)"}
                    graphLabelValueA={"Invalid Documents"}
                    graphLabelValueC={"Document Not Legible"}
                    metricSubTitle={"Incomplete"}
                    graphTitle={"Incomplete Status Graph"}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                          
                            {
                                metricTitle: "Invalid Documents",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "Missing Document(s)",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Document Not Legible",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;
        detailGraphTableComponent = <GraphDetailsTable
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Incomplete Reason'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "incompleteNote",
                    "headerName": "Incomplete Note",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 220,
                    "editable": false
                },
              
               

               
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },


                
               
      
                {
                    "field": "auditFinding1",
                    "headerName": "Audit Finding 1",
                    "width": 220,
                    "height": "5VH",
                    "editable": false,
                   
                },
                {
                    "field": "auditFinding2",
                    "headerName": "Audit Finding 2",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding3",
                    "headerName": "Audit Finding 3",
                    "width": 220,
                    "editable": false

                },
                {
                    "field": "auditFinding4",
                    "headerName": "Audit Finding 4",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding5",
                    "headerName": "Audit Finding 5",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding6",
                    "headerName": "Audit Finding 6",
                    "width": 220,
                    "editable": false
                },
               
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },





            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Incomplete Reason'}

            tableColumns={
                [
                    {
                        "field": "altRowId",
                        "headerName": "ROWID",
                        "width": 150,
                        "editable": false
                    },
                    {
                        "field": "auditId",
                        "headerName": "Audit ID",
                        "width": 150,
                        "editable": false
                    },
                    {
                        "field": "auditEndDate",
                        "headerName": "Audit End Date",
                        "width": 180
                    },
                    {
                        "field": "employeeLastName",
                        "headerName": "Member/Employee Last Name",
                        "width": 250,
                        "editable": false
                    },
                    {
                        "field": "employeeFirstName",
                        "headerName": "Member/Employee First Name",
                        "width": 250,
                        "editable": false
                    },

                  

                   
                    {
                        "field": "dependentLastName",
                        "headerName": "Dependent Last Name",
                        "width": 250,
                        "editable": false
                    },

                    {
                        "field": "dependentFirstName",
                        "headerName": "Dependent First Name",
                        "width": 250,
                        "editable": false
                    },
                    {
                        "field": "dependentStatus",
                        "headerName": "Dependent Status",
                        "width": 220,
                        "editable": false
                    },
                    {
                        "field": "reason",
                        "headerName": "Reason",
                        "width": 220,
                        "editable": false
                    },
                    {
                        "field": "incompleteNote",
                        "headerName": "Incomplete Note",
                        "width": 220,
                        "editable": false
                    },
                    {
                        "field": "dependentBirthDate",
                        "headerName": "Dependent Birth Date",
                        "width": 220,
                        "editable": false
                    },

                    {
                        "field": "dependentRelationship",
                        "headerName": "Dependent Relationship",
                        "width": 220,
                        "editable": false
                    },

                    {
                        "field": "dependentRelationshipType",
                        "headerName": "Dependent Relationship Type",
                        "width": 250,
                        "editable": false
                    },
                 


                    {
                        "field": "location",
                        "headerName": "Location",
                        "width": 250,
                        "editable": false
                    },

                    {
                        "field": "auditFinding1",
                        "headerName": "Audit Finding 1",
                        "width": 220,
                        "editable": false
                    },
                    {
                        "field": "auditFinding2",
                        "headerName": "Audit Finding 2",
                        "width": 220,
                        "editable": false
                    },
                    {
                        "field": "auditFinding3",
                        "headerName": "Audit Finding 3",
                        "width": 220,
                        "editable": false

                    },
                    {
                        "field": "auditFinding4",
                        "headerName": "Audit Finding 4",
                        "width": 220,
                        "editable": false
                    },
                    {
                        "field": "auditFinding5",
                        "headerName": "Audit Finding 5",
                        "width": 220,
                        "editable": false
                    },
                    {
                        "field": "auditFinding6",
                        "headerName": "Audit Finding 6",
                        "width": 220,
                        "editable": false
                    },
          
                    {
                        "field": "phone",
                        "headerName": "Phone",
                        "width": 150,
                        "editable": false
                    },
                    {
                        "field": "email",
                        "headerName": "Email",
                        "width": 250,
                        "editable": false
                    },





                ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;
        detailGraphComponentMobile = <GraphCardWideMobile
            graphLink={null}
            graphName={null}
            desiredCardWidth={360}
            desiredCardHeight={170}
            graphData={graphContextData}
            graphLegendStyle={'Incomplete Reason'}

            graphComponent={

                <PieGraph
                    graphValueA={graphContextData[0]?.metricValue}
                    graphValueB={graphContextData[1]?.metricValue}
                    graphValueC={graphContextData[2]?.metricValue}
                    graphLabelValueB={"Missing Document(s)"}
                    graphLabelValueA={"Invalid Documents"}
                    graphLabelValueC={"Document Not Legible"}
                    metricSubTitle={"Incomplete"}
                    graphTitle={"Incomplete Status Graph"}
                />
            }
            graphLegendComponent={
                <GraphLegendCard
                    graphLegendData={
                        [
                           
                            {
                                metricTitle: "Invalid Documents",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "Missing Document(s)",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Document Not Legible",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },
                        ]
                    }
                />

            }
        />;

    } else if (graphName == 'Dependent Status' && graphContextData !== null && graphContextTableData !== null) {

        let altTableData = [];
        let altTableId = 0;

        const mapData = graphContextTableData.map((item) => {
            let newEntry = { ...item, altRowId: altTableId++, }
            altTableData = [...altTableData, newEntry];
            return altTableData;
        })


        detailGraphTableComponent = <GraphDetailsTable
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Dependent Status'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 220,
                    "editable": false
                },

               
               
               
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "status",
                    "headerName": "Dependent Status",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 180
                },
                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "relationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },


               
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },





            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Dependent Status'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 250,
                    "editable": false
                },

              
               
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 250,
                    "editable": false
                },

                {
                    "field": "status",
                    "headerName": "Dependent Status",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 280
                },
                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "relationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 240,
                    "editable": false
                },




               

                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },





            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;
        detailGraphComponent = <GraphCardWideBarChart
            graphLink={null}
            graphName={null}
            desiredCardWidth={1000}
            desiredCardHeight={60}

            graphData={graphContextData}

            graphLegendComponent={
                <PercentageBarChart
                    percentageBarChartData={
                        [
                            {
                                metricTitle: "No Response",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "Incomplete",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Review",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },


                        ]
                    }
                />
            }
            graphLegendComponent2={
                <PercentageBarChart
                    percentageBarChartData={
                        [

                            {
                                metricTitle: "Passed",
                                metricValue: graphContextData[3]?.metricValue,
                                metricPercentage: graphContextData[3]?.metricPercentage,
                            },
                            {
                                metricTitle: "Suspend",
                                metricValue: graphContextData[4]?.metricValue,
                                metricPercentage: graphContextData[4]?.metricPercentage,
                            },
                            {
                                metricTitle: "Ineligible",
                                metricValue: graphContextData[5]?.metricValue,
                                metricPercentage: graphContextData[5]?.metricPercentage,
                            },

                        ]
                    }
                />
            }


        />;
        detailGraphComponentMobile = <GraphCardWideBarChartMobile
            graphLink={null}
            graphName={null}
            desiredCardWidth={360}
            desiredCardHeight={150}
            graphData={graphContextData}
            graphLegendStyle={'Dependent Status'}

            graphLegendComponent={
                <PercentageBarChart
                    percentageBarChartData={
                        [
                            {
                                metricTitle: "No Response",
                                metricValue: graphContextData[0]?.metricValue,
                                metricPercentage: graphContextData[0]?.metricPercentage,
                            },
                            {
                                metricTitle: "Incomplete",
                                metricValue: graphContextData[1]?.metricValue,
                                metricPercentage: graphContextData[1]?.metricPercentage,
                            },
                            {
                                metricTitle: "Review",
                                metricValue: graphContextData[2]?.metricValue,
                                metricPercentage: graphContextData[2]?.metricPercentage,
                            },


                        ]
                    }
                />
            }
            graphLegendComponent2={
                <PercentageBarChart
                    percentageBarChartData={
                        [

                            {
                                metricTitle: "Passed",
                                metricValue: graphContextData[3]?.metricValue,
                                metricPercentage: graphContextData[3]?.metricPercentage,
                            },
                            {
                                metricTitle: "Suspend",
                                metricValue: graphContextData[4]?.metricValue,
                                metricPercentage: graphContextData[4]?.metricPercentage,
                            },
                            {
                                metricTitle: "Ineligible",
                                metricValue: graphContextData[5]?.metricValue,
                                metricPercentage: graphContextData[5]?.metricPercentage,
                            },

                        ]
                    }
                />
            }


        />
    } else if (graphName == 'Ineligible Reason' && graphLegendDetailsStyle == 'Ineligible Reason X' && graphContextData !== null && graphContextTableData !== null) {

        let altTableData = [];
        let altTableId = 0;

        const mapData = graphContextTableData.map((item) => {
            let newEntry = { ...item, altRowId: altTableId++, }
            altTableData = [...altTableData, newEntry];
            return altTableData;
        })


        detailGraphTableComponent = <GraphDetailsTable
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Ineligible Reason'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180,
                    renderCell: (params) =>
                        renderDate(params.row?.auditEndDate)

                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 220,
                    "editable": false
                },
             
               

              
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },


              
              
               
                {
                    "field": "auditFinding1",
                    "headerName": "Audit Finding 1",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding2",
                    "headerName": "Audit Finding 2",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding3",
                    "headerName": "Audit Finding 3",
                    "width": 220,
                    "editable": false

                },
                {
                    "field": "auditFinding4",
                    "headerName": "Audit Finding 4",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding5",
                    "headerName": "Audit Finding 5",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding6",
                    "headerName": "Audit Finding 6",
                    "width": 220,
                    "editable": false
                },
  
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },





            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphComponent = <GraphCardWideIneligibleBarChart
            graphLink={null}
            graphName={null}
            desiredCardWidth={400 * (graphContextData.length / 3)}
            desiredCardHeight={150}
            graphData={graphContextData}
            graphLegendStyle={graphLegendDetailsStyle}
            graphLegendComponent={
                <IneligibleReasonBarChart
                    percentageBarChartData={ //needs to be dynamic list
                        graphContextData
                    }
                />
            }
            graphLegendComponent2={
                null
            }


        />;

        detailGraphComponentMobile = <GraphCard
            graphLink={null}
            graphName={null}
            desiredCardWidth={"82VW"}
            desiredCardHeight={100 * (graphContextData.length / 3)}
            graphData={graphContextData}
            graphLegendStyle={graphLegendDetailsStyle}
            graphLegendComponent={
                <IneligibleReasonBarChart
                    percentageBarChartData={
                        graphContextData
                        //needs to be dynamic list     
                    }
                />
            }
            graphLegendComponent2={
                null
            }


        />;
        //detailGraphComponentMobile = <GraphCardWideBarChartMobile
        //    graphLink={null}
        //    graphName={null}
        //    desiredCardWidth={360}
        //    desiredCardHeight={150}
        //    graphData={graphContextData}
        //    graphLegendStyle={graphLegendDetailsStyle}
        //    graphLegendComponent={
        //        <IneligibleReasonBarChart
        //            percentageBarChartData={[ //needs to be dynamic list
        //                {
        //                    metricTitle: graphContextData[0]?.metricTitle,
        //                    metricValue: graphContextData[0]?.metricValue,
        //                    metricPercentage: graphContextData[0]?.metricPercentage,
        //                },
        //                {
        //                    metricTitle: graphContextData[1]?.metricTitle,
        //                    metricValue: graphContextData[1]?.metricValue,
        //                    metricPercentage: graphContextData[1]?.metricPercentage,
        //                },
        //                {
        //                    metricTitle: graphContextData[2]?.metricTitle,
        //                    metricValue: graphContextData[2]?.metricValue,
        //                    metricPercentage: graphContextData[2]?.metricPercentage,
        //                },
        //            ]}
        //        />
        //    }
        //    graphLegendComponent2={
        //        null
        //    }


        ///>;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Ineligible Reason'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 250,
                    "editable": false
                },
               
               

              
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 350,
                    "editable": false
                },


               
               
             
                {
                    "field": "auditFinding1",
                    "headerName": "Audit Finding 1",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding2",
                    "headerName": "Audit Finding 2",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding3",
                    "headerName": "Audit Finding 3",
                    "width": 220,
                    "editable": false

                },
                {
                    "field": "auditFinding4",
                    "headerName": "Audit Finding 4",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding5",
                    "headerName": "Audit Finding 5",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding6",
                    "headerName": "Audit Finding 6",
                    "width": 220,
                    "editable": false
                },
    
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },




            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;


    } else if (graphName == 'Ineligible Reason' && graphLegendDetailsStyle == 'Ineligible Reason XY' && graphContextData !== null && graphContextTableData !== null) {

        //let altTableData = [];
        //let altTableId = 0;

        //const mapData = graphContextData.map((item) => {
        //    let newEntry = { ...item, alertRowId: altTableId++, }
        //    altTableData = [...altTableData, newEntry];
        //    return altTableData;
        //})

        let altTableData = [];
        let altTableId = 0;

        const mapData = graphContextTableData.map((item) => {
            let newEntry = { ...item, altRowId: altTableId++, }
            altTableData = [...altTableData, newEntry];
            return altTableData;
        })


        detailGraphTableComponent = <GraphDetailsTable
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Ineligible Reason'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 220,
                    "editable": false
                },

              
              


               
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
               
                {
                    "field": "auditFinding1",
                    "headerName": "Audit Finding 1",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding2",
                    "headerName": "Audit Finding 2",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding3",
                    "headerName": "Audit Finding 3",
                    "width": 220,
                    "editable": false

                },
                {
                    "field": "auditFinding4",
                    "headerName": "Audit Finding 4",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding5",
                    "headerName": "Audit Finding 5",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding6",
                    "headerName": "Audit Finding 6",
                    "width": 220,
                    "editable": false
                },
          
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },





            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphComponent = <GraphCardWideIneligibleBarChart
            graphLink={null}
            graphName={null}
            desiredCardWidth={400 * (graphContextData.length / 4)}
            desiredCardHeight={150}
            graphData={graphContextData}
            graphLegendStyle={graphLegendDetailsStyle}
            graphLegendComponent={
                <IneligibleReasonBarChart
                    percentageBarChartData={graphContextData}
                />
            }
            graphLegendComponent2={
                <IneligibleReasonBarChart
                    percentageBarChartData={graphContextData}
                />
            }


        />;
        detailGraphComponentMobile = <GraphCard
            graphLink={null}
            graphName={null}
            desiredCardWidth={"82VW"}
            desiredCardHeight={400}
            graphData={graphContextData}
            graphLegendStyle={graphLegendDetailsStyle}
            graphLegendComponent={
                <IneligibleReasonBarChart
                    percentageBarChartData={
                        graphContextData
                        //needs to be dynamic list     
                    }
                />
            }
            graphLegendComponent2={
                null
            }


        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Ineligible Reason'}
            tableColumns={[
                {
                    "field": "altRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 240,
                    "editable": false
                },

              
               


                
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentStatus",
                    "headerName": "Dependent Status",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "reason",
                    "headerName": "Reason",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 240,
                    "editable": false
                },
         
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "auditFinding1",
                    "headerName": "Audit Finding 1",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding2",
                    "headerName": "Audit Finding 2",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding3",
                    "headerName": "Audit Finding 3",
                    "width": 220,
                    "editable": false

                },
                {
                    "field": "auditFinding4",
                    "headerName": "Audit Finding 4",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding5",
                    "headerName": "Audit Finding 5",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "auditFinding6",
                    "headerName": "Audit Finding 6",
                    "width": 220,
                    "editable": false
                },
         
                {
                    "field": "phone",
                    "headerName": "Phone",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "email",
                    "headerName": "Email",
                    "width": 250,
                    "editable": false
                },




            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;

    } else if (graphName == 'Alert Report' && graphContextData !== null && graphContextTableData !== null) {

        let altTableData = [];
        let altTableId = 0;

        const mapData = graphContextTableData.map((item) => {
            let newEntry = { ...item, alertRowId: altTableId++, }
            altTableData = [...altTableData, newEntry];
            return altTableData;
        })


        detailGraphTableComponent = <GraphDetailsTable
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Alert Report'}
            quickFilterButtons={true}
            tableBadgeDate={renderedAlertReport?.length}
            tableColumns={[
                {
                    "field": "alertRowId",
                    "headerName": "ROWID",
                    "width": 100,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentNoteType",
                    "headerName": "Dependent Note Type",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentAlertNote",
                    "headerName": "Dependent Alert Note",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "familyNoteType",
                    "headerName": "Family Note Type",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "familyAlertNote",
                    "headerName": "Family Alert Note",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "addressPre",
                    "headerName": "Address(pre)",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "address1",
                    "headerName": "Address(1)",
                    "width": 350,
                    "editable": false
                },
                {
                    "field": "address2",
                    "headerName": "Address(2)",
                    "width": 350,
                    "editable": false
                },

                {
                    "field": "city",
                    "headerName": "City",
                    "width": 350,
                    "editable": false
                },
                {
                    "field": "state",
                    "headerName": "State",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "zip",
                    "headerName": "Zip",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "zipExtension",
                    "headerName": "Zip (Ext)",
                    "width": 150,
                    "editable": false
                },

                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
              
               
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "plan",
                    "headerName": "Plan",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 340,
                    "editable": false
                },
               
              


              









            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'86VW'}
            desiredHeight={'46VH'}
        />;
        detailGraphTableComponentMobile = <GraphDetailsTableMobile
            tableData={altTableData.length !== 0 ? (altTableData) : null}
            tableTitle={'Alert Report'}
            tableColumns={[
                {
                    "field": "alertRowId",
                    "headerName": "ROWID",
                    "width": 170,
                    "editable": false
                },
                {
                    "field": "auditId",
                    "headerName": "Audit ID",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "auditEndDate",
                    "headerName": "Audit End Date",
                    "width": 180
                },
                {
                    "field": "employeeLastName",
                    "headerName": "Member/Employee Last Name",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "employeeFirstName",
                    "headerName": "Member/Employee First Name",
                    "width": 250,
                    "editable": false
                },

               
                {
                    "field": "dependentLastName",
                    "headerName": "Dependent Last Name",
                    "width": 250,
                    "editable": false
                },

                {
                    "field": "dependentFirstName",
                    "headerName": "Dependent First Name",
                    "width": 250,
                    "editable": false
                },

                {
                    "field": "dependentNoteType",
                    "headerName": "Dependent Note Type",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "dependentAlertNote",
                    "headerName": "Dependent Alert Note",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "familyNoteType",
                    "headerName": "Family Note Type",
                    "width": 220,
                    "editable": false
                },
                {
                    "field": "familyAlertNote",
                    "headerName": "Family Alert Note",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "addressPre",
                    "headerName": "Address(pre)",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "address1",
                    "headerName": "Address(1)",
                    "width": 350,
                    "editable": false
                },
                {
                    "field": "address2",
                    "headerName": "Address(2)",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "city",
                    "headerName": "City",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "state",
                    "headerName": "State",
                    "width": 250,
                    "editable": false
                },
                {
                    "field": "zip",
                    "headerName": "Zip",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "zipExtension",
                    "headerName": "Zip (Ext)",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "familyStatus",
                    "headerName": "Family Status",
                    "width": 150,
                    "editable": false
                },
                {
                    "field": "dependentBirthDate",
                    "headerName": "Dependent Birth Date",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationship",
                    "headerName": "Dependent Relationship",
                    "width": 220,
                    "editable": false
                },

                {
                    "field": "dependentRelationshipType",
                    "headerName": "Dependent Relationship Type",
                    "width": 240,
                    "editable": false
                },
               


             

               
                {
                    "field": "location",
                    "headerName": "Location",
                    "width": 350,
                    "editable": false
                },
                {
                    "field": "plan",
                    "headerName": "Plan",
                    "width": 250,
                    "editable": false
                },




            ]}
            tableFilterModel={null}
            tableFilterInputs={null}
            desiredWidth={'84VW'}
            desiredHeight={500}
        />;

    }
    //else if (graphName == "Employee / Member Status" && graphContextData !== null && graphContextTableData !== null) {

    //    let altTableData = [];
    //    let altTableId = 0;

    //    const mapData = graphContextTableData.map((item) => {
    //        let newEntry = { ...item, altRowId: altTableId++, }
    //        altTableData = [...altTableData, newEntry];
    //        return altTableData;
    //    })

    //    detailGraphComponent = <GraphCardWideBarChart
    //        graphLink={null}
    //        graphName={null}
    //        desiredCardWidth={1000}
    //        desiredCardHeight={60}

    //        graphData={graphContextData}

    //        graphLegendComponent={
    //            <PercentageBarChart
    //                percentageBarChartData={
    //                    [
    //                        {
    //                            metricTitle: "No Response",
    //                            metricValue: graphContextData[0]?.metricValue,
    //                            metricPercentage: graphContextData[0]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Incomplete",
    //                            metricValue: graphContextData[1]?.metricValue,
    //                            metricPercentage: graphContextData[1]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Review",
    //                            metricValue: graphContextData[2]?.metricValue,
    //                            metricPercentage: graphContextData[2]?.metricPercentage,
    //                        },


    //                    ]
    //                }
    //            />
    //        }
    //        graphLegendComponent2={
    //            <PercentageBarChart
    //                percentageBarChartData={
    //                    [

    //                        {
    //                            metricTitle: "Passed",
    //                            metricValue: graphContextData[3]?.metricValue,
    //                            metricPercentage: graphContextData[3]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Suspend",
    //                            metricValue: graphContextData[4]?.metricValue,
    //                            metricPercentage: graphContextData[4]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Ineligible",
    //                            metricValue: graphContextData[5]?.metricValue,
    //                            metricPercentage: graphContextData[5]?.metricPercentage,
    //                        },

    //                    ]
    //                }
    //            />
    //        }


    //    />;

    //    detailGraphTableComponent = <GraphDetailsTable
    //        tableData={altTableData.length !== 0 ? (altTableData) : null}
    //        tableTitle={"Employee / Member Status"}
    //        tableColumns={[
    //            {
    //                "field": "altRowId",
    //                "headerName": "ROWID",
    //                "width": 100,
    //                "editable": false
    //            },
    //            {
    //                "field": "auditId",
    //                "headerName": "Audit ID",
    //                "width": 100,
    //                "editable": false
    //            },
    //            {
    //                "field": "auditEndDate",
    //                "headerName": "Audit End Date",
    //                "width": 180
    //            },
    //            {
    //                "field": "memberLastName",
    //                "headerName": "Member/Employee Last Name",
    //                "width": 250,
    //                "editable": false
    //            },
    //            {
    //                "field": "memberFirstName",
    //                "headerName": "Member/Employee First Name",
    //                "width": 220,
    //                "editable": false
    //            },

    //            {
    //                "field": "familyStatus",
    //                "headerName": "Family Status",
    //                "width": 150,
    //                "editable": false
    //            },
    //            {
    //                "field": "location",
    //                "headerName": "Location",
    //                "width": 250,
    //                "editable": false
    //            },


    //            {
    //                "field": "phone",
    //                "headerName": "Phone",
    //                "width": 150,
    //                "editable": false
    //            },
    //            {
    //                "field": "email",
    //                "headerName": "Email",
    //                "width": 250,
    //                "editable": false
    //            },
               
    //            {
    //                "field": "birthDate",
    //                "headerName": "Birth Date",
    //                "width": 120,
    //                "editable": false
    //            },

    //            {
    //                "field": "relationship",
    //                "headerName": "Relationship",
    //                "width": 220,
    //                "editable": false
    //            },

    //            {
    //                "field": "relationshipType",
    //                "headerName": "Relationship Type",
    //                "width": 220,
    //                "editable": false
    //            },
    //            {
    //                "field": "status",
    //                "headerName": "Status",
    //                "width": 120,
    //                "editable": false
    //            },
    //            {
    //                "field": "reason",
    //                "headerName": "Reason",
    //                "width": 750,
    //                "editable": false
    //            },
              





    //        ]}
    //        tableFilterModel={null}
    //        tableFilterInputs={null}

    //        desiredWidth={'86VW'}
    //        desiredHeight={'46VH'}
    //    />;

    //    detailGraphComponentMobile = <GraphCardWideBarChartMobile
    //        graphLink={null}
    //        graphName={null}
    //        desiredCardWidth={360}
    //        desiredCardHeight={150}
    //        graphData={graphContextData}
    //        graphLegendStyle={'Dependent Status'}

    //        graphLegendComponent={
    //            <PercentageBarChart
    //                percentageBarChartData={
    //                    [
    //                        {
    //                            metricTitle: "No Response",
    //                            metricValue: graphContextData[0]?.metricValue,
    //                            metricPercentage: graphContextData[0]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Incomplete",
    //                            metricValue: graphContextData[1]?.metricValue,
    //                            metricPercentage: graphContextData[1]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Review",
    //                            metricValue: graphContextData[2]?.metricValue,
    //                            metricPercentage: graphContextData[2]?.metricPercentage,
    //                        },


    //                    ]
    //                }
    //            />
    //        }
    //        graphLegendComponent2={
    //            <PercentageBarChart
    //                percentageBarChartData={
    //                    [

    //                        {
    //                            metricTitle: "Passed",
    //                            metricValue: graphContextData[3]?.metricValue,
    //                            metricPercentage: graphContextData[3]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Suspend",
    //                            metricValue: graphContextData[4]?.metricValue,
    //                            metricPercentage: graphContextData[4]?.metricPercentage,
    //                        },
    //                        {
    //                            metricTitle: "Ineligible",
    //                            metricValue: graphContextData[5]?.metricValue,
    //                            metricPercentage: graphContextData[5]?.metricPercentage,
    //                        },

    //                    ]
    //                }
    //            />
    //        }


    //    />

    //    detailGraphTableComponentMobile = <GraphDetailsTableMobile
    //        tableData={altTableData.length !== 0 ? (altTableData) : null}
    //        tableTitle={"Employee / Member Status"}
    //        tableColumns={[
    //            {
    //                "field": "altRowId",
    //                "headerName": "ROWID",
    //                "width": 100,
    //                "editable": false
    //            },
    //            {
    //                "field": "auditId",
    //                "headerName": "Audit ID",
    //                "width": 150,
    //                "editable": false
    //            },
    //            {
    //                "field": "auditEndDate",
    //                "headerName": "Audit End Date",
    //                "width": 180
    //            },
    //            {
    //                "field": "memberLastName",
    //                "headerName": "Member/Employee Last Name",
    //                "width": 250,
    //                "editable": false
    //            },
    //            {
    //                "field": "memberFirstName",
    //                "headerName": "Member/Employee First Name",
    //                "width": 250,
    //                "editable": false
    //            },

    //            {
    //                "field": "familyStatus",
    //                "headerName": "Family Status",
    //                "width": 150,
    //                "editable": false
    //            },
    //            {
    //                "field": "location",
    //                "headerName": "Location",
    //                "width": 250,
    //                "editable": false
    //            },


    //            {
    //                "field": "phone",
    //                "headerName": "Phone",
    //                "width": 150,
    //                "editable": false
    //            },
    //            {
    //                "field": "email",
    //                "headerName": "Email",
    //                "width": 250,
    //                "editable": false
    //            },

    //            {
    //                "field": "birthDate",
    //                "headerName": "Birth Date",
    //                "width": 220,
    //                "editable": false
    //            },

    //            {
    //                "field": "relationship",
    //                "headerName": "Relationship",
    //                "width": 220,
    //                "editable": false
    //            },

    //            {
    //                "field": "relationshipType",
    //                "headerName": "Relationship Type",
    //                "width": 220,
    //                "editable": false
    //            },
    //            {
    //                "field": "status",
    //                "headerName": "Status",
    //                "width": 120,
    //                "editable": false
    //            },
    //            {
    //                "field": "reason",
    //                "headerName": "Reason",
    //                "width": 750,
    //                "editable": false
    //            },






    //        ]}
    //        tableFilterModel={null}
    //        tableFilterInputs={null}
    //        desiredWidth={'84VW'}
    //        desiredHeight={500}
    //    />;



    //}















    return (
        <>

            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', display: { xs: 'none', md: 'block', xl: 'block' } }} >


                {/*  DESKTOP PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>

                    <div className="row-no-left-margin">
                        <Button variant="text"
                            sx={{ mt: 0, ml: '-0.5VW' }}
                            onClick={() => handleNavigateBackToProject()}
                        > <IoMdArrowRoundBack />Back to Project</Button>
                        <div className="row-left">




                        </div>


                    </div>
                    <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>{graphName == 'Alert Report' ? ('Alert') : (graphName)} Report Details </Typography>
                   {/* <Typography textAlign="left" gutterBottom={true} fontWeight="700" fontSize="1em" sx={{ mt: 0, mb: 1, color: '#737373' }}>Graph Details {mgraphNAME}</Typography>*/}

                   











                </Container >

                <Container sx={{ ml: 0 }}>
                    <Grid container spacing={3} sx={{ mt: '-1VH', mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>


                        <Grid item xs="auto" >
                            {graphComponentData?.length !== 0 ? (<>

                                {detailGraphComponent}

                            </>) : (<>



                            </>)}
                        </Grid>
                    </Grid>
                    {/*<Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>*/}

                    {/*</Grid>*/}
                   
                </Container>
                {graphContextTableData?.length !== 0 ? (<>

                    {detailGraphTableComponent}

                </>) : (<>



                </>)}
             

                {/* DESKTOP TABLE*/}

               

                <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>
                   
                  
                   
                </Container>

               

                <Container sx={{ mt: 1, mb: 0.5, ml: 0, display: { xs: 'none', md: 'block', xl: 'block' } }}>

                   

                </Container>

                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>






            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/*  MOBILE PAGE COMPONENTS */}


                {/*  MOBILE PAGE TITLES/HEADINGS*/}

                {openMobileGraphDetails ? (<></>) : (<>
                    <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                        <div className="row-no-left-margin">
                            <div className="row-left">


                                <Button variant="text"
                                    sx={{ mt: 0, ml: '-1VW' }}
                                    onClick={() => handleNavigateBackToProject()}
                                > <IoMdArrowRoundBack />Back to Project</Button>

                            </div>


                        </div>
                        <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, mb: 3 }}>{graphName == 'Alert Report' ? ('Alert') : (graphName)} Report Details</Typography>

                        <Container sx={{ ml: 0, mt: 2 }}>
                            <Grid container spacing={1} sx={{ mt: '-2VH', mb: 2, display: { xs: 'flex', md: 'none', xl: 'none' } }}>



                                {graphComponentData?.length !== 0 ? (<>
                                    <Grid item xs="auto" sx={{ml: '-2.5VW'}} >
                                        {detailGraphComponentMobile}
                                    </Grid>
                                    {detailGraphComponentMobile2 !== null ? (<>
                                        <Grid item xs="auto" sx={{
                                            ml: '44VW',
                                            mt: '-21VH'
   }} >
                                            {detailGraphComponentMobile2}
                                        </Grid>
                                    </>) : (<></>)}

                                </>) : (<>



                                </>)}


                            </Grid>
                            {/*<Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>*/}

                            {/*</Grid>*/}

                        </Container>


                    </Container >
                </>)}

            

                {graphContextTableData?.length !== 0 ? (<>

                    {detailGraphTableComponentMobile}

                </>) : (<>



                </>)}

               



            </Box>

        </>

    )
}

export default GraphDetails;

